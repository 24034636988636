import L from "leaflet";

function montaCamera(fill: string) {
  return L.divIcon({
    html: `
    <svg width="25" height="41" viewBox="0 0 29 45" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask id="path-1-inside-1_396_161" fill="white">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M11.6159 43.3604C13.072 45.3517 15.928 45.3517 17.3841 43.3604C22.4451 36.4394 32.0288 21.3159 28.0619 10.6651C22.7657 -3.55502 6.23433 -3.55503 0.938065 10.6651C-3.02884 21.3159 6.55493 36.4394 11.6159 43.3604ZM14.4999 21.0914C17.7239 21.0914 20.3375 18.4778 20.3375 15.2538C20.3375 12.0297 17.7239 9.41609 14.4999 9.41609C11.2758 9.41609 8.66221 12.0297 8.66221 15.2538C8.66221 18.4778 11.2758 21.0914 14.4999 21.0914Z"
        />
      </mask>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.6159 43.3604C13.072 45.3517 15.928 45.3517 17.3841 43.3604C22.4451 36.4394 32.0288 21.3159 28.0619 10.6651C22.7657 -3.55502 6.23433 -3.55503 0.938065 10.6651C-3.02884 21.3159 6.55493 36.4394 11.6159 43.3604ZM14.4999 21.0914C17.7239 21.0914 20.3375 18.4778 20.3375 15.2538C20.3375 12.0297 17.7239 9.41609 14.4999 9.41609C11.2758 9.41609 8.66221 12.0297 8.66221 15.2538C8.66221 18.4778 11.2758 21.0914 14.4999 21.0914Z"
        fill="${fill}"
      />
      <path
        d="M17.3841 43.3604L16.5769 42.7702L17.3841 43.3604ZM11.6159 43.3604L10.8087 43.9507H10.8087L11.6159 43.3604ZM28.0619 10.6651L28.9991 10.3161L28.0619 10.6651ZM0.938065 10.6651L1.87518 11.0141L0.938065 10.6651ZM16.5769 42.7702C15.5203 44.2151 13.4797 44.2151 12.4231 42.7702L10.8087 43.9507C12.6643 46.4882 16.3357 46.4882 18.1913 43.9507L16.5769 42.7702ZM27.1248 11.0141C28.9697 15.9674 27.6948 22.1043 25.1877 28.0213C22.698 33.8972 19.0871 39.3374 16.5769 42.7702L18.1913 43.9507C20.7421 40.4624 24.4535 34.8805 27.0292 28.8016C29.5875 22.7639 31.1211 16.0136 28.9991 10.3161L27.1248 11.0141ZM1.87518 11.0141C4.39779 4.24105 9.51597 1 14.5 1C19.484 1 24.6022 4.24106 27.1248 11.0141L28.9991 10.3161C26.2254 2.869 20.4298 -1 14.5 -1C8.57023 -1 2.7746 2.869 0.00095238 10.316L1.87518 11.0141ZM12.4231 42.7702C9.91293 39.3374 6.30198 33.8972 3.81229 28.0213C1.30516 22.1043 0.0303399 15.9674 1.87518 11.0141L0.000952383 10.316C-2.12111 16.0136 -0.587496 22.7639 1.97078 28.8016C4.54649 34.8805 8.25791 40.4624 10.8087 43.9507L12.4231 42.7702ZM19.3375 15.2538C19.3375 17.9255 17.1716 20.0914 14.4999 20.0914V22.0914C18.2762 22.0914 21.3375 19.0301 21.3375 15.2538H19.3375ZM14.4999 10.4161C17.1716 10.4161 19.3375 12.582 19.3375 15.2538H21.3375C21.3375 11.4774 18.2762 8.41609 14.4999 8.41609V10.4161ZM9.66221 15.2538C9.66221 12.582 11.8281 10.4161 14.4999 10.4161V8.41609C10.7235 8.41609 7.66221 11.4774 7.66221 15.2538H9.66221ZM14.4999 20.0914C11.8281 20.0914 9.66221 17.9255 9.66221 15.2538H7.66221C7.66221 19.0301 10.7235 22.0914 14.4999 22.0914V20.0914Z"
        fill="#1B335F"
        mask="url(#path-1-inside-1_396_161)"
      />
    </svg>
  `,
    className: "svg-icon",
    iconSize: [25, 41],
    iconAnchor: [12, 41],
  });
}

export const CAMERA_ATIVA = montaCamera("#F5792B");

export const CAMERA_INATIVA = montaCamera("#3F51B5");
