import { SvgIcon } from "@mui/material";

export default function Passageiro() {
  return (
    <SvgIcon>
      <svg width="17" height="15" viewBox="0 0 17 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_1_26)">
          <path
            d="M13.6635 15C13.6635 12.5813 11.0568 10.625 7.8474 10.625C4.63802 10.625 2.03125 12.5813 2.03125 15M7.8474 8.75C8.74526 8.75 9.60636 8.42076 10.2412 7.83471C10.8761 7.24866 11.2328 6.4538 11.2328 5.625C11.2328 4.7962 10.8761 4.00134 10.2412 3.41529C9.60636 2.82924 8.74526 2.5 7.8474 2.5C6.94953 2.5 6.08843 2.82924 5.45355 3.41529C4.81866 4.00134 4.46198 4.7962 4.46198 5.625C4.46198 6.4538 4.81866 7.24866 5.45355 7.83471C6.08843 8.42076 6.94953 8.75 7.8474 8.75Z"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_1_26">
            <rect width="16.25" height="15" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  );
}
