import { ReactNode, useCallback } from "react";

import { Checkbox, ListItemText, MenuItem, SelectChangeEvent } from "@mui/material";

import { get } from "lodash";

import { isTodos } from "../../../services/consultas.ts";
import { useDadosFiltro } from "../barra-filtros/hooks.ts";
import { TODOS } from "../barra-filtros/valores.ts";

import SelecaoFiltro from "./selecao-filtro.tsx";
import { FiltroProps } from "./tipos.ts";

export default function FiltroMultivalorado({ definicao: d }: FiltroProps) {
  const { setFiltros, values, value } = useDadosFiltro(d);

  const handleRenderValue = useCallback((selected: string[]) => {
    if (isTodos(selected)) return "Todos";

    if (selected.length <= 2) return selected.join(" + ");

    return [selected[0], `… ${selected.length - 1} outros`].join(" + ");
  }, []);

  const handleChange = useCallback(
    (e: SelectChangeEvent<string[]>, child: ReactNode) => {
      if (!Array.isArray(e.target.value)) return;
      let newValue = e.target.value;

      const valorClicado = (get(child, "props.value") ?? "") as string;

      if (valorClicado === "All" || newValue.length === 0) newValue = TODOS;
      else if (newValue.includes("All")) newValue = newValue.filter((v) => v !== "All");

      setFiltros((f) => ({ ...f, [d.variavel]: newValue }));
    },
    [setFiltros, d.variavel],
  );

  return (
    <SelecaoFiltro definicao={d} multiple value={value} renderValue={handleRenderValue} onChange={handleChange}>
      <MenuItem value="All">
        <Checkbox size="small" checked={value.includes("All")} />
        <ListItemText primary="Todos" />
      </MenuItem>
      {values?.map((v, i) => (
        <MenuItem key={i} value={v}>
          <Checkbox size="small" checked={value.includes(v)} />
          <ListItemText primary={v} />
        </MenuItem>
      ))}
    </SelecaoFiltro>
  );
}
