import { ReactNode } from "react";

import { Feature, LineString } from "geojson";

import { formataData } from "../../../services/datas.ts";
import { fmtDataCurtaBrasilia, fmtDecimal, fmtDiaMesBrasilia, fmtShortTime } from "../../../services/formatacao.ts";
import { isCoordinates } from "../../../services/geo.ts";
import VerCoordenadas from "../../popups/ver-coordenadas.tsx";
import VerGeojson from "../../popups/ver-geojson.tsx";
import { InfoColuna, ObjetoDados } from "../types.ts";

/**
 * Prepara o conteúdo de uma célula de dados, de acordo com as definições da coluna.
 */
export function preparaConteudo<T extends ObjetoDados>(row: T, col: InfoColuna<T>) {
  const valor = row[col.id];
  const valorComoString = String(valor);

  const conteudo: ReactNode = (() => {
    switch (col.type) {
      case "geojson":
        return <VerGeojson valor={JSON.parse(valorComoString) as Feature<LineString>} />;

      case "date":
        return <time dateTime={valorComoString}>{formataData(valorComoString, fmtDataCurtaBrasilia)}</time>;
      case "ddmm":
        return <time dateTime={valorComoString}>{formataData(valorComoString, fmtDiaMesBrasilia)}</time>;

      case "datetime":
      case "time":
        return <time dateTime={valorComoString}>{formataData(valorComoString, fmtShortTime)}</time>;

      case "km":
      case "number":
        return fmtDecimal.format(+valorComoString);

      case "percentual":
        return fmtDecimal.format(+valorComoString) + " %";

      case "custom":
        return col.renderer?.(row);

      case "coordenadas-latlng":
        return isCoordinates(valor) ? <VerCoordenadas valor={valor.map(([lat, lng]) => [lng, lat])} /> : null;

      case "string":
      case "link":
      case "inteiro":
      default:
        return valorComoString;
    }
  })();

  return { conteudo, valorComoString };
}
