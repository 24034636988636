import { Box } from "@mui/material";

import type { NameType, Payload, ValueType } from "recharts/types/component/DefaultTooltipContent";

export default function TooltipApenasValor<TValue extends ValueType, TName extends NameType>({
  active,
  payload,
}: {
  active?: boolean;
  payload?: Payload<TValue, TName>[];
  label?: string | number;
}) {
  if (!active || !payload || !payload.length) return null;

  return (
    <Box
      sx={{
        backgroundColor: "#ffffff",
        px: "10px",
        py: "6px",
        border: "1px solid rgba(131, 135, 154, 0.10)",
        borderRadius: "6px",
        boxShadow: "0px 1px 16px -7px rgba(176, 176, 176, 0.21)",
        fontWeight: 700,
        color: "#83879A",
      }}
    >
      <Box className="label">{payload[0].value}</Box>
    </Box>
  );
}
