import { Alert } from "@mui/material";

import { Pane } from "react-leaflet";

export default function PaneErro() {
  return (
    <Pane name="Erro">
      <Alert color="error" sx={{ opacity: 0.85, width: "50vh", position: "relative", left: "5em", top: "2em" }}>
        Ocorreu um erro ao carregar os dados do mapa de calor.
      </Alert>
    </Pane>
  );
}
