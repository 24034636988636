import { useEffect, useMemo, useState } from "react";

import { List } from "immutable";
import { useAtomValue } from "jotai";
import { debounce, get, isNumber, isString } from "lodash";
import { useLocation } from "react-router-dom";
import useSWRImmutable from "swr/immutable";

import { filtroDataEpochAtom, filtrosAtom } from "../layouts/dashboard-layout/barra-filtros/valores.ts";

import { CamposVariacao, constroiParametros } from "./consultas.ts";
import { useAuthQueryFetcher } from "./fetchers.ts";

type UObj = Record<string, unknown>;

export interface ConfigDadosAPI {
  consulta: string;
  parametrosAdicionais?: UObj;
  camposVariacao?: CamposVariacao;
  suspense?: boolean;
}

export function useDadosAPIProtegida<T extends UObj = UObj>({
  consulta,
  parametrosAdicionais,
  camposVariacao,
  suspense = false,
}: ConfigDadosAPI) {
  const { pathname } = useLocation();
  const [from, to] = useAtomValue(filtroDataEpochAtom);
  const filtros = useAtomValue(filtrosAtom(pathname));

  const adicionais = { from, to, ...parametrosAdicionais };

  const spPrincipal = constroiParametros(filtros, adicionais);
  const spVariacao = consulta && camposVariacao && constroiParametros(filtros, adicionais, camposVariacao);

  const fetcher = useAuthQueryFetcher<T>();

  const endpointConsulta =
    "conteudo/dados" +
    (consulta.startsWith("/")
      ? consulta
      : (pathname.startsWith("/gestor/") ? pathname : `/area-publica${pathname}`).replace(/\/+$/, "") + `/${consulta}`);

  const { data: registros, error: erro } = useSWRImmutable<T[], unknown>(
    consulta && `${endpointConsulta}?${spPrincipal.toString()}`,
    fetcher,
    { suspense },
  );
  const { data: variacao, error: erroVariacao } = useSWRImmutable<T[], unknown>(
    spVariacao && `${endpointConsulta}?${spVariacao.toString()}`,
    fetcher,
    { suspense },
  );

  return { registros, variacao, erro, erroVariacao };
}

export function useIndicador(config: Omit<ConfigDadosAPI, "camposVariacao">) {
  const {
    registros,
    variacao: valorAnterior,
    ...erros
  } = useDadosAPIProtegida<{ valor: number | undefined }>({
    camposVariacao: ["Ano", "Mes", "Dia"],
    ...config,
  });

  return {
    carregando: !registros || !valorAnterior,
    valor: Object.values(registros?.[0] ?? {}).at(0),
    valorAnterior: Object.values(valorAnterior?.[0] ?? {}).at(0),
    ...erros,
  };
}

export function useDadosAPIPaginados<T extends UObj = UObj>({
  consulta,
  parametrosAdicionais,
  limit = 1000,
  delay = 750,
}: ConfigDadosAPI & { limit?: number; delay?: number }) {
  const [paginaSolicitada, setPaginaSolicitada] = useState(0);
  const [count, setCount] = useState<number>();
  const [paginas, setPaginas] = useState(List<T[]>());

  // roda a contagem
  const { registros: contagem, erro: erroContagem } = useDadosAPIProtegida<{ [k: string]: number }>({
    consulta: `${consulta}:contagem`,
    parametrosAdicionais,
  });

  // roda a consulta para a página solicitada (inicialmente a zero)
  const { registros: pagina, erro: erroRegistros } = useDadosAPIProtegida<T>({
    consulta,
    parametrosAdicionais: { ...parametrosAdicionais, limit, offset: paginaSolicitada * limit },
  });

  // cada vez que vier resposta da página solicitada, salva
  useEffect(() => {
    if (!pagina) return;
    setPaginas((pp) => pp.set(paginaSolicitada, pagina));
  }, [pagina, paginaSolicitada]);

  useEffect(() => {
    const novaContagem = !contagem?.length ? undefined : Object.values(contagem[0])[0];
    if (isNumber(novaContagem)) setCount(novaContagem);
  }, [contagem]);

  // cada vez que vier uma página e a contagem for zero, atualiza o total
  useEffect(() => {
    setCount((c) => (c ? c : paginas.reduce((sum, p) => sum + p.length, 0)));
  }, [limit, paginas]);

  // callback para carregar a página solicitada após um delay
  const solicitaPagina = useMemo(() => debounce(setPaginaSolicitada, delay), [delay]);

  // Proxy que responde no lugar da lista de registros, retornando a contagem realizada
  // e o registro carregado, ou undefined se ainda não tiver sido carregado.
  // Acesso a registros não carregados dispara uma carga da página após o delay.
  // Para acessar um registro sem disparar a carga, usar o método at().
  // Ao percorrer com for...of, será retornada apenas a primeira página.
  const proxy = useMemo(() => {
    if (!paginas.get(0) && !count) return undefined;

    const p1 = paginas.get(0) ?? [];
    const at = (itemSolicitado: number) => {
      if (itemSolicitado < 0) itemSolicitado = (count ?? 0) + itemSolicitado;
      if (itemSolicitado < 0) return undefined;
      const paginaDoItem = Math.trunc(itemSolicitado / limit);
      const offsetDoItem = itemSolicitado % limit;

      return paginas.get(paginaDoItem)?.[offsetDoItem];
    };

    return new Proxy(p1, {
      get(target, p: string | symbol) {
        // retorna a contagem no servidor
        if (p === "length") return count;

        // retorna o valor sem solicitar carga
        if (p === "at") return at;

        // retorna apenas a primeira página no for...of
        if (p === Symbol.iterator) return p1[Symbol.iterator].bind(p1);

        // se a propriedade solicitada for um número inteiro, retorna o valor
        // da página correta e, se não encontrar a página, solicita a carga ao servidor
        if (isString(p)) {
          const itemSolicitado = +p;
          if (Number.isInteger(itemSolicitado) && itemSolicitado >= 0) {
            const paginaDoItem = Math.trunc(itemSolicitado / limit);
            const offsetDoItem = itemSolicitado % limit;

            const registrosDaPagina = paginas.get(paginaDoItem);
            if (registrosDaPagina) {
              return registrosDaPagina[offsetDoItem];
            } else {
              solicitaPagina(paginaDoItem);

              return undefined;
            }
          }
        }

        // se chegamos até aqui, retorna a propriedade padrão do array vazio
        return get(target, p) as unknown;
      },
      has(target, p: string | symbol): boolean {
        return p === proxyPaginacao ? true : p in target;
      },
    });
  }, [count, paginas, solicitaPagina, limit]);

  return { registros: proxy, erro: erroRegistros ?? erroContagem };
}

/**
 * Retorna verdadeiro se o array em questão for um proxy de paginação.
 */
export function isProxyPaginacao<T>(linhas: T[]) {
  return proxyPaginacao in linhas;
}

const proxyPaginacao = Symbol("proxyPaginacao");
