import TableBox from "../../../components/frames/table-box.tsx";
import { useDadosAPIPaginados } from "../../../services/dados.ts";

export default function CoordenadasRotas() {
  const tabelaProps = useDadosAPIPaginados<{
    linha: string;
    sentido: string;
    consorcio: string;
    empresa: string;
    coordenadas: number[][];
  }>({ consulta: "coordenadas-rotas" });

  return (
    <TableBox
      {...tabelaProps}
      titulo="Coordenadas das rotas"
      colunas={[
        { id: "empresa", label: "Empresa", type: "string" },
        { id: "consorcio", label: "Consórcio", type: "string" },
        { id: "linha", label: "Linha", type: "string" },
        { id: "sentido", label: "Sentido", type: "string" },
        { id: "coordenadas", label: "Coordenadas", type: "coordenadas-latlng", width: "6em" },
      ]}
    />
  );
}
