import { ReactNode, useEffect } from "react";

import { Box, Stack, SxProps, Typography } from "@mui/material";

import Arrowback from "../assets/icones/arrowback.tsx";
import MenuMobile from "../layouts/shared/menu-mobile.tsx";
import { useIsTabletOrSmaller } from "../services/responsividade.ts";

interface Props {
  titulo: ReactNode;
  subtitulo?: ReactNode;
  sx?: SxProps;
}

export default function Titulos({ titulo, subtitulo, sx }: Props) {
  const isTablet = useIsTabletOrSmaller();

  useEffect(() => {
    document.title = ["MobNit", titulo, subtitulo].filter((v) => Boolean(v)).join(" - ");
  }, [subtitulo, titulo]);

  return (
    <Stack flexDirection="row" alignItems="center" sx={sx}>
      {isTablet && (
        <MenuMobile>
          <Box sx={{ zoom: 1.5, pr: 1 }}>
            <Arrowback />
          </Box>
        </MenuMobile>
      )}

      <Stack justifyContent="center">
        <Typography sx={{ color: "secondary.main", fontWeight: 200, lineHeight: 1, mt: 0.5 }}>{titulo}</Typography>

        {subtitulo && (
          <Typography fontSize={24} fontWeight={700} color="secondary.main" paddingBottom={2}>
            {subtitulo}
          </Typography>
        )}
      </Stack>
    </Stack>
  );
}
