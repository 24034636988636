import { atom } from "jotai";
import { atomFamily } from "jotai/utils";

import { calculaValoresPadraoFiltroData } from "../../../services/datas.ts";

const [valorPadraoDataInicio, valorPadraoDataFim] = calculaValoresPadraoFiltroData();

export const filtroDataDe = atom(valorPadraoDataInicio);

export const filtroDataAte = atom(valorPadraoDataFim);

export const filtrosAtom = atomFamily((_id: string) => atom<Record<string, string[]>>({}));

export const filtroDataEpochAtom = atom((get) => [get(filtroDataDe).valueOf(), get(filtroDataAte).valueOf()] as const);

export const TODOS = ["All"];
