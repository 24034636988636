import {
  Bar,
  CartesianGrid,
  ComposedChart,
  Label,
  Legend,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

import ChartBox from "../../../components/frames/chartbox.tsx";
import TooltipMultiseries from "../../../components/tooltips/tooltip-multiseries.tsx";
import { useDadosAPIProtegida } from "../../../services/dados.ts";
import { fmtNumeroInteiro } from "../../../services/formatacao.ts";

import { CustomLegend } from "./custom-legend.tsx";
import { DispProg } from "./tipos.ts";

export default function FrotaDisponivelProgramada() {
  const { registros, ...chartProps } = useDadosAPIProtegida<DispProg>({ consulta: "disponivel-programada" });

  const disponivelColor = "#F5792B";
  const programadaColor = "#683AB7";
  const titulosLegenda = ["Programada", "Disponível"];

  return (
    <ChartBox {...chartProps} registros={registros} titulo="Frota **disponível e programada**">
      <ResponsiveContainer width="100%" height="100%" style={{ fontSize: "12px" }}>
        <ComposedChart data={registros} margin={{ top: 15, right: 25, bottom: 20, left: 50 }}>
          <CartesianGrid vertical={false} stroke="rgba(131, 135, 154, 0.10)" />
          <XAxis dataKey="linha" axisLine={false} angle={-45} tickMargin={10} />
          <YAxis axisLine={false} tickLine={false}>
            <Label angle={-90} position="insideLeft" offset={-10}>
              Frota
            </Label>
          </YAxis>
          <Tooltip content={<TooltipMultiseries formatador={fmtNumeroInteiro} />} />
          <Legend
            content={
              <CustomLegend
                disponivelColor={disponivelColor}
                titulos={titulosLegenda}
                programadaColor={programadaColor}
              />
            }
          />
          <Bar dataKey="frotaDisponivel" fill={disponivelColor} name={titulosLegenda[1]} />
          <Line dataKey="frotaProgramada" stroke={programadaColor} fill={programadaColor} name={titulosLegenda[0]} />
        </ComposedChart>
      </ResponsiveContainer>
    </ChartBox>
  );
}
