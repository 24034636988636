import { Navigate } from "react-router-dom";

import { useRotasAgrupadas } from "../hooks.ts";

export default function PrimeiraRotaDisponivel() {
  const path = usePrimeiraRotaDisponivel();

  return <Navigate to={path} />;
}

function usePrimeiraRotaDisponivel(): string {
  const [grupos, individuais] = useRotasAgrupadas();

  return (
    grupos.flatMap(([, , rotasFilhos]) => rotasFilhos).map(([, path]) => path)[0] ??
    individuais.map(([, path]) => path).filter(([, path]) => path?.startsWith("/gestor"))[0] ??
    "/boas-vindas"
  );
}
