import TableBox from "../../../components/frames/table-box.tsx";
import { useDadosAPIPaginados } from "../../../services/dados.ts";

import { LinhaTabelaIntegracoes } from "./tipos.ts";

export default function TabelaIntegracoes() {
  const tabelaProps = useDadosAPIPaginados<LinhaTabelaIntegracoes>({ consulta: "integracoes" });

  return (
    <TableBox
      {...tabelaProps}
      titulo="Integrações"
      colunas={[
        { id: "empresa", label: "Empresa", type: "string" },
        { id: "linha", label: "Linha", type: "string" },
        { id: "veiculo", label: "Veículo", type: "string" },
        { id: "data", label: "Data", type: "date" },
        { id: "numPassIntMes", label: "Integrações por mês", type: "inteiro", width: "5.5em" },
        { id: "passMes", label: "Passageiros por mês", type: "inteiro", width: "5.5em" },
        { id: "porIntMes", label: "% de integração", type: "string", width: "6em" },
      ]}
    />
  );
}
