import { SvgIcon } from "@mui/material";

export default function Quilometragem() {
  return (
    <SvgIcon>
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_1_106)">
          <path
            d="M12.7599 13C13.984 11.7554 14.6689 10.079 14.6666 8.33333C14.6666 4.65333 11.6799 1.66666 7.99992 1.66666C4.31992 1.66666 1.33325 4.65333 1.33325 8.33333C1.33325 10.1467 2.05325 11.7867 3.22659 12.9933"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M7.99992 14.3333C8.59754 14.3333 9.17068 14.0959 9.59326 13.6733C10.0158 13.2508 10.2532 12.6776 10.2532 12.08C10.2532 11.4824 10.0158 10.9092 9.59326 10.4867C9.17068 10.0641 8.59754 9.82667 7.99992 9.82667C7.40229 9.82667 6.82915 10.0641 6.40657 10.4867C5.98399 10.9092 5.74658 11.4824 5.74658 12.08C5.74658 12.6776 5.98399 13.2508 6.40657 13.6733C6.82915 14.0959 7.40229 14.3333 7.99992 14.3333ZM10.1666 8H10.6666C11.2132 8 11.6666 7.55333 11.6666 7C11.6666 6.45333 11.2132 6 10.6666 6C10.1199 6 9.66658 6.44667 9.66658 7V7.5C9.66658 7.77333 9.89325 8 10.1666 8Z"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_1_106">
            <rect width="16" height="16" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  );
}
