import { useCallback, useState } from "react";

import { Box, Card, CardContent, IconButton, Stack, Typography } from "@mui/material";

import { Set } from "immutable";
import { MapContainer, Marker, TileLayer } from "react-leaflet";

import Fechar from "../../assets/icones/fechar.tsx";
import VideoCamera from "../../assets/icones/video-camera.tsx";
import { CAMERA_ATIVA, CAMERA_INATIVA } from "../../components/mapa/icones.tsx";
import { POSICOES } from "../../components/mapa/posicoes.ts";
import Titulos from "../../components/titulos.tsx";
import { cameras, ICamera } from "../../services/cameras.ts";

export default function Videomonitoramento() {
  const mapHeight = "75vh";
  const videoBaseWidth = "40vw";
  const [camerasAtivas, setCamerasAtivas] = useState(Set<ICamera>());

  const toggleCamera = useCallback((c: ICamera) => {
    setCamerasAtivas((ativas) => {
      if (ativas.has(c)) return ativas.remove(c);

      if (ativas.size < 2) return ativas.add(c);

      return ativas;
    });
  }, []);

  return (
    <Stack>
      <Titulos titulo="Mapas" subtitulo="Videomonitoramento" />
      <Card>
        <CardContent
          sx={{
            p: 2,
            ["& > div"]: { height: mapHeight },
            ["& .leaflet-popup-content-wrapper"]: { width: `calc(${videoBaseWidth} + 40px)` },
          }}
        >
          <MapContainer center={[-22.9148, -43.0955]} zoom={13} scrollWheelZoom={false}>
            <TileLayer
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            {cameras.map((p) => (
              <Marker
                key={p.id + p.nome}
                position={[p.lat, p.long]}
                eventHandlers={{ click: () => toggleCamera(p) }}
                icon={camerasAtivas.has(p) ? CAMERA_ATIVA : CAMERA_INATIVA}
              />
            ))}
            {camerasAtivas.toIndexedSeq().map((p, i) => (
              <Stack
                key={p.id + p.nome}
                sx={{
                  bgcolor: "primary.main",
                  color: "primary.contrastText",

                  borderRadius: "18px",
                  m: "2rem",
                  overflow: "hidden",
                  boxShadow: "0px 4px 16px -7px rgba(176, 176, 176, 0.21)",
                }}
                className={POSICOES[i]}
              >
                <Stack position="relative">
                  <Box
                    display="flex"
                    flex={1}
                    alignItems="center"
                    gap={1.5}
                    pl="14px"
                    pr={0.5}
                    minHeight="42px"
                    sx={{ pointerEvents: "auto", ["& button"]: { ml: "auto" } }}
                  >
                    <VideoCamera />
                    <Typography
                      fontWeight={700}
                      fontSize="14px"
                      sx={{
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        width: `calc(${videoBaseWidth} - 6.2rem)`,
                      }}
                      title={p.nome}
                    >
                      {p.nome}
                    </Typography>

                    <IconButton onClick={() => toggleCamera(p)}>
                      <Fechar />
                    </IconButton>
                  </Box>
                  <Box component="video" autoPlay sx={{ width: videoBaseWidth }}>
                    <source
                      type="video/mp4"
                      src={`https://appnittrans.niteroi.rj.gov.br:8888/${p.id}/last_video.mp4`}
                    />
                  </Box>
                  <Typography position="absolute" left={16} bottom={10} color="#EB5757" fontSize={14} fontWeight={700}>
                    AO VIVO
                  </Typography>
                </Stack>
              </Stack>
            ))}
          </MapContainer>
        </CardContent>
      </Card>
    </Stack>
  );
}
