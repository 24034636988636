import { Box } from "@mui/material";

import { Feature, LineString } from "geojson";

import VerGeojson from "./ver-geojson.tsx";

interface Props {
  valor: number[][];
}

export default function VerCoordenadas({ valor }: Props) {
  if (!valor) return <Box textAlign="center">Carregando</Box>;

  const feature: Feature<LineString> = {
    type: "Feature",
    geometry: { type: "LineString", coordinates: valor },
    properties: {},
  };

  return <VerGeojson valor={feature ?? []} />;
}
