import { Box, Stack, Typography } from "@mui/material";
interface Props {
  disponivelColor: string;
  titulos: string[];
  programadaColor: string;
}

export function CustomLegend({ disponivelColor, titulos, programadaColor }: Props) {
  return (
    <Stack ml={10}>
      <Stack flexDirection="row" alignItems="center" gap={1}>
        <Box sx={{ bgcolor: disponivelColor }} width={24} height={4} />
        <Typography className="legenda" fontSize="10px" color="#83879A">
          {titulos[1]}
        </Typography>
      </Stack>
      <Stack flexDirection="row" alignItems="center" gap={1}>
        <Box sx={{ bgcolor: programadaColor }} width={24} height={4} />
        <Typography className="legenda" fontSize="10px" color="#83879A">
          {titulos[0]}
        </Typography>
      </Stack>
    </Stack>
  );
}
