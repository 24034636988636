import { MouseEvent, useState } from "react";

import { ArrowDropUp } from "@mui/icons-material";
import { Collapse, ListItemButton, Stack } from "@mui/material";
import { ArrowDropDownIcon } from "@mui/x-date-pickers";

import { StyledMenuItem } from "../styled-popup-menu.tsx";

export default function MenuPrefeitura() {
  const [open, setOpen] = useState(false);
  const handleClick = (e: MouseEvent) => {
    e.stopPropagation();
    setOpen(!open);
  };

  return (
    <Stack ml="auto">
      <ListItemButton onClick={handleClick} sx={{ color: "primary.main", px: 1 }}>
        Prefeitura Niterói {open ? <ArrowDropUp /> : <ArrowDropDownIcon />}
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <Stack alignItems="flex-end">
          <StyledMenuItem to="https://www.niteroi.rj.gov.br" target="_blank">
            Portal Prefeitura de Niterói
          </StyledMenuItem>
          <StyledMenuItem to="https://urbanismo.niteroi.rj.gov.br" target="_blank">
            Secretaria de Mobilidade Urbana
          </StyledMenuItem>
          <StyledMenuItem to="https://www.nittrans.niteroi.rj.gov.br" target="_blank">
            Nittrans
          </StyledMenuItem>
          <StyledMenuItem to="https://observa.niteroi.rj.gov.br" target="_blank">
            ObservaNit
          </StyledMenuItem>
          <StyledMenuItem to="https://www.sigeo.niteroi.rj.gov.br" target="_blank">
            SIGEO
          </StyledMenuItem>
          <StyledMenuItem to="https://servicos.niteroi.rj.gov.br/#/main/dyview" target="_blank">
            Serviços ao cidadão
          </StyledMenuItem>
          <StyledMenuItem to="https://transparencia.niteroi.rj.gov.br/#/" target="_blank">
            Portal da transparência
          </StyledMenuItem>
        </Stack>
      </Collapse>
    </Stack>
  );
}
