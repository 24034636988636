import { Box, TableCell } from "@mui/material";

import { InfoColuna, ObjetoDados } from "../types.ts";

import { preparaConteudo } from "./conteudo.tsx";

interface Props<T extends ObjetoDados> {
  row: T;
  col: InfoColuna<T>;
}

export default function CellValue<T extends ObjetoDados>({ row, col }: Props<T>) {
  const { conteudo, valorComoString } = preparaConteudo(row, col);

  let final = conteudo;

  // se temos um colorMap, envolvemos o conteúdo em uma tag span com a cor específica
  if (col.colorMap) {
    const [label, color] = col.colorMap.get(valorComoString) ?? [];
    final = (
      <Box component="span" sx={{ color, fontWeight: 700 }}>
        {label ?? final}
      </Box>
    );
  }

  // retorna o conteúdo em uma célula
  return <TableCell>{final}</TableCell>;
}
