import { Bar, BarChart, Brush, CartesianGrid, Label, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";

import ChartBox from "../../../components/frames/chartbox.tsx";
import TooltipIntegracoesLinha from "../../../components/tooltips/tooltip-integracoes-linha.tsx";
import { useDadosAPIProtegida } from "../../../services/dados.ts";

import { LinhaQtd } from "./tipos.ts";

export default function QtdIntegracoesLinha() {
  const { registros, ...chartProps } = useDadosAPIProtegida<LinhaQtd>({ consulta: "qtd-integracoes-linha" });

  const realizadaColor = "#574786";

  return (
    <ChartBox {...chartProps} registros={registros} titulo="Quantidade de **integrações/linha**">
      <ResponsiveContainer width="100%" height="100%" style={{ fontSize: "12px" }}>
        <BarChart data={registros} width={500} height={400} margin={{ top: 15, right: 25, bottom: 45, left: 70 }}>
          <CartesianGrid vertical={false} stroke="rgba(131, 135, 154, 0.10)" />
          <XAxis dataKey="linha" axisLine={false} tickLine={false} angle={-45} tickMargin={10}>
            <Label position="bottom" offset={20}>
              Linha
            </Label>
          </XAxis>
          <YAxis axisLine={false} tickLine={false}>
            <Label angle={-90} position="insideLeft" offset={-30}>
              Integrações
            </Label>
          </YAxis>
          <Tooltip content={TooltipIntegracoesLinha} />
          {registros?.length && registros.length > 30 && <Brush height={10} endIndex={30} />}
          <Bar dataKey="qtd" fill={realizadaColor} />
        </BarChart>
      </ResponsiveContainer>
    </ChartBox>
  );
}
