export default {
  texto: {
    indicadores: {
      passageiros: {
        ipk: {
          tooltip:
            "Relação entre o número de passageiros transportados por quilômetro percorrido para cada linha em operação.",
          titulo: "Índice de **passageiros por quilômetro (IPK)**",
        },
        pdv: {
          tooltip: `Aproveitamento da capacidade física dos veículos alocados na frota de cada uma das linhas operantes, 
              considerando a dinâmica de funcionamento do sistema de transporte coletivo de passageiros.`,
          titulo: "**Passageiros por veículo** por dia **(PVD)**",
        },
      },
      frota: {
        idf: {
          tooltip:
            "Índice de Disponibilidade de Frota: A percentagem (%) da relação entre frota disponível e frota programada.",
          titulo: "Índice de **disponibilidade de frota** (IDF)",
        },
        ici: {
          tooltip: "Índice de Cumprimento de Intervalos: A percentagem (%) de atendimento dos headways programados.",
          titulo: "Índice de **cumprimento de intervalos (ICI)**",
        },
        icv: {
          tooltip: "Índice de Cumprimento de Viagens: A percentagem (%) de viagens realizadas sobre as programadas.",
          titulo: "Índice de **cumprimento de viagens (ICV)**",
        },
      },
      reclamacoes: {
        irm: {
          tooltip: "Índice de Reclamações de Mobilidade: A relação entre reclamações e passageiros transportados.",
          titulo: "Índice de **reclamações de mobilidade (%)**",
        },
      },
    },
    auditoria: {
      subsidios: {
        ntps: {
          tooltip: `Número total de passageiros subsidiados:
               Somatório de passageiros aptos a receber subsídios por x1 ou x2 viagens.`,
          titulo: "Número total de **passageiros subsidiados**",
        },
        ntpns: {
          tooltip: `Número total de passageiros não subsidiados:
                Somatório de passageiros não aptos a receber subsídios.`,
          titulo: "Número total de **passageiros não subsidiados**",
        },
        ntp: {
          tooltip: `Número total de passageiros:
              Soma do número total de passageiros subsidiados e o número total de passageiros não subsidiados.`,
          titulo: "Número total de **passageiros**",
        },
        ntvs: {
          tooltip: `Número total de viagens subsidiadas:
               Somatório de viagens aptas a receber subsídios, considerando x1 ou x2 viagem do passageiro}, descontadas as baldeações.`,
          titulo: "Número total de **viagens subsidiadas**",
        },
        ntvns: {
          tooltip: `Número total de viagens não subsidiadas:
                Somatório de viagens, a partir da 3ª viagem no dia (descontados os casos de baldeações bem sucedidas)}, não subsidiadas ao usuário ou à operadora.`,
          titulo: "Número total de **viagens não subsidiadas**",
        },
        ntv: {
          tooltip: `Número total de viagens:
              Soma do número total de viagens subsidiadas e o número total de viagens não subsidiadas.`,
          titulo: "Número total de **viagens**",
        },
        nmvspp: {
          tooltip: "Número médio de viagens subsidiadas por passageiro:",
          titulo: "Número médio de **viagens subsidiadas por passageiro**",
        },
        nmvnspp: {
          tooltip: `Número médio de viagens não subsidiadas por passageiro:
                  Média de viagens, a partir da 3ª viagem no dia (descontados os casos de baldeações bem sucedidas)}, não subsidiadas ao usuário ou à operadora, por passageiro.`,
          titulo: "Número médio de **viagens não subsidiadas por passageiro**",
        },
        nmvpp: {
          tooltip: `Número médio de viagens por passageiro:
                Média do número total de viagens subsidiadas mais o número total de viagens não subsidiadas por passageiro.`,
          titulo: "Número médio de **viagens por passageiro**",
        },
        su: {
          tooltip: `Subsídio ao usuário (R$):
             Valores em reais (R$) a serem pagos de subsídios por x1 ou x2 viagens para o usuário.
             Os valores consideram um índice fixo de R$0,45 por viagem e limite de até 2 viagens por dia
             descontados os casos de baldeações bem sucedidas.`,
          titulo: "Subsídio ao **usuario (R$)**",
        },
        so: {
          tooltip: `Subsídio à operadora (R$):
             Valores em reais (R$) a serem pagos de subsídios por x1 ou x2 viagens para o usuário.
             Os valores consideram um índice fixo de R$0,70 por viagem e limite de até 2 viagens por dia
             descontados os casos de baldeações bem sucedidas.
             O valor pode ser alterado conforme critério do “Desconto por desempenho”.`,
          titulo: "Subsídio à **operadora (R$)**",
        },
        st: {
          tooltip: `Subsídio total (R$):
             Somatório dos valores de subsídio aos usuários e às operadoras.`,
          titulo: "Subsídio **total (R$)**",
        },
      },
      quilometragem: {
        pmm: {
          tooltip: `Somatório de todas as quilometragens diárias realizadas dividido pelo número de dias de operação.
              Dados oriundos do GPS dos veículos.`,
          titulo: "Percurso médio mensal por veículo(PMM) - (KM)",
        },
      },
    },
  },
};
