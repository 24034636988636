import { Card, Stack } from "@mui/material";

import ImagemEditavel from "../../../components/conteudo-editavel/imagem-editavel";

import BotaoHome from "./botao-home.tsx";
import Busca from "./busca";
import { Contadores } from "./contadores.tsx";
import { IconeHorarios, IconeItinerarios } from "./icones.tsx";

export default function HomeUsuario() {
  return (
    <Stack alignItems="center" gap={4} flex={1}>
      <Stack sx={{ width: { xs: "90vw", lg: "1000px" } }}>
        <Busca />
      </Stack>

      <Card sx={{ width: { xs: "90vw", lg: "1000px" } }}>
        <Stack flexDirection="row" fontWeight={700}>
          <Stack flex={1} gap={3} alignItems="center" pt={4.5}>
            <Stack flexDirection="row" gap={1.5} pb={1}>
              <BotaoHome to="/horarios" sx={{ bgcolor: "#F5792B", ml: "auto" }} icone={<IconeHorarios />}>
                Horários e pontualidade
              </BotaoHome>
              <BotaoHome to="/itinerarios" sx={{ bgcolor: "#683AB7", mr: "auto" }} icone={<IconeItinerarios />}>
                Itinerários, rotas e tarifas
              </BotaoHome>
            </Stack>
            <Contadores />
          </Stack>
          <ImagemEditavel caminho="area-publica/inicial" width="45%" display={{ xs: "none", sm: "flex" }} />
        </Stack>
      </Card>
    </Stack>
  );
}
