import { Stack } from "@mui/material";

import { useAtomValue } from "jotai";
import { GeoJSON, MapContainer, TileLayer } from "react-leaflet";
import useSWRImmutable from "swr/immutable";

import { camadasFetcher } from "../../../services/fetchers.ts";

import { camadasSelecionadasAtom } from "./atomos.ts";

interface Props {
  mapHeight?: string;
}

export default function MapaMobilidade({ mapHeight = "319px" }: Props) {
  const camadasSelecionadas = useAtomValue(camadasSelecionadasAtom);

  const { data: camadas } = useSWRImmutable(camadasSelecionadas, camadasFetcher);

  return (
    <Stack fontSize={8} border="1px solid rgba(131, 135, 154, 0.19)" borderRadius="7px" overflow="hidden">
      <Stack sx={{ ["& > div"]: { height: mapHeight } }}>
        <MapContainer center={[-22.9, -43.07]} zoom={12} scrollWheelZoom={false}>
          <TileLayer attribution="CARTO" url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png" />

          {camadas?.map(([nome, i, c]) => <GeoJSON key={`${nome}${i}`} data={c} />)}
        </MapContainer>
      </Stack>
    </Stack>
  );
}
