import { SvgIcon } from "@mui/material";

export default function MobilidadeIcon() {
  return (
    <SvgIcon>
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
        <path
          d="M13.6062 3.92699L10.9395 2.72699H10.9062H10.8262H10.7462H10.5795H10.5128H10.4262H10.3928L7.99951 3.80033L5.60618 2.72699H5.57284L5.49284 2.66699H5.41284H5.24618H5.17951H5.09284L2.42618 3.86699C2.29317 3.91796 2.17998 4.01018 2.1032 4.13015C2.02642 4.25012 1.99008 4.39153 1.99951 4.53366V12.667C1.9997 12.7785 2.02787 12.8883 2.08144 12.9861C2.13502 13.0839 2.21229 13.1668 2.30618 13.227C2.41348 13.2963 2.53844 13.3333 2.66618 13.3337C2.76051 13.3332 2.85367 13.3128 2.93951 13.2737L5.33284 12.2003L7.72618 13.2737H7.75951C7.83464 13.3088 7.91657 13.327 7.99951 13.327C8.08245 13.327 8.16438 13.3088 8.23951 13.2737H8.27284L10.6662 12.2003L13.0595 13.2737C13.1454 13.3128 13.2385 13.3332 13.3328 13.3337C13.4606 13.3333 13.5855 13.2963 13.6928 13.227C13.7867 13.1668 13.864 13.0839 13.9176 12.9861C13.9712 12.8883 13.9993 12.7785 13.9995 12.667V4.53366C13.9992 4.40542 13.962 4.27998 13.8922 4.17237C13.8225 4.06477 13.7231 3.97957 13.6062 3.92699ZM5.99951 4.36699L7.33284 4.96033V11.627L5.99951 11.0337V4.36699ZM12.6662 11.6337L11.3328 11.0403V4.37366L12.6662 4.96699V11.6337Z"
          fill="currentColor"
        />
      </svg>
    </SvgIcon>
  );
}
