import TableBox from "../../../components/frames/table-box.tsx";
import { useDadosAPIPaginados } from "../../../services/dados.ts";

export default function TabelaSubsidio() {
  const tabelaProps = useDadosAPIPaginados({ consulta: "passageiros-subsidiados" });

  return (
    <TableBox
      {...tabelaProps}
      titulo="Passageiros subsidiados"
      colunas={[
        { id: "dataTransacao", label: "Data da Transação", type: "date" },
        { id: "chipCartao", label: "Chip Cartão", type: "string" },
        { id: "numeroViagens", label: "Viagens", type: "number" },
        { id: "consorcio", label: "Consórcio", type: "string" },
        { id: "modalidade", label: "Modalidade", type: "string" },
        { id: "tipoAplicacao", label: "Tipo Aplicação", type: "string" },
        { id: "debito", label: "Débito", type: "string" },
        { id: "tipoDia", label: "Tipo Dia", type: "string" },
        { id: "turnoDia", label: "Turno", type: "string" },
      ]}
    />
  );
}
