import StyledPopupMenu, { StyledMenuItem } from "./styled-popup-menu.tsx";

export default function MenuPrefeitura() {
  return (
    <StyledPopupMenu titulo="Prefeitura de Niterói">
      <StyledMenuItem to="https://www.niteroi.rj.gov.br" target="_blank">
        Portal Prefeitura de Niterói
      </StyledMenuItem>
      <StyledMenuItem to="https://urbanismo.niteroi.rj.gov.br" target="_blank">
        Secretaria de Mobilidade Urbana
      </StyledMenuItem>
      <StyledMenuItem to="https://www.nittrans.niteroi.rj.gov.br" target="_blank">
        Nittrans
      </StyledMenuItem>
      <StyledMenuItem to="https://observa.niteroi.rj.gov.br" target="_blank">
        ObservaNit
      </StyledMenuItem>
      <StyledMenuItem to="https://www.sigeo.niteroi.rj.gov.br" target="_blank">
        SIGEO
      </StyledMenuItem>
      <StyledMenuItem to="https://servicos.niteroi.rj.gov.br/#/main/dyview" target="_blank">
        Serviços ao cidadão
      </StyledMenuItem>
      <StyledMenuItem to="https://transparencia.niteroi.rj.gov.br/#/" target="_blank">
        Portal da transparência
      </StyledMenuItem>
    </StyledPopupMenu>
  );
}
