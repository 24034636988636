import {
  Bar,
  CartesianGrid,
  ComposedChart,
  Label,
  Legend,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

import ChartBox from "../../../../components/frames/chartbox.tsx";
import TooltipMultiseries from "../../../../components/tooltips/tooltip-multiseries.tsx";
import { useDadosAPIProtegida } from "../../../../services/dados.ts";
import { fmtIndicadorKm } from "../../../../services/formatacao.ts";
import { CustomLegend } from "../../../indicadores/frota/custom-legend.tsx";

import { type ProgramadaVolta } from "./tipos.ts";

export default function ProgramadaVolta() {
  const { registros, ...chartProps } = useDadosAPIProtegida<ProgramadaVolta>({ consulta: "programada-volta" });

  const realizadaColor = "#59C3C8";
  const programdaColor = "#574786";
  const titulo = "Quilometragem programada **volta**";

  const titulosLegenda = ["Programada", "Realizada"];

  return (
    <ChartBox {...chartProps} registros={registros} titulo={titulo}>
      <ResponsiveContainer width="100%" height="100%" style={{ fontSize: "12px" }}>
        <ComposedChart data={registros} margin={{ top: 15, right: 20, bottom: 20, left: 70 }}>
          <CartesianGrid vertical={false} stroke="rgba(131, 135, 154, 0.10)" />
          <YAxis axisLine={false} tickLine={false}>
            <Label angle={-90} position="insideLeft" offset={-30}>
              Quilômetros
            </Label>
          </YAxis>
          <XAxis dataKey="numeroLinha" axisLine={false} tickLine={false} />
          <Tooltip content={<TooltipMultiseries formatador={fmtIndicadorKm} />} />
          <Legend
            content={
              <CustomLegend
                disponivelColor={realizadaColor}
                titulos={titulosLegenda}
                programadaColor={programdaColor}
              />
            }
          />
          <Bar dataKey="kmRealizadaVolta" fill={realizadaColor} name={titulosLegenda[1]} />
          <Line dataKey="kmProgramadaVolta" stroke={programdaColor} fill={programdaColor} name={titulosLegenda[0]} />
        </ComposedChart>
      </ResponsiveContainer>
    </ChartBox>
  );
}
