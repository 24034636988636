import { Card, CardContent, Stack } from "@mui/material";

import Titulos from "../../../components/titulos.tsx";

export default function BoasVindas() {
  return (
    <Stack>
      <Titulos titulo="MobNit" subtitulo="Boas Vindas" />
      <Card>
        <CardContent sx={{ p: 4, mt: -2 }}>
          <p>Olá!</p>
          <p>Obrigado pela confirmação de seus dados.</p>
          <p>
            Uma solicitação de acesso foi enviada ao time responsável, muito em breve você deverá receber o acesso
            completo à plataforma.
          </p>
          <p>Enquanto isso, você pode navegar na área pública do MobNit.</p>
        </CardContent>
      </Card>
    </Stack>
  );
}
