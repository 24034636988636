import { Box, Stack, Typography } from "@mui/material";

import dayjs from "dayjs";

import BotaoLink from "../../../components/botao-link.tsx";
import { Tabela } from "../../../components/tabela";
import { useSWRFromAPI } from "../../../services/api/swr.ts";
import { fmtDataHoraCurta } from "../../../services/formatacao.ts";

import ExcluirEndpoint from "./acoes/excluir-endpoint.tsx";
import BotaoCopiar from "./botao-copiar.tsx";
import { Editar } from "./editar.tsx";

export default function ListaEndpoints() {
  const { data: registros } = useSWRFromAPI("/cadastros/apis");
  const linhas = registros.map((r) => ({
    nome: r.nome,
    endereco: r.enderecoCurto,
    dataAtualizacao: r.atualizacao?.dataUtc,
    criadoPor: r.criacao.usuarioEmail,
    chamadasRecebidas: r.chamadasRecebidas,
    id: r.id,
  }));

  return (
    <Stack border="1px solid rgba(131, 135, 154, 0.19)" borderRadius="18px" flex={1} p={3}>
      <Stack flexDirection="row" alignItems="center" gap={1} mb={2}>
        <Typography display="flex" flex={1} fontSize={20}>
          Endpoints
        </Typography>
        <BotaoLink to="/gestor/admin/apis/novo" variant="contained" size="small" sx={{ ml: "auto" }}>
          Novo endpoint
        </BotaoLink>
      </Stack>

      <Stack height="50vh">
        <Tabela
          colunas={[
            {
              id: "nome",
              label: "Nome",
              type: "custom",
              renderer: (row) => (
                <BotaoLink
                  sx={{ textDecoration: "underline", color: "inherit", textWrap: "nowrap" }}
                  to={`/gestor/admin/apis/${row.id}/detalhes`}
                >
                  {row.nome}
                </BotaoLink>
              ),
            },
            {
              id: "endereco",
              label: "Endereço",
              type: "custom",
              renderer: (row) => <BotaoCopiar valor={row.endereco} />,
            },
            {
              id: "dataAtualizacao",
              label: "Data da atualização",
              type: "custom",
              renderer: (row) => <Box>{fmtDataHoraCurta.format(dayjs.utc(row.dataAtualizacao).toDate())}</Box>,
            },
            { id: "criadoPor", label: "Criado por", type: "string" },
            { id: "chamadasRecebidas", label: "Chamadas recebidas", type: "string", width: "7em" },
            {
              id: "editar",
              label: "",
              type: "custom",
              sortable: false,
              width: "10rem",
              renderer: (row) => <Editar id={row.id} />,
            },
            {
              id: "excluir",
              label: "",
              type: "custom",
              sortable: false,
              width: "10rem",
              renderer: (row) => <ExcluirEndpoint icone nome={row.nome} url={row.endereco} id={row.id} />,
            },
          ]}
          linhas={linhas}
        />
      </Stack>
    </Stack>
  );
}
