import { useEffect } from "react";

import { Navigate, useNavigate } from "react-router-dom";

import { useAuthContext } from "../services/auth.ts";

export default function Login() {
  const { signInIntegrated, currentUser } = useAuthContext();
  const navigate = useNavigate();

  useEffect(() => {
    if (!currentUser) signInIntegrated().catch(reportError);
  }, [currentUser, navigate, signInIntegrated]);

  return <Navigate to="/gestor" />;
}
