import { Bar, BarChart, Brush, CartesianGrid, Label, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";

import ChartBox from "../../../../components/frames/chartbox.tsx";
import TooltipLinhaKm from "../../../../components/tooltips/tooltip-linha-km.tsx";
import { useDadosAPIProtegida } from "../../../../services/dados.ts";

import { CategoriaValor } from "./tipos.ts";

export default function QuilometragemFrota() {
  const { registros, ...chartProps } = useDadosAPIProtegida<CategoriaValor>({ consulta: "km-frota-grafico" });

  const realizadaColor = "#574786";

  return (
    <ChartBox {...chartProps} registros={registros} titulo="Quilometragem  da **frota**">
      <ResponsiveContainer width="100%" height="100%" style={{ fontSize: "12px" }}>
        <BarChart data={registros} width={500} height={400} margin={{ top: 15, right: 25, bottom: 25, left: 70 }}>
          <CartesianGrid vertical={false} stroke="rgba(131, 135, 154, 0.10)" />
          <XAxis
            dataKey="categoria"
            axisLine={false}
            tickLine={false}
            height={55}
            angle={-45}
            tick={{ textAnchor: "end" }}
          >
            <Label position="bottom" offset={15}>
              Veículo
            </Label>
          </XAxis>
          <YAxis dataKey="valor" axisLine={false} tickLine={false}>
            <Label angle={-90} position="insideLeft" offset={-30}>
              Quilômetros
            </Label>
          </YAxis>

          <Tooltip content={TooltipLinhaKm} />
          {registros && registros.length > 29 && <Brush height={10} endIndex={30} />}
          <Bar dataKey="valor" fill={realizadaColor} />
        </BarChart>
      </ResponsiveContainer>
    </ChartBox>
  );
}
