import { Suspense } from "react";

import { Card, Stack } from "@mui/material";

import Aguarde from "../../../components/aguarde.tsx";
import TextoEditavel from "../../../components/conteudo-editavel/texto-editavel";
import Titulos from "../../../components/titulos.tsx";

export default function Sobre() {
  return (
    <Stack gap={3}>
      <Titulos titulo="MOBNIT" subtitulo="Sobre" />
      <Stack
        component={Card}
        padding={8}
        maxWidth="80vw"
        minWidth="30vh"
        sx={{ "& p": { fontSize: "12px", fontWeight: 200, letterSpacing: "0.6px", lineHeight: "152.6%" } }}
      >
        <Suspense fallback={<Aguarde />}>
          <TextoEditavel caminho="area-publica/sobre" />
        </Suspense>
      </Stack>
    </Stack>
  );
}
