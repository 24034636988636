import { useMemo } from "react";

import dayjs from "dayjs";

/**
 * Monta um formatador de ticks adaptado para interpretar a data como UTC e exibir apenas a parte relevante
 * da data: se estão todas no mesmo mês, apresenta apenas o dia; se estão todas no mesmo ano, apresenta dia e mês,
 * e em outros casos mostra dia/mês/ano.
 */
export function useTickFormatterUTC<T extends { [k in K]: number | string }, K extends keyof T>(
  registros: T[] | undefined,
  campo: K,
) {
  return useMemo(() => {
    const menor = dayjs.utc(registros?.at(0)?.[campo]);
    const maior = dayjs.utc(registros?.at(-1)?.[campo]);

    if (maior.year() !== menor.year()) return (v: string) => dayjs.utc(v).format("DD/MM/YYYY");
    if (maior.month() !== menor.month()) return (v: string) => dayjs.utc(v).format("DD/MM");
    else return (v: string) => dayjs.utc(v).format("DD");
  }, [campo, registros]);
}
