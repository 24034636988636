import { ChangeEvent, ReactNode, useCallback } from "react";

import { Checkbox, FormControlLabel, FormControlLabelProps } from "@mui/material";

import { useFormContext } from "react-hook-form";

import { caminhoParaPermissao } from "../../../../services/permissoes.ts";

import { Inputs } from "./tipos.ts";

interface Props extends Omit<FormControlLabelProps, "control"> {
  path: string;
  label: ReactNode;
}

export default function CheckboxPermissao({ path, ...props }: Props) {
  const { watch, setValue } = useFormContext<Inputs>();

  const permissoes = watch("permissoes");

  const permissao = caminhoParaPermissao(path);

  const handleToggle = useCallback(
    (e: ChangeEvent<HTMLInputElement>, marcada: boolean) => {
      const permissao = e.target.value;
      const naLista = permissoes.has(permissao);

      if (marcada && !naLista) setValue("permissoes", permissoes.add(permissao));
      else if (!marcada && naLista) setValue("permissoes", permissoes.remove(permissao));
    },
    [permissoes, setValue],
  );

  return (
    <FormControlLabel
      control={<Checkbox size="small" value={permissao} checked={permissoes.has(permissao)} onChange={handleToggle} />}
      {...props}
    />
  );
}
