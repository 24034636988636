import { Box, SvgIcon } from "@mui/material";

export default function Fechar() {
  return (
    <Box sx={{ zoom: 0.65 }}>
      <SvgIcon>
        <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
          <line x1="1.93934" y1="15.1972" x2="15.1976" y2="1.9389" stroke="white" strokeWidth="3" />
          <line x1="15.1976" y1="15.3185" x2="1.93939" y2="2.06022" stroke="white" strokeWidth="3" />
        </svg>
      </SvgIcon>
    </Box>
  );
}
