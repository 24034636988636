import { Stack } from "@mui/material";

import Prodata from "./prodata.tsx";
import RioCard from "./rioCard.tsx";

export default function Comparativo() {
  return (
    <Stack gap={2} sx={{ mt: { xs: 1, md: -2 }, px: { xs: 1, md: 0 } }}>
      <Prodata height="50vh" />
      <RioCard height="50vh" />
    </Stack>
  );
}
