import { Navigate } from "react-router-dom";

import { IconeFrota, IconePassageiro } from "../../assets/icones";
import Reclamacoes from "../../assets/icones/reclamações.tsx";
import DashboardLayout from "../../layouts/dashboard-layout";
import { MobnitRouteObject } from "../types.ts";

import IndicadoresFrota from "./frota";
import Passageiros from "./passageiros";
import PaginaReclamacoes from "./reclamacoes";

export const routes: MobnitRouteObject = {
  path: "indicadores",
  handle: { titulo: "Indicadores" },
  element: <DashboardLayout />,
  children: [
    { index: true, element: <Navigate to="passageiros" replace /> },
    {
      path: "passageiros",
      element: <Passageiros />,
      handle: { titulo: "Passageiros", icone: <IconePassageiro /> },
    },
    {
      path: "frota",
      element: <IndicadoresFrota />,
      handle: { titulo: "Frota", icone: <IconeFrota /> },
    },
    {
      path: "reclamacoes",
      element: <PaginaReclamacoes />,
      handle: { titulo: "Reclamações", icone: <Reclamacoes /> },
    },
  ],
};
