import IndicadorComDescricao from "../../../../components/indicador-com-descricao";
import { useConteudo } from "../../../../services/conteudo";
import { useIndicador } from "../../../../services/dados.ts";
import { fmtPercentual } from "../../../../services/formatacao.ts";

export default function Icv() {
  const conteudo = useConteudo();
  const indicadorProps = useIndicador({ consulta: "icv" });

  return (
    <IndicadorComDescricao
      {...indicadorProps}
      titulo={conteudo.texto.indicadores.frota.icv.titulo}
      numberFormat={fmtPercentual}
      cor="#3661EB"
      corVariacao="#02A9F4"
      descricao={conteudo.texto.indicadores.frota.icv.tooltip}
    />
  );
}
