import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography } from "@mui/material";

import alertaIcon from "../../../../assets/icones/alert.svg";
import fecharIcon from "../../../../assets/icones/close.svg";
import { API } from "../../../../services/api";

interface Props {
  isOpen: boolean;
  toggleOpen: () => void;
  textoAcao: "Desabilitar" | "Rejeitar" | "Reabilitar";
  usuario: API["UsuarioCadastrado"];
  onClick: () => void;
}

export default function AcaoDialog({ isOpen, toggleOpen, textoAcao, usuario, onClick }: Props) {
  return (
    <Dialog
      open={isOpen}
      onClose={toggleOpen}
      sx={{ "& p": { color: "#83879A" }, "& .MuiPaper-root": { maxWidth: "27em" } }}
    >
      <IconButton sx={{ m: "0.3em 0.3em   0 auto", bgcolor: "#D9D9D9" }} onClick={toggleOpen}>
        <img src={fecharIcon} alt="Fechar" />
      </IconButton>
      <DialogTitle color="#EB5757">
        <img src={alertaIcon} alt="Alerta" style={{ marginRight: "0.5em" }} />
        {`${textoAcao} usuário`}
      </DialogTitle>
      <DialogContent>
        <Typography sx={{ "& span": { fontWeight: 700 }, "mb": 2.5 }}>
          {`Tem certeza que deseja ${textoAcao.toLowerCase()} o usuário `}
          <span>
            {usuario.nome} {usuario.sobrenome}
          </span>{" "}
          (<span>{usuario.email}</span>)?
        </Typography>
        <Typography>Esta ação ficará registrada no histórico do sistema.</Typography>
      </DialogContent>
      <DialogActions sx={{ pb: 2.5, alignSelf: "center" }}>
        <Button variant="contained" sx={{ bgcolor: "#AEAFB4" }} onClick={toggleOpen}>
          Cancelar
        </Button>
        <Button variant="contained" onClick={onClick} sx={{ bgcolor: "#EB5757" }}>
          {`${textoAcao} usuário`}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
