import IndicadorComDescricao from "../../../../components/indicador-com-descricao";
import { useConteudo } from "../../../../services/conteudo";
import { useIndicador } from "../../../../services/dados.ts";

export default function TotalViagensSubsidiadas() {
  const conteudo = useConteudo();
  const indicadorProps = useIndicador({ consulta: "total-viagens-subsidiadas" });

  return (
    <IndicadorComDescricao
      {...indicadorProps}
      titulo={conteudo.texto.auditoria.subsidios.ntvs.titulo}
      descricao={conteudo.texto.auditoria.subsidios.ntvs.tooltip}
      cor="#F5792B"
      corVariacao="#02A9F4"
    />
  );
}
