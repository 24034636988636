import { Typography } from "@mui/material";

import { useAtomValue } from "jotai";

import { filtroDataAte, filtroDataDe } from "../../../layouts/dashboard-layout/barra-filtros/valores.ts";

export default function TituloHomeGestor() {
  const from = useAtomValue(filtroDataDe);
  const to = useAtomValue(filtroDataAte);

  return (
    <>
      <Typography fontSize="1.25rem" fontWeight={700} mt={1}>
        <span style={{ fontWeight: 400 }}>Principais</span> KPIs
      </Typography>

      <Typography color="darkgray" fontSize={12}>
        Todos os indicadores se referem ao período de {from.format("DD/MM/YYYY")} até {to.format("DD/MM/YYYY")}.
      </Typography>
    </>
  );
}
