import { useMemo, useState } from "react";

import { Search } from "@mui/icons-material";
import { Card, CardContent, InputAdornment, Stack, TextField } from "@mui/material";

import { useSearchParams } from "react-router-dom";
import unidecode from "unidecode";

import BotaoDownload from "../../../components/botao-download";
import Tabela from "../../../components/tabela/tabela.tsx";
import { useSWRFromAPI } from "../../../services/api/swr.ts";
import { useRegexParaBuscaSimples } from "../../../services/buscas.ts";

const colorMap = new Map<string, [string, string]>([
  ["no_horario", ["No horário", "#087508"]],
  ["atrasado", ["Atrasado", "#ff0000"]],
  ["viagem_nao_iniciada", ["Viagem não iniciada", "#b20606"]],
  ["viagem_encerrada", ["Viagem encerrada", "#000000"]],
]);

export default function Status() {
  const { data: registros } = useSWRFromAPI("/gtfs/agenda");
  const [searchParams] = useSearchParams();
  const [filtro, setFiltro] = useState(searchParams.get("linha") ?? "");
  const fnFiltro = useRegexParaBuscaSimples(unidecode(filtro));

  const registrosTratados = useMemo(
    () =>
      registros
        .filter((r) => r.statusPontualidade !== undefined && r.statusPontualidade !== "erro")
        .map((r) => ({
          ...r,
          horario: r.horario.substring(0, 5),
        })),
    [registros],
  );

  const filtrado = useMemo(
    () =>
      registrosTratados?.filter(
        (r) =>
          !fnFiltro ||
          fnFiltro(
            unidecode(
              `${r.nomeLinha} ${r.nomeLinha} ${r.consorcio} ${colorMap.get(r.statusPontualidade ?? "")?.[0]} ${r.horario}`,
            ),
          ),
      ),
    [registrosTratados, fnFiltro],
  );

  return (
    <Card>
      <CardContent>
        <Stack position="relative" gap={2} height="60vh">
          <Stack maxWidth="30em">
            <TextField
              type="search"
              value={filtro}
              onChange={(e) => setFiltro(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Search color="disabled" />
                  </InputAdornment>
                ),
              }}
              size="small"
              label="Busca"
              disabled={!filtrado}
            />
          </Stack>

          <BotaoDownload registros={filtrado} position="absolute" right={0} top={0} visibility="visible" />
          <Stack flex={1}>
            <Tabela
              linhas={filtrado}
              colunas={[
                { id: "consorcio", label: "Consórcio", type: "string" },
                { id: "nomeLinha", label: "Nome da Linha", type: "string" },
                { id: "numeroLinha", label: "Linha", type: "string" },
                { id: "data", label: "Dia", type: "ddmm", width: "5em" },
                {
                  id: "horario",
                  label: "Horário",
                  type: "string",
                },
                {
                  id: "statusPontualidade",
                  label: "Status de pontualidade",
                  type: "string",
                  colorMap,
                },
              ]}
            />
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );
}
