import { ArrowDownward, ArrowUpward } from "@mui/icons-material";
import { Box, Stack } from "@mui/material";

import { isFinite, isNumber } from "lodash";

import { fmtDecimal, fmtNumeroInteiro } from "../../services/formatacao.ts";

export default function Variacao({ valor, valorAnterior }: { valor: number; valorAnterior: number | undefined }) {
  if (!isFinite(valorAnterior) || !isNumber(valorAnterior) || valorAnterior === 0) return <Box>ND</Box>;

  if (valorAnterior === valor)
    return <Box width="37px" height="2px" alignSelf="center" my={2} sx={{ background: "#83879A" }} />;

  const varPercent = Math.abs(100 - 100 * (valor / valorAnterior));

  const variacaoValorPercentual = varPercent > 1 ? fmtNumeroInteiro.format(varPercent) : fmtDecimal.format(varPercent);

  return (
    <Stack flexDirection="row">
      {valor > valorAnterior ? <ArrowUpward /> : <ArrowDownward />}
      {variacaoValorPercentual}%
    </Stack>
  );
}
