import { Box, Breadcrumbs, Stack, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import Pagination from "@mui/material/Pagination";
import PaginationItem from "@mui/material/PaginationItem";

import dayjs from "dayjs";
import { Link, useParams, useSearchParams } from "react-router-dom";

import { API } from "../../../../services/api";
import { useSWRFromAPI } from "../../../../services/api/swr.ts";
import { fmtDataHoraCurta } from "../../../../services/formatacao.ts";

const dateTimeFormat = new Intl.DateTimeFormat("pt-BR", {
  year: "numeric",
  month: "long",
  day: "numeric",
});

function descricaoAcao(acao: API["EntradaHistoricoUsuario"]["acao"]) {
  switch (acao) {
    case "acesso":
      return "Acesso";

    case "edicao":
      return "Edição";
    case "criacao":
      return "Criação";
    case "download":
      return "Download";
    case "exclusao":
      return "Exclusão";
    case "bloqueio":
      return "Bloqueio";
    case "desbloqueio":
      return "Desbloqueio";
  }
}

export default function HistoricoUsuario() {
  const { id = "" } = useParams();
  const { data: usuario } = useSWRFromAPI("/cadastros/usuario/{id}", { id });

  const [query] = useSearchParams();
  const pagina = +(query.get("pagina") || "1");

  const tamPagina = 15;
  const { data: historico } = useSWRFromAPI(
    "/cadastros/usuario/{id}/historico",
    { id },
    { pagina, tam_pagina: tamPagina },
  );

  if (!usuario) return null;

  if (!historico) return null;

  const parts = dateTimeFormat.formatToParts(new Date(usuario.dataRegistro));
  const colunas = [
    { id: "pagina", label: "Página" },
    { id: "dataHora", label: "Data e Hora" },
  ];

  const linhas = historico.itens.map((i) => ({
    pagina: (
      <>
        <Breadcrumbs separator=">">
          {i.categoria.map((c) => (
            <Box key={i.dataUtc}>{c}</Box>
          ))}
        </Breadcrumbs>
        <Box>({descricaoAcao(i.acao)})</Box>
      </>
    ),
    dataHora: fmtDataHoraCurta.format(dayjs.utc(i.dataUtc).toDate()),
    edicao: i.acao !== "acesso",
  }));

  return (
    <Stack color="#83879A">
      <Stack
        sx={{
          "alignSelf": "center",
          "color": "rgba(131, 135, 154, 1)",
          "& p span": { fontSize: "13px", pl: 0.2, color: "rgba(131, 135, 154, 1)" },
          "& label": { fontSize: "14px" },
          "&.month": { "&::first-letter": { textTransform: "capitalize" } },
        }}
      >
        <Stack flexDirection="row" alignItems="baseline" gap={1}>
          <Typography color="#000000" py={2}>
            {usuario.nome} {usuario.sobrenome}
          </Typography>

          <Typography sx={{ fontSize: 13 }}>
            Com acesso desde{" "}
            {parts.map((p, i) => (
              <Box
                key={i}
                component="span"
                className={p.type}
                sx={{
                  "display": "inline-block",
                  "&.weekday, &.month": { "&::first-letter": { textTransform: "capitalize" } },
                }}
              >
                {p.value}
              </Box>
            ))}
          </Typography>
        </Stack>
        <Stack border="1px solid rgba(131, 135, 154, 0.19)" borderRadius="18px" p={2}>
          <Box fontSize={18} fontWeight={500}>
            Histórico de navegação
          </Box>
          <Stack px={7} py={5} alignItems="center">
            <Table>
              <TableHead>
                <TableRow>
                  {colunas.map((c) => (
                    <TableCell size="small" key={c.id} sx={{ borderBottom: "none" }}>
                      {c.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {linhas.map((l, i) => (
                  <TableRow
                    key={i}
                    sx={{
                      "&  .MuiTableCell-root, li": { color: l.edicao ? "#F5792B" : "inherit" },
                      "tbody &:nth-of-type(odd):not(.Mui-selected)": {
                        backgroundColor: "#f7f7f7",
                      },
                      "& .MuiTableCell-root": { border: "none" },
                      "thead & .MuiTableCell-root": { borderBottom: "solid 1px rgba(131, 135, 154, 0.19)" },
                    }}
                  >
                    <TableCell size="small">{l.pagina}</TableCell>
                    <TableCell size="small">{l.dataHora}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <Pagination
              count={Math.ceil(historico.total / tamPagina)}
              page={+pagina}
              hidePrevButton
              hideNextButton
              shape="rounded"
              sx={{
                "mt": 5,
                "& button": { color: "#ffffff", bgcolor: "#D9D9D9", fontWeight: 700 },
                "& ul > li > .Mui-selected": { bgcolor: "#AEAFB4" },
              }}
              renderItem={(item) => (
                <PaginationItem component={Link} to={`${item.page === 1 ? "" : `?pagina=${item.page}`}`} {...item} />
              )}
            />
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
}
