import { useCallback, useState } from "react";

import { SettingsBackupRestore } from "@mui/icons-material";
import { Button } from "@mui/material";

import { useSnackbar } from "notistack";

import { API, typedRequest } from "../../../../services/api";
import { useAuthAxios } from "../../../../services/fetchers.ts";

import AcaoDialog from "./acao-dialog.tsx";

interface Props {
  usuario: API["UsuarioCadastrado"];
  icone?: boolean;
  onSuccess?: () => void;
}

export default function AcaoHabilitar({ usuario, icone = false, onSuccess }: Props) {
  const authAxios = useAuthAxios();
  const [isOpen, setOpen] = useState(false);
  const toggleOpen = useCallback(() => setOpen((o) => !o), []);

  const { enqueueSnackbar } = useSnackbar();

  const habilitar = useCallback(async () => {
    if (!usuario.id) return;

    try {
      await typedRequest(authAxios, "patch", "/cadastros/usuario/{id}/rehabilitar", { id: usuario.id });

      enqueueSnackbar({ variant: "success", message: "Usuário reabilitado com sucesso" });
      if (onSuccess) onSuccess();

      setOpen(false);
    } catch (e) {
      reportError(e);
      enqueueSnackbar({
        variant: "error",
        message: (
          <>
            Ocorreu um erro imprevisto ao tentar reabilitar o usuário.
            <br />
            Tente novamente mais tarde, ou entre em contato com o suporte.
          </>
        ),
      });
    }
  }, [authAxios, enqueueSnackbar, onSuccess, usuario.id]);

  return (
    <>
      <Button
        onClick={toggleOpen}
        startIcon={icone && <SettingsBackupRestore />}
        color="success"
        sx={{ textDecoration: "underline", textWrap: "nowrap" }}
        disabled={usuario.habilitado}
      >
        Reabilitar usuário
      </Button>

      <AcaoDialog
        isOpen={isOpen}
        toggleOpen={toggleOpen}
        usuario={usuario}
        onClick={habilitar}
        textoAcao="Reabilitar"
      />
    </>
  );
}
