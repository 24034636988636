import { SvgIcon } from "@mui/material";

export default function Reclamacoes() {
  return (
    <SvgIcon>
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
        <path
          d="M11.3346 13.9996C11.1997 13.9988 11.0679 13.9595 10.9546 13.8862L6.66797 10.9529H3.33464C3.15782 10.9529 2.98826 10.8827 2.86323 10.7576C2.73821 10.6326 2.66797 10.463 2.66797 10.2862V5.7129C2.66797 5.53609 2.73821 5.36652 2.86323 5.24149C2.98826 5.11647 3.15782 5.04623 3.33464 5.04623H6.66797L10.9413 2.1129C11.0417 2.03946 11.1607 1.9956 11.2848 1.98626C11.4088 1.97692 11.533 2.00248 11.6433 2.06005C11.7536 2.11762 11.8456 2.20491 11.9088 2.31204C11.9721 2.41916 12.0041 2.54185 12.0013 2.66623V13.3329C12.0013 13.5097 11.9311 13.6793 11.806 13.8043C11.681 13.9293 11.5114 13.9996 11.3346 13.9996ZM4.0013 9.61957H6.88797C7.0232 9.61793 7.15573 9.65745 7.26797 9.7329L10.668 12.0662V3.9329L7.26797 6.26623C7.15573 6.34168 7.0232 6.38121 6.88797 6.37957H4.0013V9.61957Z"
          fill="currentColor"
        />
      </svg>
    </SvgIcon>
  );
}
