import { OutlinedInput, Stack, Typography } from "@mui/material";

import buscaIcon from "../../../../assets/icones/busca.svg";
import BotaoDownload from "../../../../components/botao-download";
import NaoInformado from "../../../../components/frames/nao-informado.tsx";
import { Tabela } from "../../../../components/tabela";
import { useSWRFromAPI } from "../../../../services/api/swr.ts";
import AcaoAprovar from "../acoes/acao-aprovar.tsx";
import AcaoRejeitar from "../acoes/acao-rejeitar.tsx";

export default function Autorizacoes() {
  const { data: registros, mutate } = useSWRFromAPI("/cadastros/autorizacoes");

  return (
    <Stack flex={1}>
      <Stack flexDirection="row" alignItems="center" gap={1} mb={2}>
        <Typography display="flex" flex={1} fontSize={20}>
          Solicitações de cadastro no MobNit
        </Typography>

        <OutlinedInput
          endAdornment={<img src={buscaIcon} alt="" />}
          sx={{ height: "2.3em", ml: "auto" }}
          placeholder="Busca"
        />
        <BotaoDownload registros={registros} />
      </Stack>

      <Stack height="50vh">
        <Tabela
          colunas={[
            {
              id: "nome",
              label: "Nome",
              type: "custom",
              renderer: (row) => `${row.nome} ${row.sobrenome}`,
            },
            {
              id: "instituicao",
              label: "Instituição",
              type: "custom",
              renderer: (row) => row.instituicao || <NaoInformado>(não informada)</NaoInformado>,
            },
            {
              id: "cargo",
              label: "Cargo",
              type: "custom",
              renderer: (row) => row.cargo || <NaoInformado />,
            },
            { id: "email", label: "Email", type: "string" },
            {
              id: "rejeitar",
              label: "",
              type: "custom",
              sortable: false,
              width: "9em",
              renderer: (row) => <AcaoRejeitar usuario={row} icone onSuccess={mutate} />,
            },
            {
              id: "aprovar",
              label: "",
              width: "9em",
              type: "custom",
              sortable: false,
              renderer: (row) => <AcaoAprovar usuario={row} onSuccess={mutate} />,
            },
          ]}
          linhas={registros}
        />
      </Stack>
    </Stack>
  );
}
