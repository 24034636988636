import { Select, SelectProps } from "@mui/material";

import { FiltroProps as FiltroProps } from "./tipos.ts";

type Props<V> = Omit<SelectProps<V>, "variant"> & FiltroProps;

export default function SelecaoFiltro<V>({ definicao: d, children, ...props }: Props<V>) {
  return (
    <Select
      {...props}
      labelId={d.variavel}
      fullWidth={false}
      variant="filled"
      sx={{
        "minWidth": `${(d.titulo ?? d.variavel).length * 0.8}em`,
        "fontSize": ".75rem",
        "& .MuiSvgIcon-root": { top: "calc(50% - 0.15em)" },
        ...props.sx,
      }}
      MenuProps={{
        ...props.MenuProps,
        MenuListProps: {
          ...props.MenuProps?.MenuListProps,
          sx: {
            ...props.MenuProps?.MenuListProps?.sx,
            "paddingY": 0,
            "& .MuiTypography-root": { fontSize: "0.75rem" },
            "& .MuiMenuItem-root": { paddingLeft: 0, paddingRight: "0.5rem", paddingY: "0.10rem" },
          },
        },
        slotProps: {
          paper: { ...props.MenuProps?.slotProps?.paper, sx: { borderRadius: 1 } },
        },
      }}
    >
      {children}
    </Select>
  );
}
