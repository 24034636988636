import { useMemo } from "react";

import { constant, escapeRegExp } from "lodash";

/**
 * A partir de um texto simples, cria uma função que permite encontrar este texto
 * dentro de qualquer string. Útil para filtros de busca simples.
 */
export function useRegexParaBuscaSimples(filtro: string): (texto: string) => boolean {
  return useMemo(() => {
    if (!filtro) return constant(true);

    const rx = new RegExp(
      filtro
        .split(" ")
        .map((f) => "(^|\\s)" + escapeRegExp(f))
        .join("|"),
      "i",
    );

    return (texto: string) => rx.test(texto);
  }, [filtro]);
}
