import { MouseEvent, ReactElement, ReactNode, useCallback } from "react";

import { HighlightOff } from "@mui/icons-material";
import { Box, Chip, Stack } from "@mui/material";

import { useAtom } from "jotai";
import { useResetAtom } from "jotai/utils";

import { AtomoOpcoes } from "./atomos.ts";

interface Props {
  titulo: ReactNode;
  opcoes: AtomoOpcoes;
  iconeTitulo: ReactElement;
}

export default function SelecaoMultipla({ titulo, opcoes, iconeTitulo }: Props) {
  const reset = useResetAtom(opcoes);

  const [mapaInformacoes, setMapaInformacoes] = useAtom(opcoes);

  const handleLimpaSelecao = () => reset();
  const toggleInformacoes = useCallback(
    (e: MouseEvent<HTMLElement>) => {
      const d = e.currentTarget.innerText;
      console.log(d);
      setMapaInformacoes((m) => m.update(d, (v) => !v));
    },
    [setMapaInformacoes],
  );

  return (
    <Stack>
      <Stack flexDirection="row" gap={1} marginBottom={1} marginTop={1}>
        {iconeTitulo}
        <Box>{titulo}</Box>
      </Stack>
      <Stack flexDirection="row" gap={1} flexWrap="wrap">
        {mapaInformacoes.keySeq().map((d) => (
          <Chip
            label={<Box color={mapaInformacoes.get(d) ? "#ffffff" : "#83879A"}>{d}</Box>}
            key={d}
            onClick={toggleInformacoes}
            color={mapaInformacoes.get(d) ? "primary" : undefined}
          />
        ))}
        <Chip
          icon={<HighlightOff fontSize="small" />}
          variant="outlined"
          label="Limpar seleção"
          onClick={handleLimpaSelecao}
          sx={{ bgcolor: "white", color: "#83879A" }}
        />
      </Stack>
    </Stack>
  );
}
