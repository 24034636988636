import { lazy, Suspense } from "react";

import { Stack, StackProps } from "@mui/material";

import { useAuthContext } from "../../../services/auth.ts";
import { apiBaseURL } from "../../../services/fetchers.ts";
import Aguarde from "../../aguarde.tsx";
import ErrorBoundary from "../../error-boundary.tsx";

import Imagem from "./imagem.tsx";

const EdicaoImagem = lazy(() => import("./edicao-imagem.tsx"));

interface Props extends StackProps {
  caminho: string;
  horizontal?: boolean;
}

export default function ImagemEditavel({ caminho, horizontal, ...props }: Props) {
  const { currentUser, isAdmin, isEditor } = useAuthContext();

  const url = [apiBaseURL, "conteudo/imagens", caminho].map((p) => p.replace(/^\/|\/$/g, "")).join("/");

  if (!currentUser || !(isAdmin || isEditor)) return <Imagem {...props} imagem={url} horizontal={horizontal} />;

  return (
    <ErrorBoundary>
      <Suspense
        fallback={
          <Stack {...props}>
            <Aguarde />
          </Stack>
        }
      >
        <EdicaoImagem {...props} imagemPadrao={url} horizontal={horizontal} />
      </Suspense>
    </ErrorBoundary>
  );
}
