import { useCallback, useState } from "react";

import { Button } from "@mui/material";

import { useSnackbar } from "notistack";

import DeleteIcon from "../../../../assets/icones/delete.tsx";
import { API, typedRequest } from "../../../../services/api";
import { useAuthAxios } from "../../../../services/fetchers.ts";

import AcaoDialog from "./acao-dialog.tsx";

interface Props {
  usuario: API["UsuarioCadastrado"];
  icone?: boolean;
  onSuccess?: () => void;
}

export default function AcaoDesabilitar({ usuario, icone = false, onSuccess }: Props) {
  const authAxios = useAuthAxios();
  const [isOpen, setOpen] = useState(false);
  const toggleOpen = useCallback(() => setOpen((o) => !o), []);

  const { enqueueSnackbar } = useSnackbar();

  const desabilitar = useCallback(async () => {
    if (!usuario.id) return;

    try {
      await typedRequest(authAxios, "patch", "/cadastros/usuario/{id}/desabilitar", { id: usuario.id });

      enqueueSnackbar({ variant: "success", message: "Usuário desabilitado com sucesso" });
      if (onSuccess) onSuccess();

      setOpen(false);
    } catch (e) {
      reportError(e);
      enqueueSnackbar({
        variant: "error",
        message: (
          <>
            Ocorreu um erro imprevisto ao tentar desabilitar o usuário.
            <br />
            Tente novamente mais tarde, ou entre em contato com o suporte.
          </>
        ),
      });
    }
  }, [authAxios, enqueueSnackbar, onSuccess, usuario.id]);

  return (
    <>
      <Button
        onClick={toggleOpen}
        startIcon={icone && <DeleteIcon />}
        sx={{ color: "#EB5757", textDecoration: "underline", textWrap: "nowrap" }}
        disabled={!usuario.habilitado}
      >
        Desabilitar usuário
      </Button>

      <AcaoDialog
        isOpen={isOpen}
        toggleOpen={toggleOpen}
        usuario={usuario}
        onClick={desabilitar}
        textoAcao="Desabilitar"
      />
    </>
  );
}
