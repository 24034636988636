import { lazy, Suspense } from "react";

import { Alert, Stack, Typography } from "@mui/material";

import { isPermissionError, isQueryError } from "../../services/api";

const ErroApiConsulta = lazy(() => import("./erro-api-consulta.tsx"));

interface Props {
  erro: unknown;
  indicador?: boolean;
}

export default function ErroDados({ erro, indicador }: Props) {
  if (isPermissionError(erro)) return <ErroPermissao />;
  if (!import.meta.env.DEV || !isQueryError(erro)) return <ErroPadrao />;

  return (
    <Suspense fallback={<ErroPadrao />}>
      <ErroApiConsulta erro={erro.response.data} indicador={indicador} />
    </Suspense>
  );
}

function ErroPermissao() {
  return (
    <Stack flex={1} alignItems="center" justifyContent="center">
      <Alert color="warning">
        <Typography variant="subtitle2">Erro</Typography>
        Seu perfil não tem acesso a esta informação.
      </Alert>
    </Stack>
  );
}

function ErroPadrao() {
  return (
    <Stack flex={1} alignItems="center" justifyContent="center">
      <Alert color="error">
        <Typography variant="subtitle2">Erro</Typography>
        Ocorreu um erro ao consultar os dados.
      </Alert>
    </Stack>
  );
}
