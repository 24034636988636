import editarIcon from "../../../../assets/icones/edit.svg";
import BotaoLink from "../../../../components/botao-link.tsx";

interface Props {
  id: string;
}

export default function AcaoEditar({ id }: Props) {
  return (
    <BotaoLink
      to={`/gestor/admin/usuarios/${id}/editar`}
      startIcon={<img src={editarIcon} alt="" />}
      sx={{ color: "#02A9F4", textDecoration: "underline", textWrap: "nowrap" }}
    >
      Editar usuário
    </BotaoLink>
  );
}
