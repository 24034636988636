import { Stack, StackProps } from "@mui/material";

import Markdown from "react-markdown";

interface Props extends StackProps {
  conteudo: string;
}

export default function Texto({ conteudo, children, ...props }: Props) {
  return (
    <Stack position="relative" {...props}>
      {children}
      <Markdown>{conteudo}</Markdown>
    </Stack>
  );
}
