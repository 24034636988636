import { useMemo } from "react";

import { Card, CardContent, Stack, Typography } from "@mui/material";

import dayjs from "dayjs";
import { createStore, Provider as AtomProvider } from "jotai";

import Titulos from "../../../components/titulos.tsx";
import { filtroDataAte, filtroDataDe } from "../../../layouts/dashboard-layout/barra-filtros/valores.ts";

import AlertasKpis from "./alertas-kpis.tsx";
import Ici from "./indicadores-sem-filtros/ici.tsx";
import Icv from "./indicadores-sem-filtros/icv.tsx";
import Idf from "./indicadores-sem-filtros/idf.tsx";
import Ipk from "./indicadores-sem-filtros/ipk.tsx";
import Pmm from "./indicadores-sem-filtros/pmm.tsx";
import Pvd from "./indicadores-sem-filtros/pvd.tsx";
import TotalPassageiros from "./indicadores-sem-filtros/total-passageiros.tsx";
import TotalViagens from "./indicadores-sem-filtros/total-viagens.tsx";
import TituloHomeGestor from "./titulo-home-gestor.tsx";

export default function HomeGestor() {
  // sobrescreve os átomos de filtros para que fiquem fixos na página do gestor
  const storeOverride = useMemo(() => {
    const mesAnterior = dayjs().subtract(1, "month");
    const from = mesAnterior.startOf("month");
    const to = mesAnterior.endOf("month");

    const store = createStore();
    store.set(filtroDataDe, from);
    store.set(filtroDataAte, to);

    return store;
  }, []);

  return (
    <AtomProvider store={storeOverride}>
      <Titulos titulo="Mobnit" subtitulo="Home" />

      <Card>
        <CardContent sx={{ p: 4 }}>
          <Typography fontSize="1.25rem" fontWeight={700}>
            Alertas
          </Typography>

          <AlertasKpis />

          <TituloHomeGestor />

          <Stack
            display="grid"
            gap={1}
            gridTemplateColumns={{ xs: "1fr", lg: "repeat(2, 1fr)", xl: "repeat(3, 1fr)" }}
            gridAutoRows="10rem"
            mt={3}
          >
            <TotalPassageiros />
            <TotalViagens />
            <Ipk />
            <Idf />
            <Pvd />
            <Icv />
            <Ici />
            <Pmm />
          </Stack>
        </CardContent>
      </Card>
    </AtomProvider>
  );
}
