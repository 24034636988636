import { useCallback } from "react";

import { Button } from "@mui/material";

import { useSearchParams } from "react-router-dom";

export default function BotaoVerItinerario({ valor }: { valor: string }) {
  const [, setParams] = useSearchParams();

  const handleClick = useCallback(
    () =>
      setParams((p) => {
        if (valor) p.set("linha", valor);
        else p.delete("linha");

        return p;
      }),
    [setParams, valor],
  );

  return (
    <Button variant="text" sx={{ textDecoration: "underline" }} onClick={handleClick}>
      Ver
    </Button>
  );
}
