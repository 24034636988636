import TableBox from "../../../components/frames/table-box.tsx";
import { useDadosAPIPaginados } from "../../../services/dados.ts";

import { type VariacaoFrota } from "./tipos.ts";

export default function VariacaoFrota() {
  const tabelaProps = useDadosAPIPaginados<VariacaoFrota>({ consulta: "empresas-e-linhas-com-variacao" });

  return (
    <TableBox
      {...tabelaProps}
      titulo="Empresas e Linhas com Variação de Frota"
      colunas={[
        { id: "consorcio", label: "Consórcio", type: "string" },
        { id: "empresas", label: "Empresa", type: "custom", renderer: (r) => r.empresas?.join(" / ") ?? r.empresa },
        { id: "linha", label: "Linha", type: "string", width: "4rem" },
        { id: "data", label: "Data", type: "date" },
        { id: "tipoDia", label: "Tipo Dia", type: "string" },
        { id: "turnoDia", label: "Turno", type: "string" },
        { id: "porcentagemVariacao", label: "% de variação", type: "number", width: "5rem" },
        { id: "frotaDisponivel", label: "Frota disponível", type: "inteiro", width: "5.5rem" },
        { id: "frotaProgramada", label: "Frota programada", type: "inteiro", width: "5.5rem" },
      ]}
    />
  );
}
