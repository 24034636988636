import IndicadorComDescricao from "../../../../components/indicador-com-descricao";
import { useConteudo } from "../../../../services/conteudo";
import { useIndicador } from "../../../../services/dados.ts";
import { fmtPercentual } from "../../../../services/formatacao.ts";

export default function Idf() {
  const conteudo = useConteudo();
  const indicadorProps = useIndicador({ consulta: "idf" });

  return (
    <IndicadorComDescricao
      {...indicadorProps}
      titulo={conteudo.texto.indicadores.frota.idf.titulo}
      numberFormat={fmtPercentual}
      cor="#F5792B"
      corVariacao="#FFA800"
      descricao={conteudo.texto.indicadores.frota.idf.tooltip}
    />
  );
}
