import { createContext, MouseEvent, ReactNode, useContext } from "react";

export type Order = "asc" | "desc";

export type TipoColuna =
  | "string"
  | "number"
  | "percentual"
  | "time"
  | "date"
  | "datetime"
  | "ddmm"
  | "km"
  | "link"
  | "custom"
  | "inteiro"
  | "geojson"
  | "coordenadas-latlng";

export interface InfoColuna<T extends ObjetoDados = ObjetoDados> {
  id: string;
  label: string;
  type: TipoColuna;
  colorMap?: Map<string, [string, string]>;
  width: number | string;
  sortable: boolean;
  renderer?: (row: T) => ReactNode;
}

export type PropsCalculaveis = "width" | "sortable";

export type InfoMinimaColunas<T extends ObjetoDados = ObjetoDados> = Omit<InfoColuna<T>, PropsCalculaveis> &
  Partial<Pick<InfoColuna<T>, PropsCalculaveis>>;

export type ObjetoDados = Record<string, unknown>;

export interface DataRowsContextVar<T extends ObjetoDados> {
  linhas: T[];
  colunas: InfoColuna<T>[];
  order: Order;
  orderBy: string | undefined;
  onSort: (e: MouseEvent<HTMLElement>) => void;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const DataRowsContext = createContext({} as DataRowsContextVar<any>);

export function useDataRowsContext<T extends ObjetoDados>() {
  return useContext(DataRowsContext) as DataRowsContextVar<T>;
}
