import { Card, CardContent, Stack } from "@mui/material";

import Info from "../../../assets/icones/info.tsx";
import MobilidadeIcon from "../../../assets/icones/mobilidade.tsx";
import Titulos from "../../../components/titulos.tsx";

import { informacoesSelecionadasAtom, linhasSelecionadasAtom } from "./atomos.ts";
import MapaMobilidade from "./mapa-mobilidade.tsx";
import SelecaoMultipla from "./selecao-multipla.tsx";

export default function Mobilidade() {
  return (
    <Stack flex={1} gap={1}>
      <Titulos titulo="Mapas" subtitulo="Mobilidade" sx={{ mr: { md: "auto" } }} />

      <Card sx={{ paddingX: 1 }}>
        <CardContent sx={{ fontWeight: 500, color: "#83879A" }}>
          <Stack gap={2}>
            <SelecaoMultipla titulo="Informações" opcoes={informacoesSelecionadasAtom} iconeTitulo={<Info />} />
            <SelecaoMultipla titulo="Linhas" opcoes={linhasSelecionadasAtom} iconeTitulo={<MobilidadeIcon />} />
            <MapaMobilidade mapHeight="500px" />
          </Stack>
        </CardContent>
      </Card>
    </Stack>
  );
}
