import { Stack } from "@mui/material";

import HorarioPicoPassageiros from "./horario-pico-passageiros.tsx";
import PassageirosFrota from "./passageiros-frota.tsx";
import PassageirosLinha from "./passageiros-linha.tsx";
import PassageirosTransportados from "./passageiros-transportados.tsx";
import PassageirosVeiculo from "./passageiros-veiculo.tsx";
import QtdPassageirosDia from "./qtd-passageiros-dia.tsx";

export default function Passageiros() {
  return (
    <Stack gap={2} sx={{ p: { xs: 1, md: 0 } }}>
      <HorarioPicoPassageiros />
      <QtdPassageirosDia />
      <PassageirosLinha />
      <PassageirosVeiculo />
      <PassageirosTransportados />
      <PassageirosFrota />
    </Stack>
  );
}
