import { OutlinedInput, Stack, Typography } from "@mui/material";

import buscaIcon from "../../../../assets/icones/busca.svg";
import BotaoDownload from "../../../../components/botao-download";
import BotaoLink from "../../../../components/botao-link.tsx";
import NaoInformado from "../../../../components/frames/nao-informado.tsx";
import { Tabela } from "../../../../components/tabela";
import { useSWRFromAPI } from "../../../../services/api/swr.ts";
import AcaoEditar from "../acoes/acao-editar.tsx";
import AcaoHabilitarDesabilitar from "../acoes/acao-habilitar-desabilitar.tsx";
import AcaoHistorico from "../acoes/acao-historico.tsx";

export default function ListaUsuarios() {
  const { data: registros, mutate } = useSWRFromAPI("/cadastros/usuarios");

  return (
    <Stack flex={1}>
      <Stack flexDirection="row" alignItems="center" gap={1} mb={2}>
        <Typography display="flex" flex={1} fontSize={20}>
          Usuários com acesso ao MobNit
        </Typography>
        <BotaoLink to="/gestor/admin/usuarios/novo" variant="contained" sx={{ ml: "auto" }}>
          Novo usuário
        </BotaoLink>
        <OutlinedInput endAdornment={<img src={buscaIcon} alt="" />} sx={{ height: "2.3em" }} placeholder="Busca" />
        <BotaoDownload registros={registros} />
      </Stack>

      <Stack height="50vh">
        <Tabela
          colunas={[
            {
              id: "nome",
              label: "Nome",
              type: "custom",
              renderer: (row) => `${row.nome} ${row.sobrenome}`,
            },
            { id: "email", label: "Email", type: "string" },
            { id: "perfil", label: "Perfil", type: "string" },
            {
              id: "instituicao",
              label: "Instituição",
              type: "custom",
              renderer: (row) => row.instituicao || <NaoInformado>(não informada)</NaoInformado>,
            },
            {
              id: "editar",
              label: "",
              type: "custom",
              sortable: false,
              width: "8rem",
              renderer: (row) => <AcaoEditar id={row.id} />,
            },
            {
              id: "desabilitar",
              label: "",
              type: "custom",
              sortable: false,
              width: "10rem",
              renderer: (row) => <AcaoHabilitarDesabilitar icone usuario={row} onSuccess={mutate} />,
            },
            {
              id: "histórico",
              label: "",
              width: "9em",
              type: "custom",
              sortable: false,
              renderer: (row) => <AcaoHistorico id={row.id} />,
            },
          ]}
          linhas={registros}
        />
      </Stack>
    </Stack>
  );
}
