import { SvgIcon } from "@mui/material";

export default function VideoCameraIcon() {
  return (
    <SvgIcon>
      <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M19.25 6.55384C18.9715 6.42844 18.6623 6.38765 18.3608 6.43654C18.0593 6.48543 17.7788 6.62185 17.5542 6.82884L15.5834 8.66217V7.33301C15.5834 6.60366 15.2936 5.90419 14.7779 5.38846C14.2622 4.87274 13.5627 4.58301 12.8334 4.58301H4.58337C3.85403 4.58301 3.15456 4.87274 2.63883 5.38846C2.12311 5.90419 1.83337 6.60366 1.83337 7.33301V14.6663C1.83337 15.3957 2.12311 16.0952 2.63883 16.6109C3.15456 17.1266 3.85403 17.4163 4.58337 17.4163H12.8334C13.5627 17.4163 14.2622 17.1266 14.7779 16.6109C15.2936 16.0952 15.5834 15.3957 15.5834 14.6663V13.3372L17.5634 15.1705C17.8549 15.4344 18.2335 15.5813 18.6267 15.583C18.8449 15.5825 19.0605 15.5356 19.2592 15.4455C19.5296 15.3361 19.7612 15.1487 19.9245 14.907C20.0878 14.6653 20.1753 14.3805 20.1759 14.0888V7.91051C20.1745 7.6178 20.0857 7.33219 19.9207 7.09042C19.7557 6.84865 19.5221 6.66177 19.25 6.55384ZM13.75 14.6663C13.75 14.9095 13.6535 15.1426 13.4816 15.3145C13.3096 15.4864 13.0765 15.583 12.8334 15.583H4.58337C4.34026 15.583 4.1071 15.4864 3.93519 15.3145C3.76328 15.1426 3.66671 14.9095 3.66671 14.6663V7.33301C3.66671 7.08989 3.76328 6.85673 3.93519 6.68483C4.1071 6.51292 4.34026 6.41634 4.58337 6.41634H12.8334C13.0765 6.41634 13.3096 6.51292 13.4816 6.68483C13.6535 6.85673 13.75 7.08989 13.75 7.33301V14.6663ZM18.3334 13.383L15.7575 10.9997L18.3334 8.61634V13.383Z"
          fill="currentColor"
        />
      </svg>
    </SvgIcon>
  );
}
