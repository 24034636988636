import { useMemo } from "react";

import { groupBy, keys, mapValues, sortBy, uniq } from "lodash";

import IndicadorComDescricao from "../../../components/indicador-com-descricao";
import { useDadosAPIProtegida } from "../../../services/dados.ts";
import { cycle } from "../../../services/util.ts";

export default function VariacaoReclamacoesPorCategoria() {
  const { registros, variacao } = useDadosAPIProtegida<{ tipo: string; valor: number }>({
    consulta: "variacao-reclamacoes-por-categoria",
    camposVariacao: ["Ano", "Mes", "Dia"],
  });

  const cores = cycle(["#3661EB", "#59C3C8", "#114855"]);
  const corVariacao = "#FFA800";

  const reclamacoesPorTipo = useMemo(() => {
    if (!registros || !variacao) return;

    const regsPorTipo = mapValues(groupBy(registros, "tipo"), ([{ valor }]) => valor);
    const varsPorTipo = mapValues(groupBy(variacao, "tipo"), ([{ valor }]) => valor);

    const resultados = uniq([...keys(regsPorTipo), ...keys(varsPorTipo)])
      .map((k) => [k, [regsPorTipo[k] ?? 0, varsPorTipo[k] ?? 0]] as const)
      .filter(([, [valor, variacao]]) => valor > 0 || variacao > 0);

    return sortBy(resultados, ([k]) => (/[oô]nibus/i.test(k) ? "0" + k : k).toLocaleLowerCase());
  }, [registros, variacao]);

  return (
    <>
      {reclamacoesPorTipo?.map(([tipo, [valor, valorAnterior]]) => (
        <IndicadorComDescricao
          carregando={false}
          erro={undefined}
          erroVariacao={undefined}
          descricao=""
          key={tipo}
          titulo={`Categoria  **${tipo}**`}
          valor={valor}
          valorAnterior={valorAnterior}
          cor={cores.next().value}
          corVariacao={corVariacao}
        />
      ))}
    </>
  );
}
