import { useCallback } from "react";

import { Stack, Typography } from "@mui/material";

import { List } from "immutable";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";

import BotaoLink from "../../../../components/botao-link.tsx";
import { typedRequest } from "../../../../services/api";
import { useSWRFromAPI } from "../../../../services/api/swr.ts";
import { useAuthAxios } from "../../../../services/fetchers.ts";
import Formulario from "../formulario/formulario.tsx";
import { Inputs } from "../tipos.ts";

export default function EditarEndpoint() {
  const { id = "" } = useParams();
  const { data: endpoint } = useSWRFromAPI("/cadastros/apis/{id}", { id }, { forEditing: true });
  const navigate = useNavigate();
  const authAxios = useAuthAxios();

  const form = useForm<Inputs>({
    defaultValues: {
      nome: endpoint?.nome,
      cors: endpoint?.cors,
      recorteTemporal: endpoint?.recorteTemporal,
      comentario: endpoint?.comentario,
      dados: List<string>(endpoint?.dados ?? []),
    },
  });

  const submit = useCallback(
    async (values: Inputs) => {
      await typedRequest(
        authAxios,
        "put",
        "/cadastros/apis/{id}",
        { id },
        {
          data: {
            cors: values.cors ?? "",
            nome: values.nome,
            recorte_temporal: values.recorteTemporal ?? "ultimo_mes",
            comentario: values.comentario,
            dados: values.dados.toArray(),
          },
        },
      );

      navigate("/gestor/admin/apis");
    },
    [authAxios, id, navigate],
  );

  return (
    <FormProvider {...form}>
      <Stack gap={3}>
        <Stack width="7em">
          <BotaoLink variant="contained" to="/gestor/admin/apis" color="secondary">
            Voltar
          </BotaoLink>
        </Stack>
        <Stack border="1px solid rgba(131, 135, 154, 0.19)" borderRadius="18px" flex={1}>
          <Stack
            pb={3}
            sx={{
              "maxWidth": "40em",
              "alignSelf": "center",
              "color": "rgba(131, 135, 154, 1)",
              "& label": { fontSize: "14px" },
            }}
          >
            <Typography color="#000000" py={2}>
              Editar endpoint
            </Typography>
            <Formulario submit={submit} />
          </Stack>
        </Stack>
      </Stack>
    </FormProvider>
  );
}
