import { useMemo } from "react";

import { HeatmapLayerFactory } from "@vgrid/react-leaflet-heatmap-layer";
import { LayersControl } from "react-leaflet";

import { useDadosAPIProtegida } from "../../../services/dados.ts";

import { Contador, PontoHeatmap, RegistroHeatmap } from "./types.ts";

const HeatmapLayer = HeatmapLayerFactory<PontoHeatmap>();

interface Props {
  label: string;
  contador: Contador;
}

export default function CamadaCalor({ label, contador }: Props) {
  const { registros } = useDadosAPIProtegida<RegistroHeatmap>({ consulta: "heatmap", suspense: true });

  const pontos = useMemo(() => registros?.map((d): PontoHeatmap => [d.lng, d.lat, d[contador]]), [contador, registros]);

  if (!pontos) return null;

  return (
    <LayersControl.Overlay name={label} checked>
      <HeatmapLayer
        fitBoundsOnLoad
        fitBoundsOnUpdate
        points={pontos}
        longitudeExtractor={extrator(0)}
        latitudeExtractor={extrator(1)}
        intensityExtractor={extrator(2)}
      />
    </LayersControl.Overlay>
  );
}

/**
 * Como a tipagem do HeatmapLayer exige uma função extratora com parâmetro 'unknown',
 * utilizamos essa nova função para gerar uma e apresentá-la como o tipo que ele espera.
 */
function extrator(p: number) {
  return ((m: number[]) => m[p]) as (point: unknown) => number;
}
