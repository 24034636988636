import { Stack } from "@mui/material";

import PercursoMedioMensal from "./paineis/percurso-medio-mensal.tsx";
import ProgramadaIda from "./paineis/programada-ida.tsx";
import ProgramadaMorto from "./paineis/programada-morto.tsx";
import ProgramadaVolta from "./paineis/programada-volta.tsx";
import QuilometragemFrota from "./paineis/quilometragem-frota.tsx";
import TabelaQuilometragemFrota from "./quilometragem-da-frota.tsx";
import TabelaVariacaoQuilometragem from "./variacao-quilometragem.tsx";

export default function Quilometragem() {
  return (
    <Stack gap={2} sx={{ p: { xs: 1, md: 0 } }}>
      <Stack gridTemplateColumns="1fr 25rem" gap={2} sx={{ display: { xs: "flex", md: "grid" } }}>
        <QuilometragemFrota />
        <PercursoMedioMensal />
      </Stack>
      <ProgramadaIda />
      <ProgramadaVolta />
      <ProgramadaMorto />
      <TabelaVariacaoQuilometragem />
      <TabelaQuilometragemFrota />
    </Stack>
  );
}
