import { ReactNode } from "react";

import { Card, CardContent, Divider, Stack, Typography } from "@mui/material";

import { useRotasAgrupadas } from "../../routes/hooks.ts";

import MenuButton from "./menu-button.tsx";

export const MenuDesktop = () => {
  const [grupos, individuais] = useRotasAgrupadas();

  return (
    <Card sx={{ width: { xs: "100%", md: "15em" }, alignSelf: "flex-start" }}>
      <CardContent sx={{ p: 4 }}>
        <Stack gap={{ xs: 3, md: 1 }} my={-1.5}>
          {grupos.map(
            ([rotaGrupo, path, rotasFilhos]) =>
              rotaGrupo.path !== "admin" && (
                <Bloco key={path} titulo={rotaGrupo.handle?.titulo ?? ""}>
                  {rotasFilhos?.map(([rotaFilho, path]) => (
                    <MenuButton key={path} to={path} startIcon={rotaFilho.handle?.icone}>
                      {rotaFilho.handle?.titulo ?? ""}
                    </MenuButton>
                  ))}
                </Bloco>
              ),
          )}

          <Divider sx={{ mx: -4, mt: 2 }} />

          {individuais
            .filter(([, path]) => path.startsWith("/gestor"))
            .map(([r, path]) => (
              <MenuButton key={path} to={path} startIcon={r.handle?.icone}>
                {r.handle?.titulo ?? ""}
              </MenuButton>
            ))}
        </Stack>
      </CardContent>
    </Card>
  );
};

const Bloco = ({ titulo, children }: { titulo: string; children: ReactNode }) => (
  <>
    <Typography textTransform="uppercase" color="primary.main" fontSize={14} fontWeight={800} sx={{ mx: -0.5 }}>
      {titulo}
    </Typography>
    <Stack gap={{ xs: 2, md: 0 }}>{children}</Stack>
  </>
);
