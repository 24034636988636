import { useCallback, useState } from "react";

import { Menu as MenuIcon } from "@mui/icons-material";
import { Backdrop, Box, Button, Stack } from "@mui/material";

import ArrowBack from "../../../assets/icones/arrowback.tsx";
import BotaoLink from "../../../components/botao-link.tsx";
import { useAuthContext } from "../../../services/auth.ts";

import MenuMobilidade from "./mobilidade.tsx";
import MenuPrefeitura from "./prefeitura-niteroi.tsx";

export default function BackdropMobile() {
  const { signOut, currentUser, signInIntegrated } = useAuthContext();
  const [open, setOpen] = useState(false);
  const handleClose = useCallback(() => setOpen(false), [setOpen]);
  const handleOpen = useCallback(() => setOpen(true), [setOpen]);

  return (
    <Box mr={-1.2} ml="auto">
      <Button onClick={handleOpen} startIcon={<MenuIcon sx={{ zoom: 1.5 }} />} size="large" sx={{ p: 0 }} />
      <Backdrop
        sx={{
          alignItems: "flex-start",
          justifyContent: "flex-end",
          color: "#fff",
          zIndex: 21474836,
          backgroundColor: "rgba(0,0,0,0.72)",
        }}
        open={open}
        onClick={handleClose}
      >
        <Stack sx={{ mt: 1, fontSize: 34, fontWeight: 700, overflowY: "auto", height: "100vh" }}>
          <Button
            onClick={handleClose}
            sx={{ width: "60px", alignSelf: "flex-end", justifyContent: "flex-end", zoom: 1.5 }}
          >
            <ArrowBack sx={{ rotate: "180deg" }} />
          </Button>

          <BotaoLink
            to="/"
            sx={{ textTransform: "none", fontSize: "inherit", fontWeight: "inherit", justifyContent: "flex-end" }}
          >
            Home
          </BotaoLink>
          <BotaoLink
            to="/sobre"
            sx={{ textTransform: "none", fontSize: "inherit", fontWeight: "inherit", justifyContent: "flex-end" }}
          >
            Sobre
          </BotaoLink>
          {currentUser ? (
            <>
              <MenuMobilidade />
              <MenuPrefeitura />
              <BotaoLink
                to="/gestor"
                sx={{ textTransform: "none", fontSize: "inherit", fontWeight: "inherit", justifyContent: "flex-end" }}
              >
                Área Gestor
              </BotaoLink>
              <Button
                onClick={signOut}
                sx={{ textTransform: "none", fontSize: "inherit", fontWeight: "inherit", justifyContent: "end" }}
              >
                Logout
              </Button>
            </>
          ) : (
            <Button
              onClick={signInIntegrated}
              sx={{ textTransform: "none", fontSize: "inherit", fontWeight: "inherit" }}
            >
              Login
            </Button>
          )}
        </Stack>
      </Backdrop>
    </Box>
  );
}
