import { ReactNode } from "react";

import { Box, Stack, Typography } from "@mui/material";

import Markdown from "react-markdown";

import BotaoDownload from "../botao-download";
import { type Props as TabelaProps, Tabela } from "../tabela";
import { ObjetoDados } from "../tabela/types.ts";

import ErroDados from "./erro-dados.tsx";
import SemDados from "./sem-dados.tsx";

interface Props<T extends ObjetoDados> extends Omit<TabelaProps<T>, "linhas"> {
  registros: T[] | undefined;
  titulo: string;
  erro: unknown;
  height?: string;
}

export default function TableBox<T extends ObjetoDados>({
  titulo,
  registros: linhas,
  erro,
  height = "calc(max(30vh, 20em))",
  ...props
}: Props<T>) {
  return (
    <Stack
      gap={2}
      position="relative"
      sx={{ "&:hover .botao-download, &:has(button.active) .botao-download": { visibility: "visible" } }}
    >
      {!linhas?.length ? null : <BotaoDownload registros={linhas} />}

      <Typography fontSize={20} component="div" sx={{ "& p": { margin: 0 } }}>
        <Markdown>{titulo}</Markdown>
      </Typography>

      <Box height={height} display="flex" position="relative">
        <Miolo semDados={linhas?.length === 0} erro={erro}>
          <Tabela {...props} linhas={linhas} />
        </Miolo>
      </Box>
    </Stack>
  );
}

interface MioloProps {
  semDados: boolean;
  erro: unknown;
  children?: ReactNode;
}

function Miolo({ semDados, erro, children }: MioloProps) {
  if (erro) return <ErroDados erro={erro} />;

  return (
    <>
      {semDados && (
        <Stack width="100%" height="100%" alignItems="center" justifyContent="center" position="absolute" mt={3}>
          <SemDados />
        </Stack>
      )}
      {children}
    </>
  );
}
