import { lazy, Suspense } from "react";

import { useSWRFromAPI } from "../../../services/api/swr.ts";
import { useAuthContext } from "../../../services/auth.ts";
import Aguarde from "../../aguarde.tsx";
import ErrorBoundary from "../../error-boundary.tsx";

import Texto from "./texto.tsx";

const EdicaoTexto = lazy(() => import("./edicao-texto.tsx"));

interface Props {
  caminho: string;
}

export default function TextoEditavel(props: Props) {
  return (
    <ErrorBoundary>
      <Suspense fallback={<Aguarde />}>
        <TextoEditavelInterno {...props} />
      </Suspense>
    </ErrorBoundary>
  );
}

export function TextoEditavelInterno({ caminho }: Props) {
  const { currentUser, isAdmin, isEditor } = useAuthContext();

  const { data: conteudo, mutate } = useSWRFromAPI("/conteudo/textos/{caminho}", { caminho }, undefined, {
    revalidateIfStale: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });

  if (!currentUser || !(isAdmin || isEditor)) return <Texto conteudo={conteudo} />;

  return <EdicaoTexto caminho={caminho} textoOriginal={conteudo} invalidate={mutate} />;
}
