import { ReactNode } from "react";

import { Card, CardContent, Stack, Typography } from "@mui/material";

import { useRotasAgrupadas } from "../../routes/hooks.ts";
import { useAuthContext } from "../../services/auth.ts";

import MenuButton from "./menu-button.tsx";

export const MenuDesktopAdmin = () => {
  const { currentUser } = useAuthContext();
  const [grupos] = useRotasAgrupadas();

  if (!currentUser?.roles.includes("admin")) return null;

  return (
    <Card sx={{ width: { xs: "100%", md: "15em" }, alignSelf: "flex-start", bgcolor: "#232323" }}>
      <CardContent sx={{ p: 4 }}>
        <Stack gap={{ xs: 3, md: 1 }} my={-1.5}>
          {grupos.map(
            ([rotaGrupo, path, rotasFilhos]) =>
              rotaGrupo.path === "admin" && (
                <Bloco key={path} titulo={rotaGrupo.handle?.titulo ?? ""}>
                  {rotasFilhos?.map(([rotaFilho, path]) => (
                    <MenuButton
                      key={path}
                      to={path}
                      startIcon={rotaFilho.handle?.icone}
                      sx={{
                        "color": "#ffffff",
                        "& span": { color: "#83879A" },
                        ["&:hover, &.active"]: { span: { color: "#535353" }, bgcolor: "#83879A", color: "#535353" },
                      }}
                    >
                      {rotaFilho.handle?.titulo ?? ""}
                    </MenuButton>
                  ))}
                </Bloco>
              ),
          )}
        </Stack>
      </CardContent>
    </Card>
  );
};

const Bloco = ({ titulo, children }: { titulo: string; children: ReactNode }) => (
  <>
    <Typography textTransform="uppercase" color="#83879A" fontSize={14} fontWeight={800} sx={{ mx: -0.5 }}>
      {titulo}
    </Typography>
    <Stack gap={{ xs: 2, md: 0 }}>{children}</Stack>
  </>
);
