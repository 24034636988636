import { Box, Stack } from "@mui/material";

import Info from "../../../assets/icones/info.tsx";

export default function AlertasKpis() {
  const cores = ["#683AB7", "#EB5757", "#59C3C8"];
  const mostrar = false;

  return (
    <Stack gap={1} py={2}>
      <Stack flexDirection="row" gap={0.25} height={50}>
        <Stack sx={{ backgroundColor: "#83879A", color: "#ffffff", p: 1, borderRadius: "7px 0   0 7px" }}></Stack>
        <Stack
          flex={1}
          sx={{
            backgroundColor: "#83879A",
            color: "#ffffff",
            justifyContent: "center",
            fontSize: "0.75rem",
            pl: 3,
            borderRadius: "0 7px 7px 0",
          }}
        >
          No momento não há alertas a serem exibidos
        </Stack>
      </Stack>
      {mostrar &&
        cores.map((c, i) => (
          <Stack flexDirection="row" key={i} gap={0.25}>
            <Stack
              display="grid"
              gridTemplateColumns="2.5rem 12rem"
              fontSize="0.77rem"
              sx={{ backgroundColor: c, color: "#ffffff", p: 1, borderRadius: "7px 0   0 7px" }}
            >
              <Box gridRow="span 2" alignSelf="center">
                <Info sx={{ fontSize: "2.2rem" }} />
              </Box>
              <Box>Alerta</Box>
              <Box sx={{ fontWeight: 700 }} whiteSpace="nowrap">
                Auditoria - Quilometragem
              </Box>
            </Stack>
            <Stack
              flex={1}
              sx={{
                backgroundColor: c,
                color: "#ffffff",
                justifyContent: "center",
                fontSize: "0.75rem",
                pl: 3,
                borderRadius: "0 7px 7px 0",
              }}
            >
              Indicador X possui valores fora dos habituais
            </Stack>
          </Stack>
        ))}
    </Stack>
  );
}
