import { createBrowserRouter } from "react-router-dom";

import { AuthProvider, RequireAuth } from "../components/auth";
import AuthLayout from "../layouts/auth-layout.tsx";
import MainLayout from "../layouts/main-layout";
import PublicLayout from "../layouts/public-layout";

import { routes as adminRoutes } from "./admin";
import { routes as auditoriaRoutes } from "./auditoria";
import { routes as dadosOperacionaisRoutes } from "./dados-operacionais";
import NotFound from "./errors/404-not-found.tsx";
import PaginaInicialGestor from "./gestor";
import HomeGestor from "./gestor/home-gestor";
import { routes as glossarioRoutes } from "./glossario";
import { routes as indicadoresRoutes } from "./indicadores";
import Login from "./login.tsx";
import { routes as mapasRoutes } from "./mapas";
import { routes as publicRoutes } from "./publico";
import { MobnitRouteObject } from "./types.ts";
import { routes as vistoriaRoutes } from "./vistoria";

export const routes: MobnitRouteObject[] = [
  {
    element: <AuthProvider />,
    children: [
      // área autenticada
      {
        element: <RequireAuth />,
        path: "gestor",
        children: [
          {
            element: <MainLayout />,
            children: [
              { index: true, element: <PaginaInicialGestor /> },
              { path: "home", element: <HomeGestor /> },
              vistoriaRoutes,
              dadosOperacionaisRoutes,
              indicadoresRoutes,
              auditoriaRoutes,
              mapasRoutes,
              glossarioRoutes,
              adminRoutes,
            ],
          },
        ],
      },
      // ações públicas e autenticação
      {
        element: <PublicLayout />,
        children: publicRoutes,
      },
      {
        element: <AuthLayout />,
        children: [
          // tela de login
          { path: "login", element: <Login /> },
        ],
      },
      { path: "*", element: <NotFound /> },
    ],
  },
];

export const router = createBrowserRouter(routes);
