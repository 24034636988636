import { ReactNode, useMemo } from "react";

import { Box, Divider, Stack, Typography } from "@mui/material";

import { sum } from "lodash";
import { useFormContext } from "react-hook-form";

import { useRotasAgrupadas } from "../../../hooks.ts";

import CheckboxPermissao from "./checkbox-permissao.tsx";
import { Inputs } from "./tipos.ts";

export default function Estrategico() {
  const { watch } = useFormContext<Inputs>();

  const [todosGrupos] = useRotasAgrupadas();
  const gruposGestor = todosGrupos.filter(([rotaGrupo]) => rotaGrupo.path !== "admin");

  const qtdItens = useMemo(
    () => sum(gruposGestor.map(([, , filhos]) => filhos.length)) + gruposGestor.length,
    [gruposGestor],
  );

  // só mostra este componente se o perfil for estratégico
  if (watch("perfil") !== "Estratégico") return null;

  return (
    <Stack sx={{ color: "rgba(131, 135, 154, 1)", ["& .MuiFormControlLabel-label"]: { fontSize: "0.875em" } }}>
      <Typography>Gestão de acesso</Typography>
      <Divider />
      <Box alignSelf="center" mt={1.5}>
        <CheckboxPermissao path="/gestor/home" label="Home" />

        <Stack flexWrap="wrap" maxHeight={`calc(2em * ${qtdItens / 2})`} columnGap={2}>
          {gruposGestor.map(([rotaGrupo, path, rotasFilhos]) => (
            <BlocoGrupo key={path} titulo={rotaGrupo.handle?.titulo ?? ""}>
              {rotasFilhos?.map(([rotaFilho, path]) => (
                <CheckboxPermissao key={path} path={path} label={rotaFilho.handle?.titulo ?? ""} sx={{ my: "-5px" }} />
              ))}
            </BlocoGrupo>
          ))}
        </Stack>
      </Box>
    </Stack>
  );
}

const BlocoGrupo = ({ titulo, children }: { titulo: string; children: ReactNode }) => (
  <Stack mt={1} width="12.5em" gap={{ xs: 2, md: 0 }}>
    <Typography fontSize={14} fontWeight={700} sx={{ mx: -0.5 }}>
      {titulo}
    </Typography>
    {children}
  </Stack>
);
