import { Alert, Box, IconButton, Stack } from "@mui/material";

import copiarIcon from "../../../assets/icones/copiar.svg";
import useClipboard from "../hook.ts";

export default function BotaoCopiar({ valor }: { valor: string }) {
  const copyToClipboard = useClipboard();
  const onClick = () => {
    copyToClipboard(valor)
      .then(() => <Alert severity="success" />)
      .catch(reportError);
  };

  return (
    <Stack direction="row" alignItems="center" spacing={1}>
      {valor}
      <Box>
        <IconButton onClick={onClick}>
          <img src={copiarIcon} alt="" />
        </IconButton>
      </Box>
    </Stack>
  );
}
