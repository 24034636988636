import { useCallback, useState } from "react";

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography } from "@mui/material";

import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";

import alertaIcon from "../../../../assets/icones/alert.svg";
import fecharIcon from "../../../../assets/icones/close.svg";
import DeleteIcon from "../../../../assets/icones/delete.tsx";
import { typedRequest } from "../../../../services/api";
import { useAuthAxios } from "../../../../services/fetchers.ts";

interface Props {
  nome: string;
  url: string;
  icone?: boolean;
  id: string;
}

export default function ExcluirEndpoint({ nome, url, icone = false, id }: Props) {
  const [isOpen, setOpen] = useState(false);
  const toggleOpen = useCallback(() => setOpen((o) => !o), []);
  const authAxios = useAuthAxios();

  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const handleExcluir = useCallback(async () => {
    await typedRequest(authAxios, "delete", "/cadastros/apis/{id}", { id });

    navigate("/gestor/admin/apis");
    enqueueSnackbar({ variant: "success", message: "Endpoint excluído com sucesso" });
    setOpen((o) => !o);
  }, [authAxios, enqueueSnackbar, id, navigate]);

  return (
    <>
      {" "}
      <Button
        onClick={toggleOpen}
        startIcon={icone && <DeleteIcon />}
        sx={{ color: "#EB5757", textDecoration: "underline", textWrap: "nowrap" }}
      >
        Excluir endpoint
      </Button>
      <Dialog open={isOpen} sx={{ "& p": { color: "#83879A" }, "& .MuiPaper-root": { maxWidth: "27em" } }}>
        <IconButton sx={{ m: "0.3em 0.3em   0 auto", bgcolor: "#D9D9D9" }} onClick={toggleOpen}>
          <img src={fecharIcon} alt="Fechar" />
        </IconButton>

        <DialogTitle color="#EB5757">
          <img src={alertaIcon} alt="Alerta" style={{ marginRight: "0.5em" }} />
          Excluir endpoint
        </DialogTitle>

        <DialogContent>
          <Typography sx={{ "& span": { fontWeight: 700 }, "mb": 2.5 }}>
            Tem certeza que deseja excluir o endpoint <span>{nome}</span>(<span>{url}</span>)?
          </Typography>
          <Typography>Esta ação ficará registrada no histórico do sistema.</Typography>
        </DialogContent>
        <DialogActions sx={{ pb: 2.5, alignSelf: "center" }}>
          <Button variant="contained" sx={{ bgcolor: "#AEAFB4" }} onClick={toggleOpen}>
            Cancelar
          </Button>
          <Button variant="contained" onClick={handleExcluir} sx={{ bgcolor: "#EB5757" }}>
            Excluir endpoint
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
