import { Suspense } from "react";

import { Stack } from "@mui/material";

import { LayersControl, MapContainer, TileLayer } from "react-leaflet";

import ErrorBoundary from "../../../components/error-boundary.tsx";

import CamadaCalor from "./camada-calor.tsx";
import PaneAguarde from "./pane-aguarde.tsx";
import PaneErro from "./pane-erro.tsx";

export default function MapaDeCalor() {
  return (
    <Stack component={MapContainer} center={[-22.9148, -43.0955]} zoom={13} scrollWheelZoom={false} height="75vh">
      <LayersControl>
        <LayersControl.BaseLayer name="Base" checked>
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
        </LayersControl.BaseLayer>
        <ErrorBoundary fallback={<PaneErro />}>
          <Suspense fallback={<PaneAguarde />}>
            <CamadaCalor label="Passageiros" contador="passageiros" />
            <CamadaCalor label="Frota" contador="veiculos" />
          </Suspense>
        </ErrorBoundary>
      </LayersControl>
    </Stack>
  );
}
