import { Box, Fab } from "@mui/material";

import ChatbotIcon from "../../../assets/icones/chatbot.tsx";

interface Props {
  onClick: () => void;
  open: boolean;
}

export function Botao({ onClick, open }: Props) {
  return (
    <Fab
      variant="circular"
      onClick={onClick}
      sx={{ bgcolor: open ? "white" : "primary.main", color: open ? "primary.main" : "white", alignItems: "center" }}
    >
      <Box>
        <ChatbotIcon sx={{ fontSize: "37px" }} />
      </Box>
    </Fab>
  );
}
