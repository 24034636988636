import { forwardRef, useId } from "react";

import { InputLabel, OutlinedInput, OutlinedInputProps, Stack } from "@mui/material";

interface Props extends Omit<OutlinedInputProps, "id" | "size"> {
  titulo: string;
}

export default forwardRef<HTMLInputElement, Props>(function Bloco({ titulo, ...props }, ref) {
  const id = useId();

  return (
    <Stack minWidth="30em" gap={0.75}>
      <InputLabel htmlFor={id}>{titulo}</InputLabel>
      <OutlinedInput ref={ref} size="small" id={id} {...props} />
    </Stack>
  );
});
