import TableBox from "../../../components/frames/table-box.tsx";
import { useDadosAPIPaginados } from "../../../services/dados.ts";

interface Props {
  titulo?: string;
  height?: string;
}

export default function RioCard({ titulo = "Dados Brutos - RioCard", height }: Props) {
  const tabelaProps = useDadosAPIPaginados({ consulta: "riocard" });

  return (
    <TableBox
      {...tabelaProps}
      height={height}
      titulo={titulo}
      colunas={[
        { id: "DH_TRANSACAO", label: "Data Transação", type: "datetime" },
        { id: "NU_CHIP", label: "N° Chip", type: "string" },
        { id: "CD_APLICACAO", label: "cod Aplicação", type: "inteiro" },
        { id: "CD_EMISSOR", label: "cod Emissor", type: "inteiro" },
        { id: "MODALIDADE", label: "Modalidade", type: "string" },
        { id: "CD_MASTER_LINHA", label: "Master linha", type: "inteiro" },
        { id: "NU_NUMERO_LINHA", label: "linha", type: "string" },
        { id: "COD_EMPRESA", label: "cod Empresa", type: "inteiro" },
        { id: "NO_EMPRESA", label: "Empresa", type: "string" },
        { id: "NU_CARRO", label: "N° carro", type: "inteiro" },
        { id: "NU_TSN", label: "N° TSN", type: "inteiro" },
        { id: "DS_TIPO_LOG_INTEGRACAO", label: "Tipo Integração", type: "string" },
        { id: "DS_TIPO_DEBITO", label: "Tipo débito", type: "string" },
        { id: "NO_PRODUTO", label: "Produto", type: "string" },
        { id: "VL_LINHA", label: "Valor Linha", type: "number" },
        { id: "TP_BENEFICIO", label: "Beneficio", type: "string" },
        { id: "TP_BENEFICIO_DESC", label: "Beneficio Desc", type: "string" },
        { id: "DH_PROC", label: "Processamento", type: "datetime" },
        { id: "TS_INCLUSAO", label: "Inclusão", type: "datetime" },
        { id: "TS_ALTERACAO", label: "Alteração", type: "string" },
        { id: "DS_NUM_CARTAO", label: "N° Cartão", type: "string" },
        { id: "DS_VISTA", label: "Vista", type: "string" },
      ]}
    />
  );
}
