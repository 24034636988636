import { ReactNode } from "react";

import { Box, Stack, SxProps, Typography } from "@mui/material";

import dayjs from "dayjs";
import { FormProvider, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";

import BotaoLink from "../../../components/botao-link.tsx";
import { useSWRFromAPI } from "../../../services/api/swr.ts";
import { fmtDataHoraCurta } from "../../../services/formatacao.ts";

import ExcluirEndpoint from "./acoes/excluir-endpoint.tsx";
import BotaoCopiar from "./botao-copiar.tsx";
import { Editar } from "./editar.tsx";
import Dados from "./formulario/dados.tsx";
import { Inputs } from "./tipos.ts";

export default function Detalhes() {
  const { id = "" } = useParams();
  const { data: endpoint } = useSWRFromAPI("/cadastros/apis/{id}", { id });
  const form = useForm<Inputs>({
    defaultValues: { ...endpoint },
  });
  if (!endpoint) return null;

  return (
    <FormProvider {...form}>
      <Stack gap={3}>
        <Stack width="7em">
          <BotaoLink variant="contained" to="/gestor/admin/apis" color="secondary">
            Voltar
          </BotaoLink>
        </Stack>

        <Stack gap={1} fontWeight={500} border="1px solid rgba(131, 135, 154, 0.19)" borderRadius="18px" p={3}>
          <Stack flexDirection="row" alignItems="center" gap={3}>
            <Typography>Endpoint {endpoint.nome}</Typography>
            <Editar id={id} />
            <ExcluirEndpoint nome={endpoint.nome} url={endpoint.enderecoCurto} id={id} />
          </Stack>

          <Stack
            display="grid"
            gridTemplateColumns="auto auto"
            border="1px solid rgba(131, 135, 154, 0.19)"
            borderRadius="18px"
            gap={3}
            p={3}
          >
            <Bloco titulo="Nome">{endpoint.nome}</Bloco>
            <Bloco titulo="Endereço(url)">
              <BotaoCopiar valor={location.origin + endpoint.enderecoCompleto} />
            </Bloco>
            <Bloco titulo="CORS">{endpoint.cors ?? "-"}</Bloco>
            <Bloco titulo="Chave">
              <BotaoCopiar valor={endpoint.chave} />
            </Bloco>
            <Bloco titulo="Recorte temporal">
              {endpoint.recorteTemporal === "ultimo_ano" ? "Último ano" : "Último mês"}
            </Bloco>
            <Bloco titulo="Data de criação">
              {fmtDataHoraCurta.format(dayjs.utc(endpoint.criacao?.dataUtc).toDate())}
            </Bloco>
            <Bloco titulo="Criado Por">{endpoint.criacao.usuarioEmail}</Bloco>
            <Bloco titulo="Data de atualização">
              {fmtDataHoraCurta.format(dayjs.utc(endpoint.atualizacao?.dataUtc).toDate()) ?? "Não modificado"}
            </Bloco>
            <Bloco titulo="Última edição por">{endpoint.atualizacao?.usuarioEmail ?? "Não modificado"}</Bloco>
            <Bloco titulo="Comentário" sx={{ gridColumn: "span 2" }}>
              {endpoint.comentario}
            </Bloco>
            <Stack gridColumn="span 2">
              <Dados readonly />
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </FormProvider>
  );
}
const Bloco = ({ titulo, children, sx }: { titulo: string; children: ReactNode; sx?: SxProps }) => (
  <Stack sx={sx}>
    <Box fontSize={14} color="rgba(131, 135, 154, 1)" py={0.5}>
      {titulo}
    </Box>
    <Stack fontSize={18} pl={1}>
      {children}
    </Stack>
  </Stack>
);
