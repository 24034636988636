import {
  Bar,
  CartesianGrid,
  ComposedChart,
  Label,
  Legend,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

import ChartBox from "../../../../components/frames/chartbox.tsx";
import TooltipMultiseries from "../../../../components/tooltips/tooltip-multiseries.tsx";
import { useDadosAPIProtegida } from "../../../../services/dados.ts";
import { fmtIndicadorKm } from "../../../../services/formatacao.ts";
import { CustomLegend } from "../../../indicadores/frota/custom-legend.tsx";

import { type ProgramadaIda } from "./tipos.ts";

export default function ProgramadaIda() {
  const { registros, ...chartProps } = useDadosAPIProtegida<ProgramadaIda>({ consulta: "programada-ida" });

  const realizadaColor = "#F5792B";
  const programadaColor = "#683AB7";
  const titulosLegenda = ["Programada", "Realizada"];

  return (
    <ChartBox {...chartProps} registros={registros} titulo="Quilometragem programada **ida**">
      <ResponsiveContainer width="100%" height="100%" style={{ fontSize: "12px" }}>
        <ComposedChart data={registros} margin={{ top: 15, right: 20, bottom: 20, left: 70 }}>
          <CartesianGrid vertical={false} stroke="rgba(131, 135, 154, 0.10)" />
          <YAxis axisLine={false} tickLine={false}>
            <Label angle={-90} position="insideLeft" offset={-30}>
              Quilômetros
            </Label>
          </YAxis>
          <XAxis dataKey="numeroLinha" axisLine={false} tickLine={false} />
          <Tooltip content={<TooltipMultiseries formatador={fmtIndicadorKm} />} />
          <Legend
            content={
              <CustomLegend
                disponivelColor={realizadaColor}
                titulos={titulosLegenda}
                programadaColor={programadaColor}
              />
            }
          />
          <Bar dataKey="kmRealizadaIda" fill={realizadaColor} name={titulosLegenda[1]} />
          <Line dataKey="kmProgramadaIda" stroke={programadaColor} fill={programadaColor} name={titulosLegenda[0]} />
        </ComposedChart>
      </ResponsiveContainer>
    </ChartBox>
  );
}
