import TableBox from "../../../components/frames/table-box.tsx";
import { ObjetoDados } from "../../../components/tabela/types.ts";
import { useDadosAPIPaginados } from "../../../services/dados.ts";

interface DadosVariacaoKm extends ObjetoDados {
  data: string;
  consorcio: string;
  empresas: string[];
  empresa: string;
  linha: string;
  pvIda: number;
  pvVolta: number;
  pvGeral: number;
}

export default function TabelaVariacaoQuilometragem() {
  const tabelaProps = useDadosAPIPaginados<DadosVariacaoKm>({ consulta: "empresas-com-variacao-km" });

  return (
    <TableBox
      {...tabelaProps}
      titulo="Empresas e linhas com **variação de quilometragem**"
      colunas={[
        { id: "data", label: "Data", type: "date" },
        { id: "consorcio", label: "Consórcio", type: "string" },
        { id: "empresas", label: "Empresa", type: "custom", renderer: (r) => r.empresas?.join(" / ") ?? r.empresa },
        { id: "linha", label: "Linha", type: "string" },
        { id: "pvIda", label: "% ida", type: "percentual", width: "7rem" },
        { id: "pvVolta", label: "% volta", type: "percentual", width: "7rem" },
        { id: "pvGeral", label: "% variação", type: "percentual", width: "7rem" },
      ]}
    />
  );
}
