import { AppBar, Box, CardMedia, Stack, Toolbar } from "@mui/material";

import { useAuth } from "react-oidc-context";
import { Link } from "react-router-dom";

import logoMobNit from "../../assets/logo-mobnit.png";
import { useAuthContext } from "../../services/auth.ts";
import { useIsTabletOrSmaller } from "../../services/responsividade.ts";
import { DivisorVertical, Hoje, Ola } from "../shared";
import BackdropMobile from "../shared/backdrop-mobile";
import MenuMobilidade from "../shared/menu-mobilidade.tsx";
import MenuPrefeitura from "../shared/menu-prefeitura.tsx";
import { StyledMenuButton } from "../shared/styled-popup-menu.tsx";

export default function MainTopBar() {
  const auth = useAuth();
  const { signOut } = useAuthContext();
  const isTablet = useIsTabletOrSmaller();

  return (
    <AppBar sx={{ bgcolor: "background.default", color: "secondary.main" }}>
      <Toolbar sx={{ gap: 2, py: { xs: 1.5, md: 0 }, pr: { xs: 0, md: 3.5 }, pl: { xs: 1.5, md: 3.5 } }}>
        <Box mr="auto" component={Link} to="/">
          <CardMedia component="img" height={40} image={logoMobNit} />
        </Box>
        {isTablet ? (
          <BackdropMobile />
        ) : (
          <>
            <Ola />

            <DivisorVertical />

            <Hoje />

            <DivisorVertical />

            <Stack direction="row">
              <StyledMenuButton to="/">Home</StyledMenuButton>

              <StyledMenuButton to="/gestor">Área do Gestor</StyledMenuButton>

              <MenuMobilidade />

              <MenuPrefeitura />

              {auth.activeNavigator === "signoutRedirect" ? (
                <StyledMenuButton disabled>Deslogando...</StyledMenuButton>
              ) : (
                <StyledMenuButton onClick={signOut}>Logout</StyledMenuButton>
              )}
            </Stack>
          </>
        )}
      </Toolbar>
    </AppBar>
  );
}
