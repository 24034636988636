import { useEffect, useMemo } from "react";

import { useAtom } from "jotai";
import { useLocation } from "react-router-dom";

import { LinhaFiltro } from "./tipos.ts";
import { filtrosAtom, TODOS } from "./valores.ts";

export function useDadosFiltro(d: LinhaFiltro) {
  const { pathname } = useLocation();
  const [filtros, setFiltros] = useAtom(filtrosAtom(pathname));

  const values = d.valores ?? (d.padrao ? [d.padrao] : []);

  // define o valor padrão de um novo filtro
  useEffect(() => {
    setFiltros((f) => {
      const atual = f[d.variavel];
      if (atual) return f;

      // HACK: aplica o valor padrão vindo dos metadados apenas no caso do campo ser monovalorado
      const vp = !d.padrao || d.multivalorado ? TODOS : [d.padrao];

      return { ...f, [d.variavel]: vp };
    });
  }, [d.multivalorado, d.padrao, d.variavel, setFiltros]);

  const value = useMemo(() => filtros[d.variavel] ?? TODOS, [d.variavel, filtros]);

  return { setFiltros, values, value, multi: d.multivalorado };
}
