import { Paper, Stack, Typography } from "@mui/material";

import type { Payload } from "recharts/types/component/DefaultTooltipContent";

import { fmtDecimal } from "../../services/formatacao.ts";

interface Props {
  active?: boolean;
  payload?: Payload<number, string>[];
  label?: string | number;
}

export default function TooltipIntegracoesLinha({ active, payload, label }: Props) {
  if (!active || !payload?.length) return null;

  const [{ color, value }] = payload;

  const valorFormatado = fmtDecimal.format(+(value ?? 0));

  return (
    <Stack
      component={Paper}
      borderRadius="7px"
      border="1px solid rgba(131, 135, 154, 0.10)"
      sx={{ color: "#83879A", p: 1, px: 2 }}
    >
      <Typography fontWeight={700} fontSize="10px">
        Linha: {label}
      </Typography>
      <Typography color={color}>{valorFormatado} integrações</Typography>
    </Stack>
  );
}
