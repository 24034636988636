import { CssBaseline, ThemeProvider } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import { SnackbarProvider } from "notistack";
import { RouterProvider } from "react-router-dom";

import { router } from "./routes";
import { theme } from "./services/theme.ts";

import "./services/datas.ts";
import "./app.css";

export default function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pt-br">
        <SnackbarProvider anchorOrigin={{ horizontal: "right", vertical: "top" }} autoHideDuration={15000}>
          <RouterProvider router={router} />
        </SnackbarProvider>
      </LocalizationProvider>
    </ThemeProvider>
  );
}
