import { lazy, Suspense, useCallback, useState } from "react";

import { Stack } from "@mui/material";

import Aguarde from "../../../components/aguarde.tsx";
import ErrorBoundary from "../../../components/error-boundary.tsx";

import { Botao } from "./botao.tsx";

const Chatbot = lazy(() => import("./chatbot.tsx"));

export default function BotaoChatBot() {
  const [open, setOpen] = useState(false);

  const toggle = useCallback(() => setOpen(!open), [open]);

  return (
    <Stack sx={{ ["&:hover .MuiFab-root"]: { bgcolor: open ? "#fafafa" : "#e35d05" } }}>
      <Botao onClick={toggle} open={open} />
      <ErrorBoundary>
        <Suspense fallback={<Aguarde />}>{open && <Chatbot onClose={toggle} />}</Suspense>
      </ErrorBoundary>
    </Stack>
  );
}
