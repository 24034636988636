import { ArrowDownward, ArrowForward, ArrowUpward } from "@mui/icons-material";
import { Box, Stack, Typography } from "@mui/material";

import Markdown from "react-markdown";

import TooltipIndicador from "../../../components/indicador-com-descricao/tooltip-indicador.tsx";
import { useConteudo } from "../../../services/conteudo";
import { useIndicador } from "../../../services/dados.ts";
import { fmtIndicadorMuitoBaixo, fmtNumeroInteiro } from "../../../services/formatacao.ts";

export default function IndiceReclamacoesMobilidade() {
  const conteudo = useConteudo();
  const { valor, valorAnterior } = useIndicador({ consulta: "irc" });

  const valorVariacao = (valor ?? 0) / (valorAnterior ?? 1);
  const upDownIcon = valorVariacao > 0 ? <ArrowUpward /> : valorVariacao < 0 ? <ArrowDownward /> : <ArrowForward />;

  const variacaoValorPercentual = fmtNumeroInteiro.format(100 * valorVariacao);

  return (
    <Box
      border="1px solid rgba(131, 135, 154, 0.19)"
      borderRadius="7px"
      fontSize="14px"
      gridRow="span 2"
      flex={1}
      display="grid"
      gridTemplateRows="5px 1fr 5px"
      sx={{
        background: "linear-gradient(to top, #FBFCFF 10px, #83879A30 10px 11px, #FFFFFF 11px 100%)",
        gridColumn: { xs: "span 2", lg: "span 1", xl: "span 2" },
      }}
    >
      <Box padding={1}>
        <Markdown>{conteudo.texto.indicadores.reclamacoes.irm.titulo}</Markdown>

        <TooltipIndicador
          title={conteudo.texto.indicadores.reclamacoes.irm.tooltip}
          iconProps={{ sx: { top: "0.21em" } }}
        />
      </Box>

      <Stack
        flexDirection="row"
        alignItems="center"
        justifyContent="center"
        alignSelf="center"
        fontSize="20px"
        fontWeight={700}
        paddingY={1.5}
        flex={1}
      >
        <Typography fontSize="60px" fontWeight={700} color="#F5792B">
          {fmtIndicadorMuitoBaixo.format(valor ?? 0)}
        </Typography>

        <Typography display="flex" whiteSpace="nowrap" color="#FFA800">
          {upDownIcon} {variacaoValorPercentual} %
        </Typography>
      </Stack>
    </Box>
  );
}
