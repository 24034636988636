import { SvgIcon } from "@mui/material";

export default function Subsidios() {
  return (
    <SvgIcon>
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M17.0083 4.9085L13.675 3.4085H13.6333H13.5333H13.4333H13.225H13.1417H13.0333H12.9917L10 4.75016L7.00833 3.4085H6.96666L6.86666 3.3335H6.76666H6.55833H6.475H6.36666L3.03333 4.8335C2.86707 4.8972 2.72559 5.01248 2.62961 5.16245C2.53363 5.31241 2.48821 5.48917 2.5 5.66683V15.8335C2.50023 15.9729 2.53544 16.1101 2.60242 16.2324C2.66939 16.3547 2.76598 16.4582 2.88333 16.5335C3.01746 16.6201 3.17365 16.6664 3.33333 16.6668C3.45124 16.6663 3.5677 16.6407 3.675 16.5918L6.66666 15.2502L9.65833 16.5918H9.7C9.79391 16.6357 9.89632 16.6585 10 16.6585C10.1037 16.6585 10.2061 16.6357 10.3 16.5918H10.3417L13.3333 15.2502L16.325 16.5918C16.4323 16.6407 16.5488 16.6663 16.6667 16.6668C16.8263 16.6664 16.9825 16.6201 17.1167 16.5335C17.234 16.4582 17.3306 16.3547 17.3976 16.2324C17.4646 16.1101 17.4998 15.9729 17.5 15.8335V5.66683C17.4997 5.50653 17.4531 5.34973 17.3659 5.21522C17.2787 5.08072 17.1545 4.97422 17.0083 4.9085ZM4.16666 6.20016L5.83333 5.4585V13.7918L4.16666 14.5335V6.20016ZM7.5 5.4585L9.16666 6.20016V14.5335L7.5 13.7918V5.4585ZM10.8333 6.20016L12.5 5.4585V13.7918L10.8333 14.5335V6.20016ZM15.8333 14.5335L14.1667 13.7918V5.4585L15.8333 6.20016V14.5335Z"
          fill="currentColor"
        />
      </svg>
    </SvgIcon>
  );
}
