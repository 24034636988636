import { Stack, Tooltip, Typography } from "@mui/material";

import { fmtIndicador } from "../../services/formatacao.ts";

import Variacao from "./variacao.tsx";

interface Props {
  numberFormat?: Intl.NumberFormat;
  valor: number;
  valorAnterior: number | undefined;
  cor: string;
  corVariacao: string;
}

export default function BlocoIndicador({ numberFormat = fmtIndicador, valor, valorAnterior, cor, corVariacao }: Props) {
  const valorFormatado = numberFormat.format(valor);

  // diminui o tamanho da fonte em 10% para cada caractere além do nono
  const fontSize = 60 * (1 - Math.max(0, valorFormatado.length - 9) / 10);

  return (
    <Stack
      flexDirection="row"
      alignItems="center"
      justifyContent="center"
      alignSelf="center"
      fontSize="20px"
      fontWeight={700}
      paddingY={1.5}
      paddingX={2}
      flex={1}
      gap={1}
    >
      <Typography className="indicador" noWrap fontSize={fontSize} fontWeight={700} color={cor}>
        {valorFormatado}
      </Typography>

      <Tooltip
        title={
          <>
            Valor no período anterior: <b>{valorAnterior}</b>
          </>
        }
      >
        <Typography component="div" display="flex" whiteSpace="nowrap" color={corVariacao}>
          <Variacao valor={valor} valorAnterior={valorAnterior} />
        </Typography>
      </Tooltip>
    </Stack>
  );
}
