import TableBox from "../../../components/frames/table-box.tsx";
import { useDadosAPIPaginados } from "../../../services/dados.ts";

interface Props {
  titulo?: string;
  height?: string;
}

export default function Prodata({ titulo = "Dados Brutos do Validador - ProData", height }: Props) {
  const tabelaProps = useDadosAPIPaginados({ consulta: "prodata" });

  return (
    <TableBox
      {...tabelaProps}
      height={height}
      titulo={titulo}
      colunas={[
        { id: "DataTransacao", label: "Data da transação", type: "datetime" },
        { id: "ChipCartao", label: "Chip cartão", type: "inteiro", width: "7rem" },
        { id: "Aplicacao", label: "Aplicação", type: "inteiro", width: "5rem" },
        { id: "EmissorAplicacao", label: "Emissor", type: "inteiro", width: "4rem" },
        { id: "Modalidade", label: "Modalidade", type: "string" },
        { id: "TipoAplicacao", label: "Tipo Aplicação", type: "string" },
        { id: "Empresa", label: "Empresa", type: "string" },
        { id: "Integracao", label: "Integração", type: "string" },
        { id: "Linha", label: "Linha", type: "string" },
        { id: "LinhaDetalhe", label: "Detalhe Linha", type: "inteiro" },
        { id: "MasterLinha", label: "Master Linha", type: "inteiro" },
        { id: "NumeroCarro", label: "N° carro", type: "string" },
        { id: "NumeroEmpresa", label: "N° Empresa", type: "inteiro" },
        { id: "NumeroTransacao", label: "N° Transação", type: "inteiro" },
        { id: "TipoDebito", label: "Tipo débito", type: "string" },
        { id: "ValorDebitado", label: "Valor debitado", type: "number" },
        { id: "Vista", label: "Vista", type: "string" },
        { id: "TipoDia", label: "Tipo Dia", type: "string" },
        { id: "TurnoDia", label: "Turno Dia", type: "string" },
      ]}
    />
  );
}
