import { Paper, Stack, Typography } from "@mui/material";

import dayjs from "dayjs";
import type { Payload } from "recharts/types/component/DefaultTooltipContent";

import { fmtDecimal } from "../../services/formatacao.ts";

interface Props {
  active?: boolean;
  payload?: Payload<number, string>[];
  label?: string | number;
}

export default function TooltipOnibusCirculacao({ active, payload, label }: Props) {
  if (!active || !payload?.length) return null;

  const [{ color, value }] = payload;
  const data = dayjs.utc(label).format("DD/MM/YYYY");
  const valorFormatado = fmtDecimal.format(+(value ?? 0));

  return (
    <Stack
      component={Paper}
      borderRadius="7px"
      border="1px solid rgba(131, 135, 154, 0.10)"
      sx={{ bgcolor: "#ffffff", color: "#83879A", p: 1, px: 2 }}
    >
      <Typography fontWeight={700} fontSize={10}>
        {data}
      </Typography>
      <Typography color={color}>{valorFormatado} veículos</Typography>
    </Stack>
  );
}
