import { CartesianGrid, Label, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";

import ChartBox from "../../../components/frames/chartbox.tsx";
import TooltipPicoFrota from "../../../components/tooltips/tooltip-pico-frota.tsx";
import { useDadosAPIProtegida } from "../../../services/dados.ts";

import { HoraMedia } from "./tipos.ts";

export default function HorarioPicoFrota() {
  const { registros, ...chartProps } = useDadosAPIProtegida<HoraMedia>({ consulta: "horario-pico-frota" });

  const realizadaColor = "#574786";

  return (
    <ChartBox {...chartProps} registros={registros} titulo="Horário de **pico de frota**">
      <ResponsiveContainer width="100%" height="100%" style={{ fontSize: "12px" }}>
        <LineChart data={registros} width={500} height={400} margin={{ top: 15, right: 25, bottom: 40, left: 70 }}>
          <CartesianGrid vertical={false} stroke="rgba(131, 135, 154, 0.10)" />
          <XAxis
            dataKey="hora"
            axisLine={false}
            tickLine={false}
            angle={-45}
            tick={{ textAnchor: "end" }}
            tickFormatter={(v) => `${v}:00`}
          >
            <Label position="bottom" offset={20}>
              Horário
            </Label>
          </XAxis>
          <Tooltip content={TooltipPicoFrota} />
          <YAxis axisLine={false} tickLine={false}>
            <Label angle={-90} position="insideLeft" offset={-30}>
              Frota
            </Label>
          </YAxis>

          <Line dataKey="media" fill={realizadaColor} stroke={realizadaColor} strokeWidth="2px" />
        </LineChart>
      </ResponsiveContainer>
    </ChartBox>
  );
}
