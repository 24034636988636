import IndicadorComDescricao from "../../../../components/indicador-com-descricao";
import { useConteudo } from "../../../../services/conteudo";
import { useIndicador } from "../../../../services/dados.ts";
import { fmtIndicadorKm } from "../../../../services/formatacao.ts";

export default function PercursoMedioMensal() {
  const conteudo = useConteudo();

  const indicadorProps = useIndicador({ consulta: "pmm" });

  return (
    <IndicadorComDescricao
      {...indicadorProps}
      numberFormat={fmtIndicadorKm}
      descricao={conteudo.texto.auditoria.quilometragem.pmm.tooltip}
      cor="#1B335F"
      corVariacao="#FFA800"
      titulo={conteudo.texto.auditoria.quilometragem.pmm.titulo}
    />
  );
}
