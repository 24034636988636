import { ReactNode } from "react";

import { Paper, Stack, Typography } from "@mui/material";

import type { Payload } from "recharts/types/component/DefaultTooltipContent";

import { fmtDataCurtaUTC, fmtDecimal } from "../../services/formatacao.ts";

interface Props {
  active?: boolean;
  payload?: Payload<number, "data">[];
  label?: string | number;
  unit?: ReactNode;
}

export default function TooltipContagemPorDia({ active, payload, label, unit }: Props) {
  if (!active || !payload?.length) return null;

  const [
    {
      color,
      value,
      payload: { tipoDia },
    },
  ] = payload;

  const dataFormatada = fmtDataCurtaUTC.format(+(label ?? 0));
  const tipoDiaFormatado = !tipoDia ? "" : tipoDia === "Útil" ? "- Dia Útil" : `- ${tipoDia}`;
  const valorFormatado = fmtDecimal.format(+(value ?? 0));

  return (
    <Stack
      component={Paper}
      borderRadius="7px"
      border="1px solid rgba(131, 135, 154, 0.10)"
      sx={{ color: "#83879A", p: 1, px: 2 }}
    >
      <Typography fontWeight={700} fontSize="10px">
        {dataFormatada} {tipoDiaFormatado}
      </Typography>
      <Typography color={color}>
        {valorFormatado} {unit}
      </Typography>
    </Stack>
  );
}
