import { ReactNode, Suspense } from "react";

import { Box, Paper, PaperProps, Stack } from "@mui/material";

import { useMatches } from "react-router-dom";

import Aguarde from "../components/aguarde.tsx";
import ErrorBoundary from "../components/error-boundary.tsx";
import Titulos from "../components/titulos.tsx";
import { getIfStr } from "../services/util.ts";

interface Props extends PaperProps {
  filtros?: ReactNode;
  filtroData?: ReactNode;
}

export default function PageLayout({ filtros, filtroData, children, ...props }: Props) {
  const matches = useMatches();
  const [titulo, subtitulo] = matches.map((m) => getIfStr(m.handle, "titulo")).filter(Boolean);

  return (
    <Box display="grid" flex={1} gap={1} gridTemplateColumns="repeat(2, auto)" gridAutoRows="min-content">
      <Titulos titulo={titulo} subtitulo={subtitulo} />
      {filtroData && (
        <Stack flexDirection="row" justifyContent="flex-end" gap={1}>
          {filtroData}
        </Stack>
      )}

      <ErrorBoundary>
        <Suspense fallback={<Aguarde />}>
          {filtros && <Stack sx={filtroData ? { gridColumn: "1/-1" } : { justifyContent: "end" }}>{filtros}</Stack>}
          <Paper
            {...props}
            sx={{ width: "100%", p: { xs: 0, sm: 4 }, minHeight: "60vh", gridColumn: "1/-1", ...props.sx }}
          >
            {children}
          </Paper>
        </Suspense>
      </ErrorBoundary>
    </Box>
  );
}
