import { Divider, Typography } from "@mui/material";

import { useAuthContext } from "../../services/auth.ts";

export { default as Hoje } from "./hoje.tsx";

export function Ola() {
  const { currentUser } = useAuthContext();

  if (!currentUser) return null;

  return (
    <Typography>
      Olá, <span style={{ fontWeight: 700 }}>{currentUser?.profile.email}</span>
    </Typography>
  );
}

export const DivisorVertical = () => <Divider sx={{ border: 1, color: "#D9D9D9", my: 2 }} flexItem />;
