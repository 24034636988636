import { ReactNode } from "react";

import { Stack, StackProps, Typography } from "@mui/material";

import { Link, To } from "react-router-dom";

interface Props extends StackProps {
  to: To;
  icone: ReactNode;
}

export default function BotaoHome({ to, icone, children, ...props }: Props) {
  return (
    <Stack
      component={Link}
      to={to}
      {...props}
      sx={{
        textDecoration: "none",
        gridRow: "span 13",
        p: "1.5rem",
        px: "2rem",
        alignItems: "center",
        width: "12rem",
        borderRadius: "7px",
        alignSelf: "center",
        ...props.sx,
      }}
    >
      {icone}
      <Typography textAlign="center" color="#ffffff" fontSize="1.125rem" fontWeight={700}>
        {children}
      </Typography>
    </Stack>
  );
}
