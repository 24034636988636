import { get, isString } from "lodash";

export const getIfStr = (v: unknown, path: string): string | undefined => {
  const maybeStr: unknown = get(v, path);
  if (isString(maybeStr)) return maybeStr;
};

export function* cycle<T>(array: T[]): Generator<T, never> {
  while (true) for (const v of array) yield v;
}
