import { useMemo } from "react";

import { compact } from "lodash";
import { useMatches } from "react-router-dom";

import { useAuthContext } from "../services/auth.ts";

import { routes } from "./index.tsx";
import { MobnitRouteObject, MobnitUIMatch } from "./types.ts";

export function useMobnitMatches() {
  return useMatches() as MobnitUIMatch[];
}

export function useRotasAgrupadas() {
  const { possuiPermissao, isAdmin, isEditor } = useAuthContext();

  return useMemo(() => {
    function* filhos(
      el: MobnitRouteObject | MobnitRouteObject[] | undefined,
      paths: string[] = [],
    ): Generator<[MobnitRouteObject, string]> {
      if (!el) return;
      if (Array.isArray(el)) for (const child of el) yield* filhos(child, paths);
      else {
        const newPaths = compact([...paths, el.path]);

        if (el.handle?.titulo) {
          const path = "/" + newPaths.join("/");
          if (isAdmin || isEditor || possuiPermissao(path)) yield [el, path];
        }
        if (el.children) yield* filhos(el.children, newPaths);
      }
    }

    function* grupos(
      el: MobnitRouteObject | MobnitRouteObject[],
      paths: string[] = [],
    ): Generator<[MobnitRouteObject, string, [MobnitRouteObject, string][]]> {
      if (Array.isArray(el)) for (const child of el) yield* grupos(child, paths);
      else if (el.children) {
        const newPaths = compact([...paths, el.path]);
        if (el.handle?.titulo) {
          const path = "/" + newPaths.join("/");
          if (isAdmin || isEditor || possuiPermissao(path)) yield [el, path, [...filhos(el.children, newPaths)]];
        } else yield* grupos(el.children, newPaths);
      }
    }

    function* individuais(
      el: MobnitRouteObject | MobnitRouteObject[],
      paths: string[] = [],
    ): Generator<[MobnitRouteObject, string]> {
      if (Array.isArray(el)) for (const child of el) yield* individuais(child, paths);
      else {
        const newPaths = compact([...paths, el.path]);
        if (el.handle?.titulo) {
          if (!el.children && (newPaths[0] !== "gestor" || isAdmin || isEditor || possuiPermissao("acesso")))
            yield [el, "/" + newPaths.join("/")];
        } else if (el.children) yield* individuais(el.children, newPaths);
      }
    }

    return [[...grupos(routes)], [...individuais(routes)]] as const;
  }, [isAdmin, isEditor, possuiPermissao]);
}
