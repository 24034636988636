import { Alert, Typography } from "@mui/material";

export default function SemDados() {
  return (
    <Alert color="warning">
      <Typography variant="subtitle2">Erro</Typography>
      Não foram retornados dados para esta seleção de filtros.
    </Alert>
  );
}
