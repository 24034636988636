import { lazy, Suspense } from "react";

import { Skeleton } from "@mui/material";

import ErrorBoundary from "../../../../components/error-boundary.tsx";

const CampoBusca = lazy(() => import("./campo-busca"));

export default function Busca() {
  return (
    <ErrorBoundary>
      <Suspense fallback={<Skeleton />}>
        <CampoBusca />
      </Suspense>
    </ErrorBoundary>
  );
}
