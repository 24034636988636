import { Box, TableCell, TableSortLabel } from "@mui/material";
import { visuallyHidden } from "@mui/utils";

import { isProxyPaginacao } from "../../services/dados.ts";

import LinhaTabela from "./linha-tabela.tsx";
import { ObjetoDados, useDataRowsContext } from "./types.ts";

export default function CabecalhoTabela<T extends ObjetoDados>() {
  const { onSort, linhas, colunas, order, orderBy } = useDataRowsContext<T>();

  return (
    <LinhaTabela sx={{ backgroundColor: "#ffffff", border: 1 }}>
      {colunas.map((c, i) => (
        <TableCell
          key={i}
          sortDirection={orderBy === c.id ? order : false}
          sx={{
            py: 1.2,
            color: "#83879A",
            fontWeight: 700,
            ["& .MuiTableSortLabel-root.Mui-active"]: { color: "#83879A" },
          }}
          style={{ width: c.width }}
        >
          {!c.sortable || isProxyPaginacao(linhas) ? (
            <>{c.label}</>
          ) : (
            <TableSortLabel
              active={orderBy === c.id}
              direction={orderBy === c.id ? order : "asc"}
              onClick={onSort}
              data-attr={c.id}
            >
              {c.label}
              {orderBy === c.id && (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "em ordem decrescente" : "em ordem crescente"}
                </Box>
              )}
            </TableSortLabel>
          )}
        </TableCell>
      ))}
    </LinhaTabela>
  );
}
