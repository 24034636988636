import { MouseEvent, useState } from "react";

import { ArrowDropUp } from "@mui/icons-material";
import { Collapse, ListItemButton, Stack } from "@mui/material";
import { ArrowDropDownIcon } from "@mui/x-date-pickers";

import { StyledMenuItem } from "../styled-popup-menu.tsx";

export default function MenuMobilidade() {
  const [open, setOpen] = useState(false);
  const handleClick = (e: MouseEvent) => {
    e.stopPropagation();
    setOpen(!open);
  };

  return (
    <Stack ml="auto">
      <ListItemButton onClick={handleClick} sx={{ color: "primary.main", px: 1, alignSelf: "end" }}>
        Mobilidade {open ? <ArrowDropUp /> : <ArrowDropDownIcon />}
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <Stack alignItems="flex-end">
          <StyledMenuItem to="/horarios">Horários e pontualidade</StyledMenuItem>
          <StyledMenuItem to="/itinerarios">Itinerários, rotas e tarifas</StyledMenuItem>
        </Stack>
      </Collapse>
    </Stack>
  );
}
