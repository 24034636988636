import { Button, InputLabel, Paper, Select, Stack } from "@mui/material";

import { useFormContext } from "react-hook-form";

import Bloco from "../../gestao-usuarios/formulario/bloco.tsx";
import { Inputs } from "../tipos.ts";

import Dados from "./dados.tsx";

interface Props {
  submit: (values: Inputs) => Promise<void>;
}

export default function Formulario({ submit }: Props) {
  const { handleSubmit, register } = useFormContext<Inputs>();

  return (
    <Stack component="form" onSubmit={handleSubmit(submit)}>
      <Stack component={Paper} border="1px solid rgba(131, 135, 154, 0.19)" gap={2} p={2}>
        <Bloco titulo="Nome" {...register("nome")} />
        <Bloco titulo="CORS(opcional)" {...register("cors")} />

        <Stack>
          <InputLabel>Recorte temporal</InputLabel>
          <Select native size="small" sx={{ mt: 0.8 }} inputProps={register("recorteTemporal")}>
            <option />
            <option value="ultimo_mes">Último mês</option>
            <option value="ultimo_ano">Último ano</option>
          </Select>
        </Stack>

        <Bloco titulo="Comentário(opcional)" multiline minRows={6} {...register("comentario")} />
        <Dados />
        <Stack alignSelf="center" width="7em">
          <Button variant="contained" color="primary" type="submit">
            Salvar
          </Button>
        </Stack>
      </Stack>
    </Stack>
  );
}
