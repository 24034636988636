import { useCallback } from "react";

import { isAxiosError } from "axios";
import { Set } from "immutable";
import { useSnackbar } from "notistack";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import { typedRequest } from "../../../../services/api";
import { useAuthAxios } from "../../../../services/fetchers.ts";
import { Inputs } from "../formulario/tipos.ts";

import Formulario from "./formulario.tsx";

export default function NovoUsuario() {
  const form = useForm<Inputs>({ defaultValues: { permissoes: Set<string>() } });

  const authAxios = useAuthAxios();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const submit = useCallback(
    async (dados: Inputs) => {
      const payload = { ...dados, permissoes: dados.permissoes.toArray() };
      try {
        await typedRequest(authAxios, "post", "/cadastros/usuario", undefined, {
          data: payload,
          params: { redirect_uri: `${location.origin}/gestor`, client_id: import.meta.env.VITE_OAUTH_CLIENT_ID },
        });

        enqueueSnackbar({
          variant: "success",
          message: (
            <>
              Usuário criado!
              <br />
              Ele irá receber um e-mail com instruções para completar sua conta e fazer o primeiro acesso.
            </>
          ),
        });
        navigate("..");
      } catch (e) {
        if (isAxiosError(e) && e.response?.status === 409)
          enqueueSnackbar({
            variant: "warning",
            message: "Não é possível criar usuário com este e-mail: já está cadastrado.",
          });
        else {
          reportError(e);
          enqueueSnackbar({
            variant: "error",
            message: (
              <>
                Ocorreu um erro imprevisto ao tentar cadastrar o usuário.
                <br />
                Tente novamente mais tarde, ou entre em contato com o suporte.
              </>
            ),
          });
        }
      }
    },
    [authAxios, enqueueSnackbar, navigate],
  );

  return (
    <FormProvider {...form}>
      <Formulario submit={submit} />
    </FormProvider>
  );
}
