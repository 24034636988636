import { HelpOutline } from "@mui/icons-material";

import { MobnitRouteObject } from "../types.ts";

import Glossario from "./glossario.tsx";

export const routes: MobnitRouteObject = {
  path: "glossario",
  element: <Glossario />,
  handle: { titulo: "Glossário", icone: <HelpOutline /> },
};
