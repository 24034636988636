import { SvgIcon } from "@mui/material";

export const IconeItinerarios = () => (
  <SvgIcon fontSize="large">
    <svg xmlns="http://www.w3.org/2000/svg" width="72" height="72" viewBox="0 0 72 72" fill="none">
      <path
        d="M61.2288 17.669L49.2288 12.269H49.0788H48.7188H48.3588H47.6088H47.3088H46.9188H46.7688L35.9988 17.099L25.2288 12.269H25.0788L24.7188 11.999H24.3588H23.6088H23.3088H22.9188L10.9188 17.399C10.3202 17.6284 9.81089 18.0434 9.46538 18.5833C9.11986 19.1231 8.95634 19.7595 8.99877 20.399V56.999C8.99961 57.501 9.12638 57.9947 9.36748 58.435C9.60858 58.8752 9.95629 59.248 10.3788 59.519C10.8616 59.8309 11.4239 59.9975 11.9988 59.999C12.4233 59.9971 12.8425 59.905 13.2288 59.729L23.9988 54.899L34.7688 59.729H34.9188C35.2569 59.8871 35.6255 59.969 35.9988 59.969C36.372 59.969 36.7407 59.8871 37.0788 59.729H37.2288L47.9988 54.899L58.7688 59.729C59.1551 59.905 59.5743 59.9971 59.9988 59.999C60.5736 59.9975 61.1359 59.8309 61.6188 59.519C62.0413 59.248 62.389 58.8752 62.6301 58.435C62.8712 57.9947 62.9979 57.501 62.9988 56.999V20.399C62.9976 19.8219 62.8299 19.2575 62.516 18.7732C62.2021 18.289 61.7551 17.9056 61.2288 17.669ZM14.9988 22.319L20.9988 19.649V49.649L14.9988 52.319V22.319ZM26.9988 19.649L32.9988 22.319V52.319L26.9988 49.649V19.649ZM38.9988 22.319L44.9988 19.649V49.649L38.9988 52.319V22.319ZM56.9988 52.319L50.9988 49.649V19.649L56.9988 22.319V52.319Z"
        fill="white"
      />
    </svg>
  </SvgIcon>
);

export const IconeHorarios = () => (
  <SvgIcon fontSize="large">
    <svg xmlns="http://www.w3.org/2000/svg" width="65" height="65" viewBox="0 0 65 65" fill="none">
      <path
        d="M32.4994 5.41699C27.1428 5.41699 21.9065 7.0054 17.4527 9.98136C12.9988 12.9573 9.5275 17.1872 7.47762 22.136C5.42775 27.0848 4.89141 32.5304 5.93643 37.784C6.98144 43.0377 9.56088 47.8635 13.3486 51.6511C17.1362 55.4388 21.962 58.0182 27.2157 59.0633C32.4693 60.1083 37.9149 59.5719 42.8637 57.5221C47.8125 55.4722 52.0424 52.0009 55.0183 47.547C57.9943 43.0932 59.5827 37.8569 59.5827 32.5003C59.5827 28.9437 58.8822 25.4219 57.5211 22.136C56.16 18.8501 54.1651 15.8644 51.6502 13.3495C49.1352 10.8346 46.1496 8.83965 42.8637 7.47859C39.5778 6.11752 36.056 5.41699 32.4994 5.41699ZM32.4994 54.167C28.2141 54.167 24.0251 52.8963 20.462 50.5155C16.8989 48.1347 14.1219 44.7509 12.482 40.7918C10.8421 36.8327 10.413 32.4763 11.249 28.2734C12.085 24.0704 14.1486 20.2098 17.1787 17.1797C20.2089 14.1495 24.0695 12.086 28.2724 11.25C32.4753 10.414 36.8318 10.843 40.7908 12.4829C44.7499 14.1228 48.1338 16.8999 50.5145 20.463C52.8953 24.026 54.166 28.2151 54.166 32.5003C54.166 38.2467 51.8833 43.7577 47.82 47.821C43.7567 51.8843 38.2457 54.167 32.4994 54.167Z"
        fill="white"
      />
      <path
        d="M43.3327 29.7923H35.2077V21.6673C35.2077 20.949 34.9223 20.2601 34.4144 19.7522C33.9065 19.2443 33.2176 18.959 32.4993 18.959C31.7811 18.959 31.0922 19.2443 30.5843 19.7522C30.0764 20.2601 29.791 20.949 29.791 21.6673V32.5007C29.791 33.2189 30.0764 33.9078 30.5843 34.4157C31.0922 34.9236 31.7811 35.209 32.4993 35.209H43.3327C44.051 35.209 44.7399 34.9236 45.2478 34.4157C45.7557 33.9078 46.041 33.2189 46.041 32.5007C46.041 31.7824 45.7557 31.0935 45.2478 30.5856C44.7399 30.0777 44.051 29.7923 43.3327 29.7923Z"
        fill="white"
      />
    </svg>
  </SvgIcon>
);
