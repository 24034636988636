import { Button, ButtonProps, lighten } from "@mui/material";

import { NavLink, To } from "react-router-dom";

interface Props extends ButtonProps {
  to?: To;
}

export default function MenuButton({ sx, to, ...props }: Props) {
  return (
    <Button
      {...props}
      {...(to && { component: NavLink, to })}
      sx={{
        whiteSpace: "nowrap",
        fontSize: 14,
        textTransform: "none",
        color: "secondary.main",
        justifyContent: "start",
        gap: 0.25,
        px: 4,
        mx: -4,
        borderRadius: 0,

        ["&.active"]: {
          bgcolor: "primary.light",
          color: "secondary.contrastText",
        },

        ["&:hover"]: {
          bgcolor: (t) => lighten(t.palette.primary.light, 0.15),
          color: "secondary.contrastText",
        },

        ...sx,
      }}
    />
  );
}
