import { useEffect } from "react";

import { Stack, Typography } from "@mui/material";

import Aguarde from "../../components/aguarde.tsx";

export default function RedirecionaVistoria() {
  useEffect(() => {
    setTimeout(() => (location.href = import.meta.env.VITE_COLETA_URL), 3000);
  }, []);

  return (
    <Stack alignItems="center" height="100%" justifyContent="center">
      <Typography>Você será redirecionado para o formulário</Typography> <Aguarde mensagem="Redirecionando" />
    </Stack>
  );
}
