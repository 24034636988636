import { Bar, BarChart, Brush, CartesianGrid, Label, LabelList, ResponsiveContainer, XAxis, YAxis } from "recharts";

import ChartBox from "../../../components/frames/chartbox.tsx";
import { useDadosAPIProtegida } from "../../../services/dados.ts";

import { DiaOp } from "./tipos.ts";

export default function DiaOpVeiculo() {
  const { registros, ...chartProps } = useDadosAPIProtegida<DiaOp>({ consulta: "dias-operacao-veiculo" });
  const realizadaColor = "#574786";

  return (
    <ChartBox {...chartProps} registros={registros} titulo="Dias de operação do veículo">
      <ResponsiveContainer width="100%" height="100%" style={{ fontSize: "12px" }}>
        <BarChart data={registros} width={500} height={400} margin={{ top: 15, right: 25, bottom: 60, left: 70 }}>
          <CartesianGrid vertical={false} stroke="rgba(131, 135, 154, 0.10)" />
          <XAxis dataKey="nit" axisLine={false} tickLine={false} angle={-45} tickMargin={15} interval={0}>
            <Label position="bottom" offset={50}>
              Veículo
            </Label>
          </XAxis>
          <YAxis axisLine={false} tickLine={false}>
            <Label angle={-90} position="insideLeft" offset={-30}>
              Dias
            </Label>
          </YAxis>
          <Bar dataKey="diasOperandoMes" fill={realizadaColor}>
            <LabelList position="top" fontSize="10px" fontWeight={500} color={realizadaColor} />
          </Bar>
          <Brush height={10} endIndex={30} dataKey="nit" y={210} />
        </BarChart>
      </ResponsiveContainer>
    </ChartBox>
  );
}
