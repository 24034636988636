import { FormControl, InputLabel } from "@mui/material";

import FiltroMonovalorado from "./filtro-monovalorado.tsx";
import FiltroMultivalorado from "./filtro-multivalorado.tsx";
import { FiltroProps } from "./tipos.ts";

export default function Filtro({ definicao: d }: FiltroProps) {
  return (
    <FormControl variant="filled">
      <InputLabel id={d.variavel} shrink>
        {d.titulo}
      </InputLabel>
      {d.multivalorado ? <FiltroMultivalorado definicao={d} /> : <FiltroMonovalorado definicao={d} />}
    </FormControl>
  );
}
