import { API } from "../../../../services/api";

import AcaoDesabilitar from "./acao-desabilitar.tsx";
import AcaoHabilitar from "./acao-habilitar.tsx";

interface Props {
  usuario: API["UsuarioCadastrado"];
  icone?: boolean;
  onSuccess?: () => void;
}

export default function AcaoHabilitarDesabilitar({ usuario, icone = false, onSuccess }: Props) {
  if (usuario.habilitado) return <AcaoDesabilitar usuario={usuario} icone={icone} onSuccess={onSuccess} />;

  return <AcaoHabilitar usuario={usuario} icone={icone} onSuccess={onSuccess} />;
}
