import { Suspense } from "react";

import { Skeleton, Stack, StackProps } from "@mui/material";

import ErrorBoundary from "../../../components/error-boundary.tsx";
import ErroDados from "../../../components/frames/erro-dados.tsx";
import { useDadosAPIProtegida } from "../../../services/dados.ts";
import Filtro from "../filtro";

import { LinhaFiltro } from "./tipos.ts";

export default function BarraFiltros(props: StackProps) {
  const { registros: filtros, erro } = useDadosAPIProtegida<LinhaFiltro>({ consulta: "filtros" });

  return (
    <Stack flexDirection="row" alignItems="center" justifyContent="flex-end" flexWrap="wrap" gap={1} {...props}>
      {erro ? (
        <ErroDados erro={erro} />
      ) : (
        filtros?.map((d) => (
          <ErrorBoundary fallback={null} key={d.variavel}>
            <Suspense fallback={<Skeleton />}>
              <Filtro definicao={d} />
            </Suspense>
          </ErrorBoundary>
        ))
      )}
    </Stack>
  );
}
