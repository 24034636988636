import { forwardRef } from "react";

import { Box, FormControl, Select, SelectProps, Typography } from "@mui/material";

import { useSWRFromAPI } from "../../../../services/api/swr.ts";

interface Props extends Omit<SelectProps<string>, "variant"> {}

export default forwardRef<HTMLSelectElement, Props>(function PerfilSelecao({ ...props }, ref) {
  const { data: grupos } = useSWRFromAPI("/cadastros/usuarios/perfis", undefined, undefined, { forEditing: true });

  return (
    <Box sx={{ minWidth: 120 }}>
      <Typography fontSize={14} color="rgba(0,0,0,0.6)" mb="0.3em">
        Perfil no Mobnit
      </Typography>
      <FormControl fullWidth>
        <Select native ref={ref} size="small" {...props}>
          <option value="" />
          {grupos.map((g) => (
            <option key={g.nome} value={g.nome}>
              {g.apelido ?? g.nome}
            </option>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
});
