import { Card, CardContent, CardHeader, Stack, StackProps } from "@mui/material";

import dayjs from "dayjs";

import { fmtNumeroInteiro } from "../../../services/formatacao.ts";

import { usePassageirosDia, usePassageirosMes, useViagensDia, useViagensMes } from "./hooks.ts";

export function Contadores(props: StackProps) {
  const diaRef = dayjs().subtract(1, "week").startOf("day");
  const mesRef = dayjs().subtract(1, "month").startOf("day");

  const passageirosDiaAnterior = usePassageirosDia(diaRef);
  const passageirosMesAnterior = usePassageirosMes(mesRef);
  const viagensDia = useViagensDia(diaRef);
  const viagensMes = useViagensMes(mesRef);

  const mesRefFormatado = mesRef.format("MMMM[ de ]YYYY");
  const diaSemana = dayjs().format("dddd");
  const noUltimo = diaSemana.endsWith("feira") ? "na última" : "no último";

  const dados = [
    [`Passageiros ${noUltimo} ${diaSemana}`, passageirosDiaAnterior, diaRef.format("DD/MMM/YYYY")],
    [`Passageiros em ${mesRefFormatado}`, passageirosMesAnterior],
    [`Viagens ${noUltimo} ${diaSemana}`, viagensDia, diaRef.format("DD/MMM/YYYY")],
    [`Viagens em ${mesRefFormatado}`, viagensMes],
  ] as const;

  return (
    <Stack
      {...props}
      width="100%"
      display="grid"
      gridTemplateColumns="1fr 1fr"
      gridTemplateRows="1fr 1fr"
      gap={2}
      px={2}
      pb={2}
      sx={{
        ...props.sx,
        "& .MuiCardHeader-root": { bgcolor: "#F7F7F7", color: "#6A6A6A" },
        "& .MuiCardHeader-content span": { fontSize: 12, fontWeight: "bold" },
        "& .MuiCardContent-root:nth-of-type(2)": { py: 2, textAlign: "center", fontSize: 30, color: "primary.main" },
        "& .MuiCardContent-root:nth-of-type(3)": {
          p: 1,
          mt: -3,
          px: 2,
          textAlign: "right",
          fontSize: 8,
          fontWeight: "normal",
          color: "darkgray",
        },
      }}
    >
      {dados.map(([titulo, valor, footer], i) => (
        <Card key={i}>
          <CardHeader title={titulo} />
          <CardContent>{fmtNumeroInteiro.format(valor)}</CardContent>
          {footer && <CardContent>{footer}</CardContent>}
        </Card>
      ))}
    </Stack>
  );
}
