import { ReactNode, useCallback, useMemo } from "react";

import { jwtDecode } from "jwt-decode";
import { User, WebStorageStateStore } from "oidc-client-ts";
import { AuthProvider as OidcAuthProvider, AuthProviderProps, useAuth } from "react-oidc-context";
import { Outlet } from "react-router-dom";

import { caminhoParaPermissao } from "../../services/permissoes.ts";

import { AuthContext } from "./context.tsx";
import { IAuthContext, IUser, KeycloakJWTPayload } from "./types.ts";

const oidcConfig: AuthProviderProps = {
  authority: import.meta.env.VITE_OAUTH_BASE_URL,
  client_id: import.meta.env.VITE_OAUTH_CLIENT_ID,
  redirect_uri: location.origin + "/gestor",
  post_logout_redirect_uri: location.origin + "/",
  userStore: new WebStorageStateStore({ store: window.localStorage }),
  automaticSilentRenew: true,
  onSigninCallback(_user: User | void) {
    window.history.replaceState({}, document.title, window.location.pathname);
  },
  onRemoveUser() {
    window.location.href = "/";
  },
  loadUserInfo: true,
};

export function AuthProvider() {
  return (
    <OidcAuthProvider {...oidcConfig}>
      <MobnitKeycloakProvider>
        <Outlet />
      </MobnitKeycloakProvider>
    </OidcAuthProvider>
  );
}

export function MobnitKeycloakProvider({ children }: { children: ReactNode }) {
  const auth = useAuth();

  const currentUser = useMemo((): IUser | undefined | null => {
    if (auth.isLoading) return undefined;
    if (!auth.user || auth.user.expired) return null;

    const decodedToken = jwtDecode<KeycloakJWTPayload>(auth.user.access_token);

    return {
      profile: auth.user.profile,
      accessToken: auth.user.access_token,
      roles: decodedToken.realm_access.roles,
    };
  }, [auth.isLoading, auth.user]);

  const signInIntegrated = useCallback(() => auth.signinRedirect(), [auth]);
  const signOut = useCallback(() => auth.signoutRedirect(), [auth]);

  const possuiPermissao = useCallback((path: string) => verificaRole(currentUser?.roles, path), [currentUser?.roles]);

  // monta o objeto final do contexto, que ficará disponível via useContext
  const ctx = useMemo(
    (): IAuthContext => ({
      currentUser,
      signInIntegrated,
      signOut,
      possuiPermissao,
      isAdmin: currentUser?.roles?.includes("admin") ?? false,
      isEditor: currentUser?.roles?.includes("editor") ?? false,
    }),
    [currentUser, possuiPermissao, signInIntegrated, signOut],
  );

  // expõe o objeto do contexto via provider
  return <AuthContext.Provider value={ctx}>{children}</AuthContext.Provider>;
}

function verificaRole(roles: string[] | undefined, path: string) {
  if (!roles) return false;

  const permissaoSolicitada = caminhoParaPermissao(path);

  return !!roles.find((role) => role === permissaoSolicitada || role.startsWith(permissaoSolicitada + "-"));
}
