import { ReactNode } from "react";

import { Paper, Stack, StackProps, Typography } from "@mui/material";

interface Props extends StackProps {
  titulo: ReactNode;
}

export default function AreaFormulario({ titulo, children, ...props }: Props) {
  return (
    <Stack
      component="form"
      {...props}
      sx={{
        maxWidth: "40em",
        alignSelf: "center",
        color: "rgba(131, 135, 154, 1)",
        ["& p span"]: { fontSize: "13px", pl: 1, color: "rgba(131, 135, 154, 1)" },
        ["& label"]: { fontSize: "14px" },
        ...props.sx,
      }}
    >
      <Typography color="#000000" pb={2}>
        {titulo}
      </Typography>

      <Stack component={Paper} border="1px solid rgba(131, 135, 154, 0.19)" gap={2} p={2}>
        {children}
      </Stack>
    </Stack>
  );
}
