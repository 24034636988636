import TableBox from "../../../components/frames/table-box.tsx";
import { useDadosAPIPaginados } from "../../../services/dados.ts";

interface IKmFrota {
  empresa: string;
  numeroLinha: string;
  ano: number;
  mes: number;
  kmRealizado: number;
  frotaOperante: number;
  kmPorVeiculo: number;

  [k: string]: unknown;
}

export default function TabelaQuilometragemFrota() {
  const tabelaProps = useDadosAPIPaginados<IKmFrota>({ consulta: "km-frota" });

  return (
    <TableBox
      {...tabelaProps}
      titulo="Quilometragem da **frota**"
      colunas={[
        { id: "empresa", label: "Empresa", type: "string" },
        { id: "numeroLinha", label: "Linha", type: "string" },
        { id: "data", label: "Data", type: "date" },
        { id: "kmRealizado", label: "Quilometragem realizada", type: "km" },
        { id: "frotaOperante", label: "Frota operante", type: "inteiro" },
        { id: "kmPorVeiculo", label: "Quilometragem por veículo", type: "km" },
      ]}
    />
  );
}
