import { CartesianGrid, Label, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";

import ChartBox from "../../../components/frames/chartbox.tsx";
import TooltipHorarioPassageiros from "../../../components/tooltips/tooltip-horario-passageiros.tsx";
import { useDadosAPIProtegida } from "../../../services/dados.ts";

import { PassageirosPorHora } from "./tipos.ts";

export default function HorarioPicoPassageiros() {
  const chartProps = useDadosAPIProtegida<PassageirosPorHora>({ consulta: "horario-pico-passageiros" });

  const realizadaColor = "#574786";

  return (
    <ChartBox titulo="Horário de **pico de Passageiros**" {...chartProps}>
      <ResponsiveContainer width="100%" height="100%" style={{ fontSize: "12px" }}>
        <LineChart
          data={chartProps.registros}
          width={500}
          height={400}
          margin={{ top: 15, right: 25, bottom: 45, left: 70 }}
        >
          <CartesianGrid vertical={false} stroke="rgba(131, 135, 154, 0.10)" />
          <XAxis dataKey="hora" axisLine={false} tickLine={false} tickMargin={15} angle={-45}>
            <Label position="bottom" offset={25}>
              Horário
            </Label>
          </XAxis>
          <YAxis axisLine={false} tickLine={false}>
            <Label angle={-90} position="insideLeft" offset={-30}>
              Passageiros
            </Label>
          </YAxis>
          <Tooltip content={TooltipHorarioPassageiros} />
          <Line dataKey="mediaPassageirosPorHora" fill={realizadaColor} stroke={realizadaColor} strokeWidth="2px" />
        </LineChart>
      </ResponsiveContainer>
    </ChartBox>
  );
}
