import { Suspense } from "react";

import { Outlet } from "react-router-dom";

import Aguarde from "../../components/aguarde.tsx";
import ErrorBoundary from "../../components/error-boundary.tsx";
import { useMobnitMatches } from "../../routes/hooks.ts";
import PageLayout from "../page-layout.tsx";

import BarraFiltros from "./barra-filtros";
import FiltroData from "./barra-filtros/filtro-data.tsx";

export default function DashboardLayout() {
  const matches = useMobnitMatches();

  const escondeFiltroData = matches.some((m) => m.handle?.filtroData === false);

  return (
    <ErrorBoundary>
      <Suspense fallback={<Aguarde />}>
        <PageLayout filtros={<BarraFiltros />} filtroData={escondeFiltroData ? null : <FiltroData />}>
          <Outlet />
        </PageLayout>
      </Suspense>
    </ErrorBoundary>
  );
}
