import { Box, SvgIconProps } from "@mui/material";

import Info from "../../assets/icones/info.tsx";
import LightTooltip from "../tooltips/light-tooltip.tsx";

interface Props {
  title: string;
  iconProps?: SvgIconProps;
}

export default function TooltipIndicador({ title, iconProps }: Props) {
  return (
    <LightTooltip title={title}>
      <Box sx={{ display: "inline-block" }}>
        <Info
          {...iconProps}
          sx={{
            color: "#A5A3A3",
            fontSize: "18px",
            position: "relative",
            left: "0.1em",
            top: "0.15em",
            marginRight: "auto",
            ...iconProps?.sx,
          }}
        />
      </Box>
    </LightTooltip>
  );
}
