import { SvgIcon } from "@mui/material";

export default function Frota() {
  return (
    <SvgIcon>
      <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M10.4708 15.3333H3.52909C2.32492 15.3333 1.33325 14.4067 1.33325 13.2667V4.06667C1.33325 2.93333 2.31784 2 3.52909 2H10.4708C11.6749 2 12.6666 2.92667 12.6666 4.06667V13.2667C12.6666 14.4067 11.682 15.3333 10.4708 15.3333Z"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.6041 9.33333H2.39575C1.80784 9.33333 1.33325 8.88667 1.33325 8.33333V7C1.33325 6.44667 1.80784 6 2.39575 6H11.6041C12.192 6 12.6666 6.44667 12.6666 7V8.33333C12.6666 8.88667 12.192 9.33333 11.6041 9.33333Z"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M4.66675 12.6667H4.67207M9.32742 12.6667H9.33341"
          stroke="currentColor"
          strokeWidth="2.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.33325 4H8.66659"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </SvgIcon>
  );
}
