import { OrderedMap } from "immutable";
import { atom } from "jotai";
import { atomWithReset } from "jotai/utils";

const VALORES_INFORMACOES = [
  {
    nome: "Eixo viário",
    url: [
      "https://services8.arcgis.com/TpaOLI1HCh5AcRQB/arcgis/rest/services/Grouplayer_SMU_UDU_AGOL/FeatureServer/90/query?outFields=*&where=1%3D1&f=geojson",
    ],
  },
  {
    nome: "Educação",
    url: [
      "https://geo.niteroi.rj.gov.br/arcgisesri/rest/services/civitas/Dadosabertos_AS_SMF_CLIN_CULT_EDUC_LIMPOL/MapServer/55/query?outFields=*&where=1%3D1&f=geojson",
      "https://geo.niteroi.rj.gov.br/arcgisesri/rest/services/civitas/Dadosabertos_AS_SMF_CLIN_CULT_EDUC_LIMPOL/MapServer/60/query?outFields=*&where=1%3D1&f=geojson",
    ],
  },
  {
    nome: "Assistência social",
    url: [
      "https://services8.arcgis.com/TpaOLI1HCh5AcRQB/arcgis/rest/services/Grouplayer_SMASES_GSUAS_AGOL/FeatureServer/10/query?outFields=*&where=1%3D1&f=geojson",
    ],
  },
  {
    nome: "Contadores de mobilidade",
    url: [
      "https://geo.niteroi.rj.gov.br/arcgisesri/rest/services/civitas/Dadosabertos_SEXEC_SEPLAG_SIGEO/MapServer/20/query?outFields=*&where=1%3D1&f=geojson",

      "https://services8.arcgis.com/TpaOLI1HCh5AcRQB/arcgis/rest/services/Grouplayer_SIGEO_AGOL/FeatureServer/160/query?outFields=*&where=1%3D1&f=geojson",
    ],
  },
  {
    nome: "Bicicletas compartilhadas",
    url: [
      "https://geo.niteroi.rj.gov.br/arcgisesri/rest/services/civitas/Dadosabertos_SEXEC_SEPLAG_SIGEO/MapServer/40/query?outFields=*&where=1%3D1&f=geojson",
      "https://geo.niteroi.rj.gov.br/arcgisesri/rest/services/civitas/Dadosabertos_SEXEC_SEPLAG_SIGEO/MapServer/55/query?outFields=*&where=1%3D1&f=geojson",
    ],
  },
  {
    nome: "Limites do município",
    url: [
      "https://services8.arcgis.com/TpaOLI1HCh5AcRQB/arcgis/rest/services/Grouplayer_camadas_agol/FeatureServer/1/query?outFields=*&where=1%3D1&f=geojson",
      "https://services8.arcgis.com/TpaOLI1HCh5AcRQB/arcgis/rest/services/Grouplayer_camadas_agol/FeatureServer/2/query?outFields=*&where=1%3D1&f=geojson",
      "https://services8.arcgis.com/TpaOLI1HCh5AcRQB/arcgis/rest/services/Grouplayer_camadas_agol/FeatureServer/0/query?outFields=*&where=1%3D1&f=geojson",
    ],
  },
  {
    nome: "Toponímeas",
    url: [
      "https://services8.arcgis.com/TpaOLI1HCh5AcRQB/arcgis/rest/services/Grouplayer_SMU_UDU_AGOL/FeatureServer/40/query?outFields=*&where=1%3D1&f=geojson",
    ],
  },
  {
    nome: "Topografia",
    url: [
      "https://services8.arcgis.com/TpaOLI1HCh5AcRQB/arcgis/rest/services/Grouplayer_SIGEO_AGOL/FeatureServer/90/query?outFields=*&where=1%3D1&f=geojson",
    ],
  },
  {
    nome: "Logradouros",
    url: [
      "https://geo.niteroi.rj.gov.br/arcgisesri/rest/services/civitas/Dadosabertos_SMARHS_SMDCG_SMS_SMU/MapServer/150/query?outFields=*&where=1%3D1&f=geojson",
    ],
  },
  {
    nome: "Abrigos de ônibus",
    url: [
      "https://services8.arcgis.com/TpaOLI1HCh5AcRQB/arcgis/rest/services/Grouplayer_SMU_UDU_AGOL/FeatureServer/60/query?outFields=*&where=1%3D1&f=geojson",
    ],
  },
  {
    nome: "Integração metropolitana",
    url: [
      "https://services8.arcgis.com/TpaOLI1HCh5AcRQB/arcgis/rest/services/Grouplayer_SMU_PLANODIRETOR_AGOL/FeatureServer/550/query?outFields=*&where=1%3D1&f=geojson",
    ],
  },
  {
    nome: "Integração municipal",
    url: [
      "https://services8.arcgis.com/TpaOLI1HCh5AcRQB/arcgis/rest/services/Grouplayer_SMU_PLANODIRETOR_AGOL/FeatureServer/540/query?outFields=*&where=1%3D1&f=geojson",
    ],
  },
];

const VALORES_LINHAS = [
  {
    nome: "Transnit",
    url: [
      "https://services8.arcgis.com/TpaOLI1HCh5AcRQB/arcgis/rest/services/Grouplayer_SMU_UDU_AGOL/FeatureServer/70/query?outFields=*&where=1%3D1&f=geojson",
    ],
  },
  {
    nome: "Transoceânica",
    url: [
      "https://services8.arcgis.com/TpaOLI1HCh5AcRQB/arcgis/rest/services/Grouplayer_SMU_UDU_AGOL/FeatureServer/80/query?outFields=*&where=1%3D1&f=geojson",
    ],
  },
  {
    nome: "BHSTransoceânica",
    url: [
      "https://services8.arcgis.com/TpaOLI1HCh5AcRQB/arcgis/rest/services/Grouplayer_SMU_PLANODIRETOR_AGOL/FeatureServer/580/query?outFields=*&where=1%3D1&f=geojson",
    ],
  },
  {
    nome: "BHSTransPendotiba",
    url: [
      "https://services8.arcgis.com/TpaOLI1HCh5AcRQB/arcgis/rest/services/Grouplayer_SMU_PLANODIRETOR_AGOL/FeatureServer/570/query?outFields=*&where=1%3D1&f=geojson",
    ],
  },
  {
    nome: "BHS Região Norte",
    url: [
      "https://services8.arcgis.com/TpaOLI1HCh5AcRQB/arcgis/rest/services/Grouplayer_SMU_PLANODIRETOR_AGOL/FeatureServer/560/query?outFields=*&where=1%3D1&f=geojson",
    ],
  },
  {
    nome: "Sistema de barcas",
    url: [
      "https://services8.arcgis.com/TpaOLI1HCh5AcRQB/arcgis/rest/services/Grouplayer_SMU_PLANODIRETOR_AGOL/FeatureServer/610/query?outFields=*&where=1%3D1&f=geojson",
      "https://services8.arcgis.com/TpaOLI1HCh5AcRQB/arcgis/rest/services/Grouplayer_SMU_PLANODIRETOR_AGOL/FeatureServer/600/query?outFields=*&where=1%3D1&f=geojson",
    ],
  },
];

export const informacoesSelecionadasAtom = atomWithReset(
  OrderedMap<string, boolean>(VALORES_INFORMACOES.map((d) => [d.nome, false])),
);

export const linhasSelecionadasAtom = atomWithReset(
  OrderedMap<string, boolean>(VALORES_LINHAS.map((d) => [d.nome, false])),
);

export const camadasSelecionadasAtom = atom((get) => [
  ...VALORES_INFORMACOES.filter((d) => get(informacoesSelecionadasAtom).get(d.nome)),
  ...VALORES_LINHAS.filter((d) => get(linhasSelecionadasAtom).get(d.nome)),
]);

export type AtomoOpcoes = typeof linhasSelecionadasAtom;
