import { useCallback, useMemo } from "react";

import axios, { AxiosInstance } from "axios";
import { FeatureCollection, Point } from "geojson";
import { Fetcher } from "swr";

import { useAuthContext } from "./auth.ts";

export const apiBaseURL = import.meta.env.VITE_MOBNIT_API_BASE_URL || "/";

export const axiosApi = axios.create({ baseURL: apiBaseURL });

export function useAuthAxios(): AxiosInstance {
  const { currentUser } = useAuthContext();

  return useMemo(() => {
    const headers = currentUser?.accessToken ? { Authorization: `Bearer ${currentUser?.accessToken}` } : {};

    return axios.create({ baseURL: apiBaseURL, headers });
  }, [currentUser?.accessToken]);
}

export function useAuthQueryFetcher<T = unknown>(): Fetcher<T[]> {
  const authAxios = useAuthAxios();

  return useCallback((key: string) => authAxios.get<T[]>(key).then((r) => r.data), [authAxios]);
}

export async function camadasFetcher(keys: { nome: string; url: string[] }[]) {
  return Promise.all(
    keys
      .filter(({ url }) => !!url)
      .flatMap(({ nome, url: urls }) =>
        urls.map((url, i) =>
          fetch(url).then(async (r) => [nome, i, (await r.json()) as FeatureCollection<Point>] as const),
        ),
      ),
  );
}
