import { DatePicker, DatePickerProps } from "@mui/x-date-pickers";

import dayjs from "dayjs";
import { useAtom } from "jotai";

import { filtroDataAte, filtroDataDe } from "./valores.ts";

export default function FiltroData() {
  const [from, setFrom] = useAtom(filtroDataDe);
  const [to, setTo] = useAtom(filtroDataAte);

  return (
    <>
      <DatePicker {...BASE_PROPS} value={from} onChange={(v) => setFrom(v ?? from)} disableFuture />
      <DatePicker {...BASE_PROPS} value={to} onChange={(v) => setTo(v ?? to)} disableFuture />
    </>
  );
}

const BASE_PROPS: DatePickerProps<dayjs.Dayjs> = {
  slotProps: {
    textField: { variant: "filled", size: "small", hiddenLabel: true },
    inputAdornment: { sx: { width: "0em", ml: "-2.5em" } },
  },
  sx: { width: "13em", fontSize: ".75rem" },
};
