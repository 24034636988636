import { Box, Card, CardContent, CardHeader, Stack } from "@mui/material";

import Markdown from "react-markdown";

import { fmtMoedaBR } from "../../../services/formatacao.ts";

export default function ModalidadeTarifaria() {
  const modalidadeTarifaria = [
    { nome: "Tarífa **normal**", valor: 4.45, descricao: "" },
    { nome: "Bilhete **municipal**", valor: 4.45, descricao: "Com **uma** integração de graça em até **1 hora**" },
    {
      nome: "Bilhete **único intermunicipal**",
      valor: 8.85,
      descricao:
        "Com uma integração com serviço **intermunicipal** no intervalo de **3 horas** para pessoas que possuem até **R$ 3.224** de renda",
    },
    {
      nome: "Cartão **gratuidade**",
      valor: 0,
      descricao: "Estudantes, idosos e pessoas com deficiência com a apresentação do respectivo cartão",
    },
  ];

  return (
    <>
      <CardHeader title="Tarifas" titleTypographyProps={{ fontSize: "1.25rem", color: "#0C0C0C" }} />

      <Stack
        flexDirection="row"
        sx={{
          "flex": 1,
          "gap": 2,
          "paddingY": "2rem",
          "paddingX": "4rem",
          "background": "#FBFCFF",
          "fontSize": "0.75rem",
          "color": "#0C0C0C",
          "& .titulo": { fontSize: "0.875rem", whiteSpace: "nowrap" },
          "& .valor": { color: "#F5792B", fontSize: "2rem", fontWeight: 700 },
          "& .descricao": { color: "#6A6A6A" },
        }}
      >
        {modalidadeTarifaria.map((r) => (
          <Card
            key={r.nome}
            sx={{
              display: "flex",
              minWidth: "10rem",
              boxShadow: "0px 2px 8px -3px rgba(0, 0, 0, 0.25)",
              borderRadius: "7px",
            }}
          >
            <CardContent>
              <Box className="titulo">
                <Markdown>{r.nome}</Markdown>
              </Box>
              <Box className="valor">{r.valor === 0 ? "Gratuito" : `${fmtMoedaBR.format(r.valor)}`}</Box>
              <Box className="descricao">
                <Markdown>{r.descricao}</Markdown>
              </Box>
            </CardContent>
          </Card>
        ))}
      </Stack>
    </>
  );
}
