import { SvgIcon, SxProps } from "@mui/material";

export default function Arrowback({ sx }: { sx?: SxProps }) {
  return (
    <SvgIcon sx={{ backgroundColor: "primay.main", ...sx }}>
      <svg width="39" height="39" viewBox="0 0 39 39" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="20.5" cy="19.5" r="18.5" fill="#F5792B" />
        <path
          d="M22.4737 30.875C22.2309 30.8758 21.9911 30.8222 21.7717 30.7182C21.5524 30.6142 21.3592 30.4623 21.2062 30.2737L13.3575 20.5238C13.1185 20.233 12.9878 19.8683 12.9878 19.4919C12.9878 19.1155 13.1185 18.7508 13.3575 18.46L21.4825 8.71C21.7583 8.37815 22.1546 8.16946 22.5843 8.12984C23.014 8.09022 23.4419 8.22293 23.7737 8.49875C24.1056 8.77458 24.3143 9.17093 24.3539 9.60062C24.3935 10.0303 24.2608 10.4581 23.985 10.79L16.7212 19.5L23.7412 28.21C23.9399 28.4485 24.0661 28.739 24.1049 29.047C24.1438 29.355 24.0935 29.6677 23.9602 29.9481C23.8268 30.2284 23.616 30.4647 23.3525 30.629C23.0891 30.7933 22.7841 30.8786 22.4737 30.875Z"
          fill="white"
        />
      </svg>
    </SvgIcon>
  );
}
