import { Stack } from "@mui/material";

import MediaViagensNaoSubsidiadas from "./indicadores/media-viagens-nao-subsidiadas.tsx";
import MediaViagensSubsidiadas from "./indicadores/media-viagens-subsidiadas.tsx";
import MediaViagens from "./indicadores/media-viagens.tsx";
import SubsidioOperadora from "./indicadores/subsidio-operadora.tsx";
import SubsidioTotal from "./indicadores/subsidio-total.tsx";
import SubsidioUsuario from "./indicadores/subsidio-usuario.tsx";
import TotalPassageirosNaoSubsidiados from "./indicadores/total-passageiros-nao-subsidiados.tsx";
import TotalPassageirosSubsidiados from "./indicadores/total-passageiros-subsidiados.tsx";
import TotalViagensNaoSubsidiadas from "./indicadores/total-viagens-nao-subsidiadas.tsx";
import TotalViagensSubsidiadas from "./indicadores/total-viagens-subsidiadas.tsx";
import TabelaSubsidio from "./tabela-subsidio.tsx";

export default function Subsidios() {
  return (
    <Stack sx={{ p: { xs: 1, md: 0 } }}>
      <Stack
        gridTemplateColumns="repeat(3, 1fr)"
        gridAutoRows="7.5rem"
        sx={{
          "gap": { xs: 1, md: 4 },
          "pt": { xs: 0, md: 2 },
          "pb": { xs: 0, md: 6 },
          "px": { xs: 0, md: 4 },
          "display": { xs: "flex", md: "grid" },
          "& .indicador": { fontSize: "45px" },
        }}
      >
        <SubsidioUsuario />
        <SubsidioOperadora />
        <SubsidioTotal />
      </Stack>

      <Stack
        display="grid"
        gridTemplateColumns={{ xs: "1fr", lg: "repeat(2, 1fr)", xl: "repeat(3, 1fr)" }}
        gridAutoRows="9.5rem"
        gap={2}
        mb={2}
      >
        <TotalPassageirosSubsidiados />
        <TotalPassageirosNaoSubsidiados />

        <TotalViagensSubsidiadas />
        <TotalViagensNaoSubsidiadas />

        <MediaViagensSubsidiadas />
        <MediaViagensNaoSubsidiadas />
        <MediaViagens />
      </Stack>

      <TabelaSubsidio />
    </Stack>
  );
}
