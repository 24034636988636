import IndicadorComDescricao from "../../../../components/indicador-com-descricao";
import { useConteudo } from "../../../../services/conteudo";
import { useIndicador } from "../../../../services/dados.ts";

export default function SubsidioUsuario() {
  const conteudo = useConteudo();
  const indicadorProps = useIndicador({ consulta: "subsidio-usuario" });

  return (
    <IndicadorComDescricao
      {...indicadorProps}
      variante="resumido"
      titulo={conteudo.texto.auditoria.subsidios.su.titulo}
      cor="#F5792B"
      corVariacao="#83879A"
      descricao={conteudo.texto.auditoria.subsidios.su.tooltip}
    />
  );
}
