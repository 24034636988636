import { AxiosError, AxiosInstance, AxiosRequestConfig, isAxiosError } from "axios";
import { get, isString } from "lodash";

import * as website from "./generated/website";

export type API = website.components["schemas"];

export type paths = website.paths;

export function typedGet<
  T extends keyof paths,
  TPathParams extends ParametersForPath<paths, T, "get">,
  TResponse extends ResponseBodyForPath<paths, T, "get">,
>(axios: AxiosInstance, path: NonNullable<T>, params?: TPathParams, config?: AxiosRequestConfig<never>) {
  const finalPath = String(path).replace(/{([^}]+)}/, (_, m1: string) =>
    !params ? m1 : String(params[m1 as keyof TPathParams]),
  );

  return axios.get<TResponse>(finalPath, config);
}

export function typedRequest<
  K extends HttpMethods,
  T extends keyof paths,
  TPathParams extends ParametersForPath<paths, T, K>,
  TResponse extends ResponseBodyForPath<paths, T, K>,
  TBody extends RequestBodyForPath<paths, T, K>,
>(axios: AxiosInstance, method: K, path: NonNullable<T>, params?: TPathParams, config?: AxiosRequestConfig<TBody>) {
  const url = String(path).replace(/{([^}]+)}/, (_, m1: string) =>
    !params ? m1 : String(params[m1 as keyof TPathParams]),
  );

  return axios.request<TResponse>({ method, url, ...config });
}

type HttpMethods = "get" | "post" | "patch" | "put" | "delete";

export type ResponseBodyForPath<P, T extends keyof P, K extends HttpMethods> = P[T] extends { [Key in K]: infer U }
  ? U extends { responses: infer V }
    ? V extends { 200: infer W }
      ? W extends { content: infer X }
        ? X extends { "application/json": infer Y }
          ? Y
          : unknown
        : unknown
      : unknown
    : unknown
  : unknown;

export type RequestBodyForPath<P, T extends keyof P, K extends HttpMethods> = P[T] extends { [Key in K]: infer U }
  ? U extends { requestBody: infer V }
    ? V extends { content: infer W }
      ? W extends { "application/json": infer X }
        ? X
        : unknown
      : unknown
    : unknown
  : unknown;

export type ParametersForPath<P, T extends keyof P, K extends HttpMethods> = P[T] extends { [Key in K]: infer U }
  ? U extends { parameters: infer V }
    ? V extends { path: infer W }
      ? W
      : unknown
    : unknown
  : never;

export type ParametersForQuery<P, T extends keyof P, K extends HttpMethods> = P[T] extends { [Key in K]: infer U }
  ? U extends { parameters: infer V }
    ? V extends { query: infer W }
      ? W
      : unknown
    : unknown
  : never;

export interface MensagemErro {
  detalhes: { sql: string };
  erro: { tipo: string; mensagem: string; traceback: string[] };
}

export function isQueryError(e: unknown): e is { response: { data: MensagemErro } } {
  return isAxiosError(e) && isString(get(e, "response.data.detalhes.sql"));
}

export function isPermissionError(e: unknown): e is AxiosError {
  return isAxiosError(e) && e.response?.status === 403;
}
