let gCanvas: HTMLCanvasElement;

export function getTextWidth(text: string, font: string): number {
  // re-use canvas object for better performance
  const canvas = (gCanvas ??= document.createElement("canvas"));
  const context = canvas.getContext("2d");
  if (!context) return text.length * 12;

  context.font = font;
  const metrics = context.measureText(text);

  return metrics.width;
}
