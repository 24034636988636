import { forwardRef, useCallback, useMemo } from "react";

import { Skeleton, Table, TableBody, TableCell, TableContainer, TableHead, TableProps } from "@mui/material";

import { TableComponents, TableVirtuoso } from "react-virtuoso";

import CabecalhoTabela from "./cabecalho-tabela.tsx";
import CellValue from "./cell-value";
import LinhaTabela from "./linha-tabela.tsx";
import DataRowsProvider from "./provider.tsx";
import { InfoMinimaColunas, ObjetoDados, useDataRowsContext } from "./types.ts";

export interface Props<T extends ObjetoDados> extends TableProps {
  linhas?: T[];
  colunas: InfoMinimaColunas<T>[];
  componentes?: Partial<TableComponents<T>>;
}

export default function Tabela<T extends ObjetoDados>({ linhas, colunas, ...props }: Props<T>) {
  return (
    <DataRowsProvider linhas={linhas ?? Array(10)} colunas={colunas}>
      <TabelaInterna {...props} />
    </DataRowsProvider>
  );
}

export function TabelaInterna<T extends ObjetoDados>({
  componentes,
  ...props
}: TableProps & {
  componentes?: Partial<TableComponents<T>>;
}) {
  const { linhas, colunas } = useDataRowsContext<T>();

  const itemContentCallback = useCallback(
    (index: number) => (
      <>
        {colunas.map((col, i) =>
          linhas[index] ? (
            <CellValue key={i} row={linhas[index]} col={col} />
          ) : (
            <TableCell key={i}>
              <Skeleton />
            </TableCell>
          ),
        )}
      </>
    ),
    [colunas, linhas],
  );

  /* eslint-disable react/display-name,react/prop-types */
  const components = useMemo(
    (): TableComponents<T> => ({
      Scroller: forwardRef((props, ref) => <TableContainer {...props} ref={ref} />),
      TableHead: forwardRef((props, ref) => <TableHead {...props} ref={ref} />),
      TableRow: (props) => <LinhaTabela {...props} />,
      TableBody: forwardRef((props, ref) => <TableBody {...props} ref={ref} />),
      Table: (vprops) => (
        <Table {...props} {...vprops} sx={{ ...props.sx, borderCollapse: "separate", tableLayout: "fixed" }} />
      ),
      ...componentes,
    }),
    [componentes, props],
  );
  /* eslint-enable react/display-name,react/prop-types */

  return (
    <TableVirtuoso
      components={components}
      fixedHeaderContent={() => <CabecalhoTabela />}
      itemContent={itemContentCallback}
      totalCount={linhas.length}
      overscan={100}
    />
  );
}
