import IndicadorComDescricao from "../../../../components/indicador-com-descricao";
import { useConteudo } from "../../../../services/conteudo";
import { useIndicador } from "../../../../services/dados.ts";
import { fmtPercentual } from "../../../../services/formatacao.ts";

export default function Ici() {
  const conteudo = useConteudo();
  const indicadorProps = useIndicador({ consulta: "ici" });

  return (
    <IndicadorComDescricao
      {...indicadorProps}
      titulo={conteudo.texto.indicadores.frota.ici.titulo}
      numberFormat={fmtPercentual}
      cor="#683AB7"
      corVariacao="#FFA800"
      descricao={conteudo.texto.indicadores.frota.ici.tooltip}
    />
  );
}
