import IndicadorComDescricao from "../../../../components/indicador-com-descricao";
import { useConteudo } from "../../../../services/conteudo";
import { useIndicador } from "../../../../services/dados.ts";

export default function SubsidioOperadora() {
  const conteudo = useConteudo();
  const indicadorProps = useIndicador({ consulta: "subsidio-operadora" });

  return (
    <IndicadorComDescricao
      {...indicadorProps}
      variante="resumido"
      titulo={conteudo.texto.auditoria.subsidios.so.titulo}
      cor="#F5792B"
      corVariacao="#83879A"
      descricao={conteudo.texto.auditoria.subsidios.so.tooltip}
    />
  );
}
