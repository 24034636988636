import { lazy, Suspense } from "react";

import { Stack } from "@mui/material";

import Aguarde from "../aguarde.tsx";
import ErrorBoundary from "../error-boundary.tsx";

import LegendaItinerario from "./legenda-itinerario.tsx";
import ListaLinhas from "./lista-linhas.tsx";

const MapaItinerario = lazy(() => import("./mapa-itinerario.tsx"));

export default function ItinerariosERotas() {
  return (
    <Stack
      display="grid"
      gridTemplateColumns="1fr 20rem"
      gridTemplateRows="auto 1fr"
      gridAutoFlow="column"
      columnGap={3}
    >
      <ErrorBoundary>
        <Suspense fallback={<Aguarde />}>
          <MapaItinerario />
        </Suspense>
      </ErrorBoundary>
      <LegendaItinerario columnGap="span 2" />
      <ListaLinhas />
    </Stack>
  );
}
