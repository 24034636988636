import { Card, CardContent } from "@mui/material";

import { Pane } from "react-leaflet";

import Aguarde from "../../../components/aguarde.tsx";

export default function PaneAguarde() {
  return (
    <Pane name="Aguarde">
      <Card sx={{ opacity: 0.85, width: "50vh", position: "relative", left: "5em", top: "2em" }}>
        <CardContent>
          <Aguarde />
        </CardContent>
      </Card>
    </Pane>
  );
}
