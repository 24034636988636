import { Navigate } from "react-router-dom";

import { MenuDesktop } from "../../layouts/shared/menu-desktop.tsx";
import { useAuthContext } from "../../services/auth.ts";
import { useIsTabletOrSmaller } from "../../services/responsividade.ts";

import PrimeiraRotaDisponivel from "./primeira-rota-disponivel.tsx";

export default function PaginaInicialGestor() {
  const isTablet = useIsTabletOrSmaller();

  const { currentUser } = useAuthContext();

  // se tiver permissão para a home do gestor, leva para lá
  const roles = currentUser?.roles;
  if (roles?.includes("admin") || roles?.includes("editor") || roles?.includes("acesso-home"))
    return <Navigate to="home" />;

  // se estamos no mobile, exibimos o menu
  if (isTablet) return <MenuDesktop />;

  // em outros casos, direciona à primeira rota disponível para o usuário
  return <PrimeiraRotaDisponivel />;
}
