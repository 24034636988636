import { Suspense } from "react";

import { Paper, Stack } from "@mui/material";

import Aguarde from "../../../components/aguarde.tsx";
import Titulos from "../../../components/titulos.tsx";

import Status from "./status.tsx";

export default function HorariosPontualidade() {
  return (
    <Stack gap={4} flex={1}>
      <Titulos titulo="MOBILIDADE" subtitulo="Horários e pontualidade" />
      <Suspense
        fallback={
          <Stack component={Paper} sx={{ height: "60vh", justifyContent: "center" }}>
            <Aguarde />
          </Stack>
        }
      >
        <Status />
      </Suspense>
    </Stack>
  );
}
