import * as React from "react";
import { ReactNode, useCallback } from "react";

import { Backdrop, Box, Button, Stack } from "@mui/material";

import { Link } from "react-router-dom";

import { useAuthContext } from "../../services/auth.ts";

import { MenuDesktopAdmin } from "./menu-desktop-admin.tsx";
import { MenuDesktop } from "./menu-desktop.tsx";

export default function MenuMobile({ children }: { children: ReactNode }) {
  const { currentUser } = useAuthContext();
  const [open, setOpen] = React.useState(false);
  const handleClose = useCallback(() => setOpen(false), [setOpen]);
  const handleOpen = useCallback(() => setOpen(true), [setOpen]);

  return (
    <Box mr={-1.2}>
      {currentUser ? (
        <Button onClick={handleOpen} size="large" sx={{ p: 0 }}>
          {children}
        </Button>
      ) : (
        <Button component={Link} to="/" size="large" sx={{ p: 0 }}>
          {children}
        </Button>
      )}

      <Backdrop
        sx={{
          alignItems: "flex-start",
          justifyContent: "flex-end",
          color: "#fff",
          zIndex: 21474836,
          backgroundColor: "rgba(255,185,105,0.72)",
          height: "100vh",
          overflowY: "scroll",
        }}
        open={open}
        onClick={handleClose}
      >
        <Stack direction="row" p={2} gap={1} mr="auto">
          <MenuDesktop />
          {currentUser?.roles.includes("admin") && <MenuDesktopAdmin />}
        </Stack>
      </Backdrop>
    </Box>
  );
}
