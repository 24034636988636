import { CartesianGrid, Label, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";

import ChartBox from "../../../components/frames/chartbox.tsx";
import TooltipOnibusCirculacao from "../../../components/tooltips/tooltip-onibus-circulacao.tsx";
import { useTickFormatterUTC } from "../../../services/charts.ts";
import { useDadosAPIProtegida } from "../../../services/dados.ts";

import { DataQtd } from "./tipos.ts";

export default function OnibusCirculacao() {
  const { registros, ...chartProps } = useDadosAPIProtegida<DataQtd>({ consulta: "qtd-onibus-em-circulacao" });

  const lineColor = "#F5792B";
  const tickFormatter = useTickFormatterUTC(registros, "data");

  return (
    <ChartBox {...chartProps} registros={registros} titulo="Quantidade de **ônibus em circulação**">
      <ResponsiveContainer width="100%" height="100%" style={{ fontSize: "12px" }}>
        <LineChart data={registros} width={500} height={400} margin={{ top: 15, right: 25, bottom: 45, left: 70 }}>
          <CartesianGrid vertical={false} stroke="rgba(131, 135, 154, 0.10)" />
          <XAxis
            dataKey="data"
            axisLine={false}
            tickLine={false}
            angle={-45}
            tickMargin={10}
            tickFormatter={tickFormatter}
            interval={0}
          >
            <Label position="bottom" offset={20}>
              Dia
            </Label>
          </XAxis>
          <YAxis axisLine={false} tickLine={false}>
            <Label angle={-90} position="insideLeft" offset={-30}>
              Veículos
            </Label>
          </YAxis>
          <Tooltip content={TooltipOnibusCirculacao} />
          <Line dataKey="qtdOnibus" stroke={lineColor} fill={lineColor} strokeWidth="2px"></Line>
        </LineChart>
      </ResponsiveContainer>
    </ChartBox>
  );
}
