import editarIcon from "../../../assets/icones/edit.svg";
import BotaoLink from "../../../components/botao-link.tsx";

export function Editar({ id }: { id: string }) {
  return (
    <BotaoLink
      to={`/gestor/admin/apis/${id}/editar`}
      startIcon={<img src={editarIcon} alt="" />}
      sx={{ color: "#02A9F4", textDecoration: "underline", textWrap: "nowrap" }}
    >
      Editar endpoint
    </BotaoLink>
  );
}
