import React from "react";

import ReactDOM from "react-dom/client";
// eslint-disable-next-line import/no-unresolved
import { registerSW } from "virtual:pwa-register";

import App from "./app.tsx";

if ("serviceWorker" in navigator) registerSW({ immediate: true });

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);
