import dayjs, { UnitType } from "dayjs";
import { isNumber, keys, uniq } from "lodash";

export type CamposVariacao = ["Ano", "Mes", "Dia"] | ["Ano", "Mes"];

/**
 * Constrói parâmetros para enviar via querystring, com base nos valores
 * vindos de filtros ou de um objeto com valores adicionais fixos.
 *
 * @param filtros os valores dos filtros a substituir
 * @param adicionais valores adicionais para realizar substituição na consulta
 * @param camposVariacao campos a serem utilizados para cálculo da variação
 */
export function constroiParametros(
  filtros: Record<string, string[]>,
  adicionais?: Record<string, unknown>,
  camposVariacao?: CamposVariacao,
): URLSearchParams {
  adicionais = adaptaCamposVariacao(filtros, adicionais, camposVariacao);

  const replacer = (v: string): string => {
    // se existir um campo em "adicionais" com o mesmo nome da variável, retorna o valor dele
    if (adicionais && v in adicionais) return quoteSQL(adicionais[v]);

    const selecao = filtros[v]; // tenta obter, da lista de filtros, a opção escolhida
    const opcoes =
      !selecao || isTodos(selecao)
        ? [] // se a opção for "Todos" ou vazia, usa um array vazio
        : selecao; // senão, apenas os valores selecionados

    return quoteSQL(opcoes);
  };

  return uniq([...keys(filtros), ...keys(adicionais)])
    .map((k) => [k, replacer(k)])
    .filter(([, v]) => v !== "")
    .reduce((q, [k, v]) => {
      q.append(k, v);

      return q;
    }, new URLSearchParams());
}

function adaptaCamposVariacao(
  filtros: Record<string, string[]>,
  adicionais?: Record<string, unknown>,
  camposVariacao?: string[],
) {
  if (camposVariacao?.join() === "Ano,Mes") {
    const hoje = dayjs();
    const [campoAno, campoMes] = camposVariacao;
    const ano = +(filtros[campoAno] ?? hoje.year());
    const mes = +(filtros[campoMes] ?? hoje.month() + 1);
    const dataVariacao = dayjs(new Date(ano, mes - 1, 1)).subtract(1, "month");

    return {
      ...adicionais,
      [campoAno]: [String(dataVariacao.year())],
      [campoMes]: [String(dataVariacao.month() + 1)],
    };
  }

  if (camposVariacao?.join() === "Ano,Mes,Dia") {
    if (!isNumber(adicionais?.["from"])) throw new Error("Necessário possuir o filtro `from` para calcular variação");
    else if (!isNumber(adicionais?.["to"])) throw new Error("Necessário possuir o filtro `to` para calcular variação");

    const from = dayjs(adicionais.from);
    const to = dayjs(adicionais.to);

    let diffUnit: UnitType;
    if (from.isSame(from.startOf("year"), "day") && to.isSame(to.endOf("year"), "day")) diffUnit = "year";
    else if (from.isSame(from.startOf("month"), "day") && to.isSame(to.endOf("month"), "day")) diffUnit = "month";
    else diffUnit = "day";

    const diff = to.diff(from, diffUnit) + 1;
    const novoFrom = from.subtract(diff, diffUnit);
    const novoTo = to.subtract(diff, diffUnit);

    return { ...adicionais, from: novoFrom.valueOf(), to: novoTo.valueOf() };
  }

  return adicionais;
}

/**
 * Retorna o valor informado, já pronto para fazer parte da consulta SQL.
 *
 * No caso de números, apenas converte para string. Outros valores são convertidos
 * para string e envolvos em aspas simples. Se já existir uma aspa simples na string,
 * ela é duplicada.
 */
function quoteSQL(f: unknown): string {
  if (Array.isArray(f)) return f.map((v) => quoteSQL(v)).join(",");

  if (Number.isFinite(f)) return String(f);

  return `'${String(f).replace(/'/g, "''")}'`;
}

export function isTodos(values: string[]) {
  return !values.length || (values.length === 1 && values[0] === "All");
}
