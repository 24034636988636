import StyledPopupMenu, { StyledMenuItem } from "./styled-popup-menu.tsx";

export default function MenuMobilidade() {
  return (
    <StyledPopupMenu titulo="Mobilidade">
      <StyledMenuItem to="/horarios">Horários e pontualidade</StyledMenuItem>
      <StyledMenuItem to="/itinerarios">Itinerários, rotas e tarifas</StyledMenuItem>
    </StyledPopupMenu>
  );
}
