import historicoIcon from "../../../../assets/icones/history.svg";
import BotaoLink from "../../../../components/botao-link.tsx";

interface Props {
  id: string;
}

export default function AcaoHistorico({ id }: Props) {
  return (
    <BotaoLink
      to={`/gestor/admin/usuarios/${id}/historico`}
      startIcon={<img src={historicoIcon} alt="" />}
      sx={{ color: "#02A9F4", textDecoration: "underline", textWrap: "nowrap" }}
    >
      Histórico
    </BotaoLink>
  );
}
