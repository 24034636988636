import { Stack } from "@mui/material";

import CoordenadasRotas from "./coordenadas-rotas.tsx";
import DiaOpVeiculo from "./dia-op-veiculo.tsx";
import HorarioPicoFrota from "./horario-pico-frota.tsx";
import OnibusCirculacao from "./onibus-circulacao.tsx";

export default function Frota() {
  return (
    <Stack gap={2} sx={{ p: { xs: 1, md: 0 } }}>
      <HorarioPicoFrota />
      <OnibusCirculacao />
      <DiaOpVeiculo />
      <CoordenadasRotas />
    </Stack>
  );
}
