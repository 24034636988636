/**
 * Para montar a URL, utilizar: <source type="video/mp4" src="https://appnittrans.niteroi.rj.gov.br:8888/{id}/last_video.mp4">
 */

export function makeCameraUrl(id: string) {
  return `https://appnittrans.niteroi.rj.gov.br:8888/${id}/last_video.mp4`;
}

export const cameras = [
  { id: "000001", lat: -22.9466, long: -43.0319, nome: "ITAIPU" },
  { id: "000002", lat: -22.9419, long: -43.0571, nome: "CISP / CCO NITTRANS" },
  { id: "000003", lat: -22.9104, long: -43.0994, nome: "TÚNEL RAUL VEIGA" },
  { id: "000004", lat: -22.9131, long: -43.0995, nome: "TÚNEL ROBERTO SILVEIRA" },
  { id: "000005", lat: -22.9002, long: -43.1121, nome: "PRAÇA JOSÉ BEDRAN" },
  { id: "000006", lat: -22.9074, long: -43.0622, nome: "FÓRUM" },
  { id: "000007", lat: -22.9082, long: -43.0674, nome: "POSTO DE SAÚDE - LGO BATALHA" },
  { id: "000008", lat: -22.9148, long: -43.0955, nome: "SKATE PARK" },
  { id: "000009", lat: -22.9083, long: -43.1014, nome: "ROB SILVEIRA X ARY PARREIRAS" },
  { id: "000010", lat: -22.8986, long: -43.1118, nome: "ROB SILVEIRA X MIGUEL FRIAS" },
  { id: "000011", lat: -22.9049, long: -43.1224, nome: "PRAIA DAS FLEXAS" },
  { id: "000012", lat: -22.9108, long: -43.1096, nome: "PRAIA X MARIZ BARROS" },
  { id: "000013", lat: -22.9039, long: -43.1165, nome: "PRAÇA GETÚLIO VARGAS" },
  { id: "000014", lat: -22.9016, long: -43.0997, nome: "RUA SANTA ROSA" },
  { id: "000015", lat: -22.8983, long: -43.1035, nome: "LARGO DO MARRÃO" },
  { id: "000016", lat: -22.894, long: -43.1239, nome: "PRAÇA ARARIBÓIA" },
  { id: "000017", lat: -22.8858, long: -43.125, nome: "FELICIANO SODRÉ" },
  { id: "000018", lat: -22.889, long: -43.1145, nome: "JANSEN MELO X MAL DEODORO" },
  { id: "000019", lat: -22.8781, long: -43.1093, nome: "BENJAMIN CONSTANT" },
  { id: "000020", lat: -22.8963, long: -43.1246, nome: "RIO BRANCO X 15 NOVEMBRO" },
  { id: "000021", lat: -22.8807, long: -43.1014, nome: "ALAMEDA X JOÃO BRASIL" },
  { id: "000022", lat: -22.8815, long: -43.0791, nome: "HOSPITAL GETULINHO" },
  { id: "000023", lat: -22.9029, long: -43.1108, nome: "GAVIÃO PEIXOTO" },
  { id: "000026", lat: -22.887, long: -43.1151, nome: "JANSEN - SENT. PONTE" },
  { id: "000027", lat: -22.8944, long: -43.1196, nome: "RUA DA CONCEIÇÃO" },
  { id: "000029", lat: -22.8859, long: -43.1152, nome: "JANSEN SENT. CENTRO" },
  { id: "000030", lat: -22.901, long: -43.1131, nome: "MIGUEL DE FRIAS" },
  { id: "000031", lat: -22.9208, long: -43.0943, nome: "PRAÇA DO RÁDIO AMADOR" },
  { id: "000031", lat: -22.9501, long: -43.0585, nome: "TREVO DE CAMBOINHAS" },
  { id: "000034", lat: -22.8835, long: -43.116, nome: "FELICIANO SODRÉ x MERGULHÃO" },
  { id: "000035", lat: -22.9098, long: -43.1041, nome: "ARY PARREIRAS x IRINEU MARINHO" },
  { id: "000036", lat: -22.8944, long: -43.1131, nome: "HOSPITAL ICARAÍ" },
  { id: "000606", lat: -22.9355, long: -43.0875, nome: "TÚNEL CAFUBÁ - CHARITAS" },
  { id: "000622", lat: -22.9367, long: -43.0816, nome: "VIÁRIO CAFUBÁ" },
  { id: "000635", lat: -22.9347, long: -43.0909, nome: "TÚNEL CAFUBÁ - CHARITAS" },
  { id: "000644", lat: -22.932, long: -43.0978, nome: "VIÁRIO CHARITAS" },
];

export type ICamera = (typeof cameras)[0];
