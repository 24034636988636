import { lazy, Suspense, useMemo, useState } from "react";

import { Box, Button, Dialog, DialogContent, Stack } from "@mui/material";

import { Feature, LineString } from "geojson";

import { calculateBounds } from "../../services/geo.ts";
import Aguarde from "../aguarde.tsx";
import ErrorBoundary from "../error-boundary.tsx";

const MapaGeoJSON = lazy(() => import("./mapa-geo-json.tsx"));

export default function VerGeojson({ valor }: { valor: Feature<LineString> }) {
  const [open, setOpen] = useState(false);

  const toggleOpen = () => setOpen((o) => !o);

  const [geoJsonLeaflet, bounds] = useMemo(() => {
    const coordinates = valor.geometry.coordinates.map(([lng, lat]) => [lat, lng]);

    const geoJsonLeaflet = { ...valor, geometry: { ...valor.geometry, coordinates } };

    const bounds = calculateBounds(coordinates);

    return [geoJsonLeaflet, bounds] as const;
  }, [valor]);

  return (
    <Box textAlign="center">
      <Button variant="text" onClick={toggleOpen}>
        visualizar…
      </Button>
      {open && (
        <Dialog open onClose={toggleOpen} maxWidth="xl" fullWidth>
          <DialogContent>
            <Stack sx={{ ["& > div"]: { height: "75vh" } }}>
              <ErrorBoundary>
                <Suspense fallback={<Aguarde />}>
                  <MapaGeoJSON bounds={bounds} data={geoJsonLeaflet} />
                </Suspense>
              </ErrorBoundary>
            </Stack>
          </DialogContent>
        </Dialog>
      )}
    </Box>
  );
}
