import { CartesianGrid, Label, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";

import ChartBox from "../../../components/frames/chartbox.tsx";
import TooltipContagemPorDia from "../../../components/tooltips/tooltip-contagem-por-dia.tsx";
import { useTickFormatterUTC } from "../../../services/charts.ts";
import { useDadosAPIProtegida } from "../../../services/dados.ts";

import { ViagensPorData } from "./tipos.ts";

export default function QtdViagensDia() {
  const { registros, ...chartProps } = useDadosAPIProtegida<ViagensPorData>({ consulta: "qtd-viagens-dia" });

  const realizadaColor = "#574786";

  const tickFormatter = useTickFormatterUTC(registros, "data");

  return (
    <ChartBox {...chartProps} registros={registros} titulo="Quantidade de **viagens/dia**">
      <ResponsiveContainer width="100%" height="100%" style={{ fontSize: "12px" }}>
        <LineChart data={registros} width={500} height={400} margin={{ top: 15, right: 25, bottom: 45, left: 70 }}>
          <CartesianGrid vertical={false} stroke="rgba(131, 135, 154, 0.10)" />
          <XAxis
            interval={0}
            type="number"
            domain={["minData", "maxData"]}
            scale="time"
            dataKey="data"
            axisLine={false}
            tickLine={false}
            angle={-45}
            tickMargin={10}
            tickFormatter={tickFormatter}
          >
            <Label position="bottom" offset={20}>
              Dia
            </Label>
          </XAxis>
          <YAxis axisLine={false} tickLine={false}>
            <Label angle={-90} position="insideLeft" offset={-30}>
              Viagens
            </Label>
          </YAxis>
          <Tooltip content={<TooltipContagemPorDia unit="viagens" />} />
          <Line dataKey="totalViagens" fill={realizadaColor} stroke={realizadaColor} strokeWidth="2px" />
        </LineChart>
      </ResponsiveContainer>
    </ChartBox>
  );
}
