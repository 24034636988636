import { Fragment } from "react";

import { Box, StackProps, Typography } from "@mui/material";

import { Cell, Pie, PieChart, ResponsiveContainer, Tooltip } from "recharts";

import ChartBox from "../../../components/frames/chartbox.tsx";
import { ObjetoDados } from "../../../components/tabela/types.ts";
import TooltipApenasValor from "../../../components/tooltips/tooltip-apenas-valor.tsx";
import { useDadosAPIProtegida } from "../../../services/dados.ts";

interface StatusReclamacao extends ObjetoDados {
  status: [string, string];
  value: number;
}

export default function StatusReclamacoes(props: StackProps) {
  const { registros, ...chartProps } = useDadosAPIProtegida<StatusReclamacao>({ consulta: "status-reclamacoes" });

  return (
    <ChartBox
      {...chartProps}
      registros={registros}
      titulo="Status de reclamações"
      width="100%"
      height="100%"
      mioloProps={{ px: 2, position: "relative" }}
      tituloProps={{ fontSize: undefined }}
      {...props}
    >
      <Box
        display="grid"
        gridTemplateColumns="auto 1fr"
        alignSelf="start"
        columnGap={1}
        alignItems="center"
        position="absolute"
      >
        {registros?.map(({ status: [name, color] }) => (
          <Fragment key={name}>
            <Box sx={{ bgcolor: color }} width={24} height={4} />
            <Typography noWrap fontSize={10} color="#83879A">
              {name}
            </Typography>
          </Fragment>
        ))}
      </Box>

      <ResponsiveContainer width="100%" height="100%">
        <PieChart width={400} height={400}>
          <Pie dataKey="value" data={registros} cx="75%" cy="50%" innerRadius={80} outerRadius={120} fill="#82ca9d">
            {registros?.map(({ status: [, color] }, index) => (
              <Cell key={`cell-${index}`} fill={color} stroke={color} />
            ))}
          </Pie>
          <Tooltip wrapperStyle={{ fontSize: 10 }} content={TooltipApenasValor} />
        </PieChart>
      </ResponsiveContainer>
    </ChartBox>
  );
}
