import { Component, ErrorInfo, ReactNode } from "react";

import { Alert, Typography } from "@mui/material";

interface Props {
  fallback?: ReactNode;
  children: ReactNode;
}

interface State {
  error?: Error;
  kind?: "permission" | "api" | "unknown";
  customMessage?: ReactNode;
}

export default class ErrorBoundary extends Component<Props, State> {
  state: State = {};

  static getDerivedStateFromError(error: Error): State {
    return { error, kind: "unknown" };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.log(error, errorInfo);
  }

  render() {
    if (!this.state.kind) return <>{this.props.children}</>;
    if (this.props.fallback !== undefined) return <>{this.props.fallback}</>;

    return (
      <Alert color="error">
        <Typography variant="subtitle2">Erro</Typography>
        {this.renderErrorDetails()}
      </Alert>
    );
  }

  private renderErrorDetails() {
    switch (this.state.kind) {
      case "permission":
        return <Typography>Você não tem permissão para acessar este recurso.</Typography>;
      case "api":
        return <Typography>Houve um erro ao consultar a API.</Typography>;
      default:
        return <Typography>Houve um erro ao apresentar o componente.</Typography>;
    }
  }
}
