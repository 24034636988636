import { ReactNode } from "react";

import { Stack, StackProps, Typography, TypographyProps } from "@mui/material";

import Markdown from "react-markdown";

import Aguarde from "../aguarde.tsx";
import BotaoDownload from "../botao-download";
import ErrorBoundary from "../error-boundary.tsx";
import { ObjetoDados } from "../tabela/types.ts";

import ErroDados from "./erro-dados.tsx";
import SemDados from "./sem-dados.tsx";

interface Props extends StackProps, MioloProps {
  titulo: string;
  mioloProps?: StackProps;
  tituloProps?: TypographyProps;
}

interface MioloProps {
  registros: ObjetoDados[] | undefined;
  erro: unknown;
  erroVariacao: unknown;
  children?: ReactNode;
}

export default function ChartBox({
  titulo,
  tituloProps,
  registros,
  erro,
  erroVariacao,
  mioloProps,
  children,
  sx,
  ...props
}: Props) {
  return (
    <Stack
      height="20rem"
      border="1px solid rgba(131, 135, 154, 0.19)"
      borderRadius="7px"
      position="relative"
      {...props}
      sx={{ ...sx, "&:hover .botao-download, &:has(button.active) .botao-download": { visibility: "visible" } }}
    >
      {!registros?.length ? null : <BotaoDownload registros={registros} />}
      <Typography
        fontSize={20}
        m={2}
        component="div"
        {...tituloProps}
        sx={{ "& p": { margin: 0 }, ...tituloProps?.sx }}
      >
        <Markdown>{titulo}</Markdown>
      </Typography>
      <Miolo {...mioloProps} registros={registros} erro={erro} erroVariacao={erroVariacao}>
        {children}
      </Miolo>
    </Stack>
  );
}

function Miolo({ erro, erroVariacao, children, registros, ...props }: MioloProps & StackProps) {
  if (erro || erroVariacao) return <ErroDados erro={erro || erroVariacao} />;

  if (!registros) return <Aguarde />;

  if (!registros.length) {
    return (
      <Stack flex={1} alignItems="center" justifyContent="center">
        <SemDados />
      </Stack>
    );
  }

  return (
    <Stack position="relative" flex={1} {...props}>
      <ErrorBoundary>{children}</ErrorBoundary>
    </Stack>
  );
}
