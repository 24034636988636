import { lazy, Suspense } from "react";

import ErrorBoundary from "../error-boundary.tsx";

import { Props } from "./types.ts";

const Botao = lazy(() => import("./botao-download.tsx"));

export default function BotaoDownload(props: Props) {
  return (
    <ErrorBoundary>
      <Suspense>
        <Botao {...props} />
      </Suspense>
    </ErrorBoundary>
  );
}
