import { SvgIcon, SvgIconProps } from "@mui/material";

export default function Info(props: SvgIconProps) {
  return (
    <SvgIcon {...props} sx={{ backgroundColor: "primay.main", ...props.sx }}>
      <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M9.49968 1.58301C7.93391 1.58301 6.4033 2.04731 5.10141 2.91721C3.79952 3.7871 2.78483 5.02352 2.18563 6.4701C1.58644 7.91668 1.42966 9.50846 1.73513 11.0441C2.04059 12.5798 2.79458 13.9904 3.90175 15.0976C5.00891 16.2048 6.41953 16.9588 7.95521 17.2642C9.4909 17.5697 11.0827 17.4129 12.5293 16.8137C13.9758 16.2145 15.2122 15.1998 16.0821 13.8979C16.952 12.5961 17.4163 11.0654 17.4163 9.49967C17.4163 8.46004 17.2116 7.43059 16.8137 6.4701C16.4159 5.5096 15.8327 4.63688 15.0976 3.90175C14.3625 3.16662 13.4897 2.58348 12.5293 2.18563C11.5688 1.78778 10.5393 1.58301 9.49968 1.58301ZM9.49968 15.833C8.24706 15.833 7.02258 15.4616 5.98107 14.7656C4.93955 14.0697 4.1278 13.0806 3.64844 11.9233C3.16909 10.7661 3.04367 9.49265 3.28804 8.2641C3.53241 7.03556 4.1356 5.90706 5.02133 5.02133C5.90707 4.1356 7.03556 3.53241 8.26411 3.28803C9.49265 3.04366 10.7661 3.16908 11.9233 3.64844C13.0806 4.12779 14.0697 4.93955 14.7657 5.98106C15.4616 7.02257 15.833 8.24706 15.833 9.49967C15.833 11.1794 15.1658 12.7903 13.978 13.978C12.7903 15.1657 11.1794 15.833 9.49968 15.833Z"
          fill="currentColor"
        />
        <path
          d="M9.49967 7.12435C9.9369 7.12435 10.2913 6.76991 10.2913 6.33268C10.2913 5.89546 9.9369 5.54102 9.49967 5.54102C9.06245 5.54102 8.70801 5.89546 8.70801 6.33268C8.70801 6.76991 9.06245 7.12435 9.49967 7.12435Z"
          fill="currentColor"
        />
        <path
          d="M9.49967 7.91602C9.28971 7.91602 9.08835 7.99942 8.93988 8.14789C8.79142 8.29636 8.70801 8.49772 8.70801 8.70768V12.666C8.70801 12.876 8.79142 13.0773 8.93988 13.2258C9.08835 13.3743 9.28971 13.4577 9.49967 13.4577C9.70964 13.4577 9.911 13.3743 10.0595 13.2258C10.2079 13.0773 10.2913 12.876 10.2913 12.666V8.70768C10.2913 8.49772 10.2079 8.29636 10.0595 8.14789C9.911 7.99942 9.70964 7.91602 9.49967 7.91602Z"
          fill="currentColor"
        />
      </svg>
    </SvgIcon>
  );
}
