import { map, max, min } from "lodash";

export function calculateBounds(coordinates: number[][]) {
  const lngs = map(coordinates, 0);
  const lats = map(coordinates, 1);

  return [
    [min(lats), min(lngs)],
    [max(lats), max(lngs)],
  ] as [number, number][];
}

/**
 * Valida se um valor é um array de coordenadas
 * @param valor
 */
export function isCoordinates(valor: unknown): valor is [number, number][] {
  return (
    Array.isArray(valor) && valor.every((v) => Array.isArray(v) && v.length === 2 && v.every((n) => Number.isFinite(n)))
  );
}
