import { Stack } from "@mui/material";

import QtdIntegracoesDia from "./qtd-integracoes-dia.tsx";
import QtdIntegracoesLinha from "./qtd-integracoes-linha.tsx";
import QtdViagensDia from "./qtd-viagens-dia.tsx";
import TabelaIntegracoes from "./tabela-integracoes.tsx";

export default function Viagens() {
  return (
    <Stack gap={2} sx={{ p: { xs: 1, md: 0 } }}>
      <QtdViagensDia />
      <QtdIntegracoesDia />
      <QtdIntegracoesLinha />
      <TabelaIntegracoes />
    </Stack>
  );
}
