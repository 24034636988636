import { useCallback, useState } from "react";

import { useSnackbar } from "notistack";

import rejeitarIcon from "../../../../assets/icones/outlined-close.svg";
import BotaoLink from "../../../../components/botao-link.tsx";
import { API, typedRequest } from "../../../../services/api";
import { useAuthAxios } from "../../../../services/fetchers.ts";

import AcaoDialog from "./acao-dialog.tsx";

interface Props {
  usuario: API["UsuarioCadastrado"];
  icone?: boolean;
  onSuccess?: () => void;
}

export default function AcaoRejeitar({ usuario, icone = false, onSuccess }: Props) {
  const [isOpen, setOpen] = useState(false);
  const toggleOpen = useCallback(() => setOpen((o) => !o), []);

  const { enqueueSnackbar } = useSnackbar();

  const authAxios = useAuthAxios();
  const handleRejeitar = useCallback(async () => {
    if (!usuario.id) return;

    try {
      await typedRequest(authAxios, "delete", "/cadastros/usuario/{id}/rejeitar", { id: usuario.id });

      enqueueSnackbar({ variant: "success", message: "Usuário rejeitado!" });
      if (onSuccess) onSuccess();

      setOpen(false);
    } catch (e) {
      reportError(e);
      enqueueSnackbar({
        variant: "error",
        message: (
          <>
            Ocorreu um erro imprevisto ao tentar rejeitar o usuário.
            <br />
            Tente novamente mais tarde, ou entre em contato com o suporte.
          </>
        ),
      });
    }
  }, [authAxios, enqueueSnackbar, onSuccess, usuario.id]);

  return (
    <>
      <BotaoLink
        onClick={toggleOpen}
        startIcon={icone && <img src={rejeitarIcon} alt="" />}
        sx={{ color: "rgba(235, 87, 87, 1)", textDecoration: "underline", textWrap: "nowrap" }}
      >
        Rejeitar
      </BotaoLink>
      <AcaoDialog
        isOpen={isOpen}
        toggleOpen={toggleOpen}
        textoAcao="Rejeitar"
        usuario={usuario}
        onClick={handleRejeitar}
      />
    </>
  );
}
