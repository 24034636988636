import { SvgIcon } from "@mui/material";

export default function DeleteIcon() {
  return (
    <SvgIcon sx={{ fill: "currentColor" }}>
      <svg width="15" height="17" viewBox="0 0 15 17" xmlns="http://www.w3.org/2000/svg">
        <path d="M5.83333 13.2C6.05435 13.2 6.26631 13.1131 6.42259 12.9584C6.57887 12.8036 6.66667 12.5938 6.66667 12.375V7.425C6.66667 7.2062 6.57887 6.99635 6.42259 6.84164C6.26631 6.68692 6.05435 6.6 5.83333 6.6C5.61232 6.6 5.40036 6.68692 5.24408 6.84164C5.0878 6.99635 5 7.2062 5 7.425V12.375C5 12.5938 5.0878 12.8036 5.24408 12.9584C5.40036 13.1131 5.61232 13.2 5.83333 13.2ZM14.1667 3.3H10.8333V2.475C10.8333 1.81859 10.5699 1.18906 10.1011 0.724911C9.63226 0.260758 8.99637 0 8.33333 0H6.66667C6.00363 0 5.36774 0.260758 4.8989 0.724911C4.43006 1.18906 4.16667 1.81859 4.16667 2.475V3.3H0.833333C0.61232 3.3 0.400358 3.38692 0.244078 3.54164C0.0877973 3.69635 0 3.9062 0 4.125C0 4.3438 0.0877973 4.55365 0.244078 4.70836C0.400358 4.86308 0.61232 4.95 0.833333 4.95H1.66667V14.025C1.66667 14.6814 1.93006 15.3109 2.3989 15.7751C2.86774 16.2392 3.50363 16.5 4.16667 16.5H10.8333C11.4964 16.5 12.1323 16.2392 12.6011 15.7751C13.0699 15.3109 13.3333 14.6814 13.3333 14.025V4.95H14.1667C14.3877 4.95 14.5996 4.86308 14.7559 4.70836C14.9122 4.55365 15 4.3438 15 4.125C15 3.9062 14.9122 3.69635 14.7559 3.54164C14.5996 3.38692 14.3877 3.3 14.1667 3.3ZM5.83333 2.475C5.83333 2.2562 5.92113 2.04635 6.07741 1.89164C6.23369 1.73692 6.44565 1.65 6.66667 1.65H8.33333C8.55435 1.65 8.76631 1.73692 8.92259 1.89164C9.07887 2.04635 9.16667 2.2562 9.16667 2.475V3.3H5.83333V2.475ZM11.6667 14.025C11.6667 14.2438 11.5789 14.4536 11.4226 14.6084C11.2663 14.7631 11.0543 14.85 10.8333 14.85H4.16667C3.94565 14.85 3.73369 14.7631 3.57741 14.6084C3.42113 14.4536 3.33333 14.2438 3.33333 14.025V4.95H11.6667V14.025ZM9.16667 13.2C9.38768 13.2 9.59964 13.1131 9.75592 12.9584C9.9122 12.8036 10 12.5938 10 12.375V7.425C10 7.2062 9.9122 6.99635 9.75592 6.84164C9.59964 6.68692 9.38768 6.6 9.16667 6.6C8.94565 6.6 8.73369 6.68692 8.57741 6.84164C8.42113 6.99635 8.33333 7.2062 8.33333 7.425V12.375C8.33333 12.5938 8.42113 12.8036 8.57741 12.9584C8.73369 13.1131 8.94565 13.2 9.16667 13.2Z" />
      </svg>
    </SvgIcon>
  );
}
