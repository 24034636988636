import { MouseEvent, ReactNode, useCallback, useMemo, useState } from "react";

import { useTheme } from "@mui/material";

import { max, reverse, sampleSize, sortBy } from "lodash";

import { fmtDecimal } from "../../services/formatacao.ts";

import { DataRowsContext, InfoColuna, InfoMinimaColunas, ObjetoDados, Order, PropsCalculaveis } from "./types.ts";
import { getTextWidth } from "./util.ts";

interface Props<T extends ObjetoDados> {
  linhas: ObjetoDados[];
  colunas: InfoMinimaColunas<T>[];
  children: ReactNode;
}

export default function DataRowsProvider<T extends ObjetoDados>({
  linhas: todasLinhas,
  colunas: preColunas,
  children,
}: Props<T>) {
  const [[orderBy, order], setOrderBy] = useState<[string | undefined, Order]>([undefined, "asc"]);

  const linhas = useMemo(() => {
    if (!orderBy) return todasLinhas;
    const sorted = sortBy(todasLinhas, orderBy);

    return order === "asc" ? sorted : reverse(sorted);
  }, [todasLinhas, order, orderBy]);

  const theme = useTheme();
  const font = `${theme.typography.fontSize}px '${theme.typography.fontFamily}'`;

  const primeirasLinhas = useMemo(() => todasLinhas.slice(0, 1000), [todasLinhas]);

  const dadosAdicionaisColuna = useCallback(
    (f: InfoMinimaColunas<T>): { [K in PropsCalculaveis]: InfoColuna<T>[K] } => {
      if (f.width) return { width: f.width, sortable: f.sortable ?? false };

      const peloLabel = getTextWidth(f.label, font);

      if (f.type === "geojson") return { width: Math.max(120, peloLabel), sortable: f.sortable ?? false };
      if (f.type === "time" || f.type === "datetime")
        return { width: Math.max(165, peloLabel), sortable: f.sortable ?? true };
      if (f.type === "inteiro") return { width: Math.max(50, peloLabel), sortable: f.sortable ?? true };

      // calcula a largura máxima entre uma amostra de 20% dos dados da primeira página
      const textos = primeirasLinhas.map((l) =>
        f.type === "number" ? fmtDecimal.format(+(l?.[f.id] ?? "")) : String(l?.[f.id] ?? ""),
      );

      const porDados = max(sampleSize(textos, 200).map((t: string) => getTextWidth(t, font))) ?? 0;

      return { width: Math.max(porDados, peloLabel) + 40, sortable: f.sortable ?? true };
    },
    [font, primeirasLinhas],
  );

  const colunas = useMemo(
    () => preColunas.map((f): InfoColuna<T> => ({ ...f, ...dadosAdicionaisColuna(f) })),
    [preColunas, dadosAdicionaisColuna],
  );

  const onSort = useCallback(
    (e: MouseEvent<HTMLElement>) => {
      const property = e.currentTarget.dataset.attr;
      const isAsc = orderBy === property && order === "asc";
      setOrderBy([property, isAsc ? "desc" : "asc"]);
    },
    [order, orderBy],
  );

  return (
    <DataRowsContext.Provider value={{ linhas, colunas, order, orderBy, onSort }}>{children}</DataRowsContext.Provider>
  );
}
