import { SvgIcon, SxProps } from "@mui/material";

export default function ChatbotIcon({ sx }: { sx?: SxProps }) {
  return (
    <SvgIcon sx={{ backgroundColor: "primay.main", ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="43" height="43" viewBox="0 0 43 43" fill="none">
        <path
          d="M31.3545 27.7715C31.3545 29.7602 29.7599 31.3548 27.7712 31.3548C25.7824 31.3548 24.1878 29.7602 24.1878 27.7715C24.1878 25.7827 25.8003 24.1882 27.7712 24.1882C29.742 24.1882 31.3545 25.8006 31.3545 27.7715ZM15.2295 24.1882C13.2587 24.1882 11.6462 25.8006 11.6462 27.7715C11.6462 29.7423 13.2587 31.3548 15.2295 31.3548C17.2003 31.3548 18.8128 29.7602 18.8128 27.7715C18.8128 25.7827 17.2182 24.1882 15.2295 24.1882ZM41.2087 26.8757V32.2507C41.2087 33.2361 40.4024 34.0423 39.417 34.0423H37.6253V35.834C37.6253 37.8227 36.0307 39.4173 34.042 39.4173H8.95866C6.98783 39.4173 5.37533 37.8227 5.37533 35.834V34.0423H3.58366C2.59824 34.0423 1.79199 33.2361 1.79199 32.2507V26.8757C1.79199 25.8902 2.59824 25.084 3.58366 25.084H5.37533C5.37533 18.1502 10.9832 12.5423 17.917 12.5423H19.7087V10.2669C18.6337 9.65773 17.917 8.49315 17.917 7.16732C17.917 5.19648 19.5295 3.58398 21.5003 3.58398C23.4712 3.58398 25.0837 5.19648 25.0837 7.16732C25.0837 8.49315 24.367 9.65773 23.292 10.2669V12.5423H25.0837C32.0174 12.5423 37.6253 18.1502 37.6253 25.084H39.417C40.4024 25.084 41.2087 25.8902 41.2087 26.8757ZM37.6253 28.6673H34.042V25.084C34.042 20.139 30.0287 16.1257 25.0837 16.1257H17.917C12.972 16.1257 8.95866 20.139 8.95866 25.084V28.6673H5.37533V30.459H8.95866V35.834H34.042V30.459H37.6253V28.6673Z"
          fill="currentColor"
        />
      </svg>
    </SvgIcon>
  );
}
