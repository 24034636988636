import { Paper, Stack, Typography } from "@mui/material";

import type { NameType, Payload, ValueType } from "recharts/types/component/DefaultTooltipContent";

interface Props<TValue extends ValueType, TName extends NameType> {
  active?: boolean;
  payload?: Payload<TValue, TName>[];
  label?: string | number;
  formatador: Intl.NumberFormat;
}

export default function TooltipMultiseries<TValue extends ValueType, TName extends NameType>({
  active,
  payload,
  label,
  formatador,
}: Props<TValue, TName>) {
  if (!active || !payload || payload.length !== 2) return null;

  return (
    <Stack
      component={Paper}
      borderRadius="7px"
      border="1px solid rgba(131, 135, 154, 0.10)"
      sx={{ color: "#83879A", p: 1, px: 2 }}
    >
      <Typography fontWeight={700} fontSize="10px">
        Linha {label}
      </Typography>
      {payload.map((p) => (
        <Stack pl={1} key={p.dataKey}>
          <Typography fontSize="10px" mt={1}>
            {p.name}
          </Typography>
          <Typography color={p.color}>{formatador.format(+(p.value ?? 0))}</Typography>
        </Stack>
      ))}
    </Stack>
  );
}
