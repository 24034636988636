import { SvgIcon } from "@mui/material";

export default function Subsidios() {
  return (
    <SvgIcon>
      <svg width="19" height="16" viewBox="0 0 19 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M6.81875 10.4062L11.8063 5.15625M15.5469 8.4375C15.5469 7.85734 15.7658 7.30094 16.1555 6.8907C16.5453 6.48047 17.0738 6.25 17.625 6.25V5.375C17.625 1.875 16.7938 1 13.4688 1H5.15625C1.83125 1 1 1.875 1 5.375V5.8125C1.55115 5.8125 2.07973 6.04297 2.46946 6.4532C2.85918 6.86344 3.07813 7.41984 3.07813 8C3.07813 8.58016 2.85918 9.13656 2.46946 9.5468C2.07973 9.95703 1.55115 10.1875 1 10.1875V10.625C1 14.125 1.83125 15 5.15625 15H13.4688C16.7938 15 17.625 14.125 17.625 10.625C17.0738 10.625 16.5453 10.3945 16.1555 9.9843C15.7658 9.57406 15.5469 9.01766 15.5469 8.4375Z"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.9925 10H13M8 4H8.00666"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </SvgIcon>
  );
}
