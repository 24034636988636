import { Stack } from "@mui/material";

import Ipk from "./ipk.tsx";
import Pvd from "./pvd.tsx";

export default function Passageiros() {
  return (
    <Stack
      gap={1}
      gridTemplateColumns="1fr 1fr"
      gridAutoRows="18em"
      sx={{ "display": { xs: "flex", md: "grid" }, "& .indicador": { fontSize: "5rem" }, "p": { xs: 1, md: 0 } }}
    >
      <Ipk />
      <Pvd />
    </Stack>
  );
}
