import { Stack } from "@mui/material";

import { Outlet } from "react-router-dom";

export default function Api() {
  return (
    <Stack mt={1.5}>
      <Outlet />
    </Stack>
  );
}
