import { MouseEvent, ReactNode, useCallback, useState } from "react";

import { Button, ButtonProps, Link as MuiLink, Menu, MenuItem } from "@mui/material";
import { styled } from "@mui/material/styles";

import { NavLink, To } from "react-router-dom";

interface Props {
  titulo: string;
  children: ReactNode;
}

export default function StyledPopupMenu({ titulo, children }: Props) {
  const [anchorEl, setAnchorEl] = useState<HTMLElement>();

  const handleOpen = useCallback((event: MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget), []);
  const handleClose = useCallback(() => setAnchorEl(undefined), []);

  const isOpen = Boolean(anchorEl);

  return (
    <>
      <StyledMenuButton onClick={handleOpen}>{titulo}</StyledMenuButton>
      <StyledMenu open={isOpen} anchorEl={anchorEl} onClose={handleClose} onClick={handleClose}>
        {children}
      </StyledMenu>
    </>
  );
}

export const StyledMenu = styled(Menu)({
  ["& li"]: {
    ["& > a"]: { fontSize: "0.625rem", textDecoration: "none", color: "#83879A" },
    ["& > a.active"]: { fontWeight: "bold" },
    ["&:hover"]: {
      backgroundColor: "primary.main",
      ["& > a"]: { color: "primary.contrastText" },
    },
  },
});

export function StyledMenuItem({ children, to, target }: { children: ReactNode; to: To; target?: string }) {
  return (
    <MenuItem>
      <MuiLink component={NavLink} to={to} target={target}>
        {children}
      </MuiLink>
    </MenuItem>
  );
}

export function StyledMenuButton({ children, to, sx, ...props }: { to?: To } & ButtonProps) {
  const linkProps = to ? { component: NavLink, to } : {};

  return (
    <Button
      {...props}
      {...linkProps}
      sx={{
        ...sx,
        minWidth: 0,
        textDecoration: "underline",
        ["&.active"]: { fontWeight: "bold" },
        ["&.MuiButtonBase-root:hover"]: { textDecoration: "underline" },
      }}
    >
      {children}
    </Button>
  );
}
