import { Typography } from "@mui/material";

const dateTimeFormat = new Intl.DateTimeFormat("pt-BR", {
  weekday: "long",
  year: "numeric",
  month: "long",
  day: "numeric",
});

export default function Hoje() {
  const hoje = new Date();

  const parts = dateTimeFormat.formatToParts(hoje);

  return (
    <Typography sx={{ whiteSpace: "pre" }}>
      {parts.map((p, i) => (
        <Typography
          key={i}
          component="span"
          className={p.type}
          sx={{
            "display": "inline-block",
            "&:not(.literal):not(.weekday)": { fontWeight: 700 },
            "&.weekday, &.month": { "&::first-letter": { textTransform: "capitalize" } },
          }}
        >
          {p.value}
        </Typography>
      ))}
    </Typography>
  );
}
