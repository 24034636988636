import { get } from "lodash";
import useSWR, { SWRConfiguration, SWRResponse } from "swr";

import { useAuthQueryFetcher } from "../fetchers.ts";

import { ParametersForPath, ParametersForQuery, paths, ResponseBodyForPath } from "./index";

type SWRConfigurationFromAPI<
  R extends ResponseBodyForPath<paths, T, "get">,
  T extends keyof paths,
> = SWRConfiguration<R> & {
  forEditing?: true;
};

export function useSWRFromAPI<
  T extends keyof paths,
  R extends ResponseBodyForPath<paths, T, "get">,
  P extends ParametersForPath<paths, T, "get">,
  Q extends ParametersForQuery<paths, T, "get">,
>(path: NonNullable<T>, params?: P, query?: Q, config?: SWRConfigurationFromAPI<R, T>) {
  const fetcher = useAuthQueryFetcher();
  const isValidPath = !params || Object.values(params).every((v) => v !== undefined && !Number.isNaN(v));

  const { forEditing, ...restOfConfig } = config ?? {};

  const finalPath = !isValidPath
    ? undefined
    : path.replace(/{([^}]+)}/, (_, m1: string) => get(params as unknown, m1, m1)) +
      (!query ? "" : "?" + new URLSearchParams(query).toString());

  return useSWR<R>(finalPath, {
    suspense: true,
    fetcher,
    ...(forEditing && { revalidateOnMount: true, revalidateOnFocus: false, revalidateOnReconnect: false }),
    ...restOfConfig,
  }) as SWRResponse<R> & { data: R };
}
