import dayjs from "dayjs";

import { useDadosAPIProtegida } from "../../../services/dados.ts";

export function usePassageirosDia(diaRef: dayjs.Dayjs) {
  const { registros } = useDadosAPIProtegida<{ valor: number }>({
    consulta: "passageiros-dia",
    parametrosAdicionais: {
      ano: diaRef.year(),
      data: diaRef.format("YYYY-MM-DD"),
    },
  });

  return registros?.[0].valor ?? 0;
}

export function usePassageirosMes(mesRef: dayjs.Dayjs) {
  const [from, to] = [mesRef.startOf("month"), mesRef.endOf("month")];

  const { registros } = useDadosAPIProtegida<{ valor: number }>({
    consulta: "passageiros-mes",
    parametrosAdicionais: {
      anoFrom: from.year(),
      anoTo: to.year(),
      from: from.format("YYYY-MM-DD"),
      to: to.format("YYYY-MM-DD"),
    },
  });

  return registros?.[0].valor ?? 0;
}

export function useViagensDia(diaRef: dayjs.Dayjs) {
  const { registros } = useDadosAPIProtegida<{ valor: number }>({
    consulta: "viagens-dia",
    parametrosAdicionais: { data: diaRef.format("YYYY-MM-DD") },
  });

  return registros?.[0].valor ?? 0;
}

export function useViagensMes(mesRef: dayjs.Dayjs) {
  const [from, to] = [mesRef.startOf("month"), mesRef.endOf("month")];

  const { registros } = useDadosAPIProtegida<{ valor: number }>({
    consulta: "viagens-mes",
    parametrosAdicionais: { from: from.format("YYYY-MM-DD"), to: to.format("YYYY-MM-DD") },
  });

  return registros?.[0].valor ?? 0;
}
