import { Stack, StackProps } from "@mui/material";

interface Props extends StackProps {
  imagem: string;
  horizontal?: boolean;
}

export default function Imagem({ imagem, horizontal, children, ...props }: Props) {
  return (
    <Stack
      {...props}
      alignItems="end"
      p={1}
      sx={{
        ...props.sx,
        background: `url(${imagem}) center center`,
        backgroundSize: horizontal ? "cover" : "auto 100%",
      }}
    >
      {children}
    </Stack>
  );
}
