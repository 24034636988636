import { Stack, StackProps } from "@mui/material";

import TableBox from "../../../components/frames/table-box.tsx";
import { ObjetoDados } from "../../../components/tabela/types.ts";
import { useDadosAPIPaginados } from "../../../services/dados.ts";
import { fmtDataSimples, fmtHoraSimples } from "../../../services/formatacao.ts";

interface DadosEtlReclamacoes extends ObjetoDados {
  tipo: string;
  status: string;
  reclamacao: string;
  data: number;
  hora: number;
  local: string;
}

export default function TabelaReclamacoes(props: StackProps) {
  const tabelaProps = useDadosAPIPaginados<DadosEtlReclamacoes>({ consulta: "tabela-reclamacoes" });

  return (
    <Stack {...props} gap={2} marginTop={3}>
      <TableBox
        {...tabelaProps}
        titulo="Reclamações"
        colunas={[
          { id: "tipo", label: "Tipo", type: "string" },
          { id: "status", label: "Status", type: "string" },
          {
            id: "data",
            label: "Data",
            type: "custom",
            renderer: (row) => fmtDataSimples.format(new Date(row.data)),
            width: "6em",
          },
          {
            id: "hora",
            label: "Hora",
            type: "custom",
            renderer: (row) => fmtHoraSimples.format(new Date(0, 0, 0, row.hora)),
            width: "3em",
          },
          { id: "local", label: "Local", type: "string" },
        ]}
      />
    </Stack>
  );
}
