import { Suspense } from "react";

import { Card, CardContent, Stack } from "@mui/material";

import Aguarde from "../../components/aguarde.tsx";
import TextoEditavel from "../../components/conteudo-editavel/texto-editavel";
import Titulos from "../../components/titulos.tsx";

export default function Glossario() {
  return (
    <Stack>
      <Titulos titulo="MobNit" subtitulo="Glossário" />
      <Card>
        <CardContent
          sx={{
            p: 4,
            mt: -2,
            minHeight: "50vh",
            ["& li"]: { my: 2 },
            ["& ol ol"]: { listStyleType: "lower-alpha" },
          }}
        >
          <Suspense fallback={<Aguarde />}>
            <TextoEditavel caminho="gestor/glossario" />
          </Suspense>
        </CardContent>
      </Card>
    </Stack>
  );
}
