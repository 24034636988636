import { useCallback, useRef } from "react";

import { groupBy, map } from "lodash";

import { API, typedGet } from "../../services/api";
import { useDadosAPIProtegida } from "../../services/dados.ts";
import { axiosApi } from "../../services/fetchers.ts";

import { DadosLinha, DadosRota } from "./tipos.ts";

export function useLinhas() {
  return useDadosAPIProtegida<DadosLinha>({ consulta: "/area-publica/itinerarios/linhas" });
}

export function useItinerario(numeroLinha: string) {
  const { registros } = useDadosAPIProtegida<DadosRota>({
    consulta: numeroLinha && "/area-publica/itinerarios/detalhes-linha",
    parametrosAdicionais: { numeroLinha },
  });

  const porSentido = groupBy(registros, "sentido");

  const ida = porSentido["Ida"]?.[0] ?? porSentido["Circular"]?.[0];
  const volta = porSentido["Volta"]?.[0];

  return { ida, volta };
}

export function useParadas(numeroLinha: string, shapeId: string, ativa: boolean) {
  const { registros } = useDadosAPIProtegida<{ longitude: number; latitude: number; parada: string }>({
    consulta: !ativa ? "" : "/area-publica/itinerarios/paradas",
    parametrosAdicionais: { numeroLinha, shapeId },
  });

  return registros;
}

export function useCachingGtfsFetcher() {
  const cacheRef = useRef<Map<string, [number, number]>>();

  const buildAndCache = useCallback(({ lat, lng, ...v }: API["VehicleData"], id: string) => {
    // inicializa o cache
    const cache = (cacheRef.current ??= new Map<string, [number, number]>());

    // tenta obter a última entrada no cache para este veículo
    const prevFromCache = cache.get(id);

    const primeiraAparicao = !prevFromCache;

    // se a posição que veio do feed é igual à do cache, reutiliza... senão, cria um novo objeto.
    // o reuso é essencial para minimizar os re-renders do React
    const position: [number, number] =
      prevFromCache && lat === prevFromCache[0] && lng === prevFromCache[1] ? prevFromCache : [lat, lng];

    // como posição anterior usa o que tinha no cache ou, se não houver, a atual mesmo
    const previousPosition = prevFromCache ?? position;

    // armazena a posição em cache para reuso
    cache.set(id, position);

    // retorna
    return { id, position, previousPosition, primeiraAparicao, ...v };
  }, []);

  return useCallback(
    (linha: string) =>
      (linha !== "-" ? typedGet(axiosApi, "/gtfs/{linha}", { linha }) : typedGet(axiosApi, "/gtfs")).then(({ data }) =>
        map(data, (v: API["VehicleData"], id: string) => buildAndCache(v, id)),
      ),
    [buildAndCache],
  );
}
