import { ReactNode } from "react";

import { CircularProgress, Stack, Typography } from "@mui/material";

interface Props {
  children?: ReactNode;
  mensagem?: string;
}

export default function Aguarde({ children, mensagem = "Aguarde, carregando…" }: Props) {
  return (
    <Stack flex={1} display="flex" alignItems="center" justifyContent="center" gap={4}>
      {children ?? <Typography variant="caption">{mensagem}</Typography>}
      <CircularProgress />
    </Stack>
  );
}
