import { Card, CardContent, CardHeader, Stack } from "@mui/material";

import ItinerariosERotas from "../../../components/itinerarios-e-rotas";
import Titulos from "../../../components/titulos.tsx";

import ModalidadeTarifaria from "./modalidade-tarifaria.tsx";

export default function Itinerarios() {
  return (
    <Stack gap={4}>
      <Titulos titulo="MOBILIDADE" subtitulo="Itinerários, rotas e tarifas" />
      <Card>
        <CardHeader
          title="Itinerários e rotas"
          sx={{ pb: 0 }}
          titleTypographyProps={{ fontSize: "1.25rem", color: "#0C0C0C" }}
        />

        <CardContent>
          <ItinerariosERotas />
          <ModalidadeTarifaria />
        </CardContent>
      </Card>
    </Stack>
  );
}
