import { Stack } from "@mui/material";

import IndiceReclamacoesMobilidade from "./indice-reclamacoes-mobilidade.tsx";
import StatusReclamacoes from "./status-reclamacoes.tsx";
import TabelaReclamacoes from "./tabela.tsx";
import VariacaoReclamacoesPorCategoria from "./variacao-reclamacoes-por-categoria.tsx";

export default function PaginaReclamacoes() {
  return (
    <Stack
      display="grid"
      sx={{
        gridTemplateColumns: { xs: "repeat(2, 1fr)", lg: "repeat(3, 1fr)", xl: "repeat(4, 1fr)" },
        gridAutoRows: "minmax(200px, 1fr)",
        gap: { xs: 1, md: 2 },
        p: { xs: 1, md: 0 },
      }}
    >
      <StatusReclamacoes sx={{ gridColumn: "span 2", gridRow: "span 2" }} />
      <IndiceReclamacoesMobilidade />
      <VariacaoReclamacoesPorCategoria />
      <TabelaReclamacoes sx={{ gridColumn: "1/-1", gridRow: "span 2" }} />
    </Stack>
  );
}
