import TableBox from "../../../components/frames/table-box.tsx";
import { useDadosAPIPaginados } from "../../../services/dados.ts";

export default function PassageirosTransportados() {
  const tabelaProps = useDadosAPIPaginados({ consulta: "passageiros-transportados" });

  return (
    <TableBox
      {...tabelaProps}
      titulo="Passageiros"
      colunas={[
        { id: "consorcio", label: "Consórcio", type: "string" },
        { id: "empresa", label: "Empresa", type: "string" },
        { id: "linha", label: "Linha", type: "string" },
        { id: "veiculo", label: "Veículo", type: "string" },
        { id: "modalidade", label: "Modalidade", type: "string" },
        { id: "data", label: "Data", type: "date" },
        { id: "numPassageiros", label: "Nº Passageiros", type: "inteiro" },
        { id: "tipoDia", label: "Tipo Dia", type: "string" },
        { id: "turnoDia", label: "Turno", type: "string" },
      ]}
    />
  );
}
