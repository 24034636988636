import TableBox from "../../../components/frames/table-box.tsx";
import { useDadosAPIPaginados } from "../../../services/dados.ts";

import { type PassageirosFrota } from "./tipos.ts";

export default function PassageirosFrota() {
  const tabelaProps = useDadosAPIPaginados<PassageirosFrota>({ consulta: "passageiros-frota" });

  return (
    <TableBox
      {...tabelaProps}
      titulo="Valores passageiros/frota"
      colunas={[
        { id: "consorcio", label: "Consórcio", type: "string" },
        { id: "empresa", label: "Empresa", type: "string" },
        { id: "numeroLinha", label: "Linha", type: "string" },
        { id: "veiculo", label: "Veiculo", type: "string" },
        { id: "modalidade", label: "Modalidade", type: "string" },
        { id: "tipoAplicacao", label: "Tipo Aplicação", type: "string" },
        { id: "tipoDebito", label: "Tipo Débito", type: "string" },
        { id: "data", label: "Data", type: "date" },
        { id: "tipoDia", label: "Tipo Dia", type: "string" },
        { id: "turnoDia", label: "Turno", type: "string" },
        { id: "kmRealizada", label: "Km Realizada", type: "number" },
        { id: "passageirosMes", label: "Passageiros", type: "inteiro", width: "6rem" },
        { id: "ipk", label: "IPK", type: "number" },
      ]}
    />
  );
}
