import { Bar, BarChart, Brush, CartesianGrid, Label, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";

import ChartBox from "../../../components/frames/chartbox.tsx";
import TooltipContagemPassageiros from "../../../components/tooltips/tooltip-contagem-passageiros.tsx";
import { useDadosAPIProtegida } from "../../../services/dados.ts";

import { PassageirosMes } from "./tipos.ts";

export default function PassageirosVeiculo() {
  const { registros, ...chartProps } = useDadosAPIProtegida<PassageirosMes>({ consulta: "qtd-passageiros-veiculo" });

  const realizadaColor = "#574786";

  return (
    <ChartBox {...chartProps} registros={registros} titulo="Quantidade de **passageiros/veículo**">
      <ResponsiveContainer width="100%" height="100%" style={{ fontSize: "12px" }}>
        <BarChart data={registros} width={500} height={400} margin={{ top: 15, right: 25, bottom: 40, left: 70 }}>
          <CartesianGrid vertical={false} stroke="rgba(131, 135, 154, 0.10)" />
          <XAxis dataKey="grupo" axisLine={false} tickLine={false} tickMargin={5}>
            <Label position="bottom" offset={22}>
              Veículo
            </Label>
          </XAxis>
          <YAxis axisLine={false} tickLine={false}>
            <Label angle={-90} position="insideLeft" offset={-30}>
              Passageiros
            </Label>
          </YAxis>

          <Tooltip content={<TooltipContagemPassageiros labelTitle="Carro" />} />
          {registros && registros.length > 29 && <Brush height={10} endIndex={30} />}
          <Bar dataKey="passageirosMes" fill={realizadaColor} />
        </BarChart>
      </ResponsiveContainer>
    </ChartBox>
  );
}
