import IndicadorComDescricao from "../../../components/indicador-com-descricao";
import { useConteudo } from "../../../services/conteudo";
import { useIndicador } from "../../../services/dados.ts";

export default function Ipk() {
  const conteudo = useConteudo();
  const indicadorProps = useIndicador({ consulta: "ipk" });

  return (
    <IndicadorComDescricao
      {...indicadorProps}
      variante="expandido"
      titulo={conteudo.texto.indicadores.passageiros.ipk.titulo}
      cor="#F5792B"
      corVariacao="#02A9F4"
      descricao={conteudo.texto.indicadores.passageiros.ipk.tooltip}
    />
  );
}
