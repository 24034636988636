import { TableRow } from "@mui/material";
import { styled } from "@mui/material/styles";

const LinhaTabela = styled(TableRow)({
  "tbody &:nth-of-type(odd):not(.Mui-selected)": {
    backgroundColor: "#f7f7f7",
  },
  "tbody & .MuiTableCell-root": { color: "#6A6A6A" },
  "& .MuiTableCell-root": { border: "none" },
  "thead & .MuiTableCell-root": { borderBottom: "solid 1px rgba(131, 135, 154, 0.19)" },
});

export default LinhaTabela;
