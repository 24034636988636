import { SvgIcon } from "@mui/material";

export default function GestaoUsuariosIcon() {
  return (
    <SvgIcon>
      <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M12.6323 14C12.6323 11.5813 10.0255 9.625 6.81615 9.625C3.60677 9.625 1 11.5813 1 14M6.81615 7.75C7.71401 7.75 8.57511 7.42076 9.21 6.83471C9.84489 6.24866 10.2016 5.4538 10.2016 4.625C10.2016 3.7962 9.84489 3.00134 9.21 2.41529C8.57511 1.82924 7.71401 1.5 6.81615 1.5C5.91828 1.5 5.05718 1.82924 4.4223 2.41529C3.78741 3.00134 3.43073 3.7962 3.43073 4.625C3.43073 5.4538 3.78741 6.24866 4.4223 6.83471C5.05718 7.42076 5.91828 7.75 6.81615 7.75Z"
          stroke="currentcolor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M18.6323 16.5C18.6323 14.0813 16.0255 12.125 12.8161 12.125C9.60677 12.125 7 14.0813 7 16.5M12.8161 10.25C13.714 10.25 14.5751 9.92076 15.21 9.33471C15.8449 8.74866 16.2016 7.9538 16.2016 7.125C16.2016 6.2962 15.8449 5.50134 15.21 4.91529C14.5751 4.32924 13.714 4 12.8161 4C11.9183 4 11.0572 4.32924 10.4223 4.91529C9.78741 5.50134 9.43073 6.2962 9.43073 7.125C9.43073 7.9538 9.78741 8.74866 10.4223 9.33471C11.0572 9.92076 11.9183 10.25 12.8161 10.25Z"
          stroke="currentcolor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </SvgIcon>
  );
}
