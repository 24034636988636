import { Box, Stack } from "@mui/material";

export default function Descricao({ descricaoText }: { descricaoText: string }) {
  return (
    <Stack
      borderTop="1px solid rgba(131, 135, 154, 0.19)"
      bgcolor="#FBFCFF"
      borderRadius="0 0 7px 7px"
      fontSize="0.75rem"
      padding={1.5}
      alignItems="flex-start"
      justifyContent="center"
    >
      <Box maxWidth="30em">{descricaoText}</Box>
    </Stack>
  );
}
