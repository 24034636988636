import { Box, Stack } from "@mui/material";

import { isNumber } from "lodash";
import Markdown from "react-markdown";

import Aguarde from "../aguarde.tsx";
import AlertaRetornoDeDados from "../alertas/retorno-de-dados.tsx";
import ErroDados from "../frames/erro-dados.tsx";

import BlocoIndicador from "./bloco-indicador.tsx";
import Descricao from "./descricao.tsx";
import TooltipIndicador from "./tooltip-indicador.tsx";

type Variante = "basico" | "resumido" | "expandido";

interface Props {
  numberFormat?: Intl.NumberFormat;
  descricao?: string;
  variante?: Variante;
  valor: number | undefined;
  valorAnterior: number | undefined;
  cor: string;
  corVariacao: string;
  titulo: string;
  carregando: boolean;
  erro: unknown;
  erroVariacao: unknown;
}

export default function IndicadorComDescricao({
  numberFormat,
  descricao = "",
  variante = "basico",
  valor,
  valorAnterior,
  cor,
  corVariacao,
  titulo,
  carregando,
  erro,
  erroVariacao,
}: Props) {
  return (
    <Box
      border="1px solid rgba(131, 135, 154, 0.19)"
      borderRadius="7px"
      fontSize="14px"
      flex={1}
      display="grid"
      gridTemplateRows={estilosPorVariante[variante].gridRows}
      sx={{ background: estilosPorVariante[variante].bg }}
    >
      <Stack
        flexDirection="row"
        paddingX={2}
        pt={variante === "basico" ? "7px" : undefined}
        sx={{ "& span": { fontWeight: 700 }, "alignItems": "center", "mt": "20px" }}
      >
        <Markdown>{titulo}</Markdown>
        {variante !== "expandido" && <TooltipIndicador title={descricao} />}
      </Stack>

      {erro ?? erroVariacao ? (
        <ErroDados erro={erro ?? erroVariacao} indicador />
      ) : carregando ? (
        <Aguarde>
          <Box />
        </Aguarde>
      ) : !isNumber(valor) ? (
        <AlertaRetornoDeDados />
      ) : (
        <BlocoIndicador
          numberFormat={numberFormat}
          valor={valor}
          valorAnterior={valorAnterior}
          cor={cor}
          corVariacao={corVariacao}
        />
      )}

      {variante === "expandido" && <Descricao descricaoText={descricao} />}
    </Box>
  );
}

const estilosPorVariante: Record<Variante, { gridRows: string; bg: string }> = {
  basico: {
    gridRows: "20px 1fr 5px",
    bg: "linear-gradient(to top, #FBFCFF 10px, #83879A30 10px 11px, #FFFFFF 11px 100%)",
  },
  resumido: { gridRows: "20px 1fr", bg: "white" },
  expandido: { gridRows: "20px 1fr 100px", bg: "white" },
};
