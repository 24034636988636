import IndicadorComDescricao from "../../../../components/indicador-com-descricao";
import { useConteudo } from "../../../../services/conteudo";
import { useIndicador } from "../../../../services/dados.ts";

export default function MediaViagensNaoSubsidiadas() {
  const conteudo = useConteudo();

  const indicadorProps = useIndicador({ consulta: "media-viagens-nao-subsidiadas-pp" });

  return (
    <IndicadorComDescricao
      {...indicadorProps}
      titulo={conteudo.texto.auditoria.subsidios.nmvnspp.titulo}
      descricao={conteudo.texto.auditoria.subsidios.nmvnspp.tooltip}
      cor="#F5792B"
      corVariacao="#F5792B"
    />
  );
}
