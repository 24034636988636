import { useCallback } from "react";

import { ListItemText, MenuItem, Radio, SelectChangeEvent } from "@mui/material";

import { useDadosFiltro } from "../barra-filtros/hooks.ts";

import SelecaoFiltro from "./selecao-filtro.tsx";
import { FiltroProps } from "./tipos.ts";

export default function FiltroMonovalorado({ definicao: d }: FiltroProps) {
  const { setFiltros, values, value } = useDadosFiltro(d);

  const handleRenderValue = useCallback((selected: string) => (selected === "All" ? "Nenhum" : selected), []);

  const handleChange = useCallback(
    (e: SelectChangeEvent) => {
      setFiltros((f) => ({ ...f, [d.variavel]: [e.target.value] }));
    },
    [d.variavel, setFiltros],
  );

  const selectedValue = value[0] === "All" ? d.padrao ?? value[0] : value[0];

  return (
    <SelecaoFiltro definicao={d} value={selectedValue} renderValue={handleRenderValue} onChange={handleChange}>
      <MenuItem value="All">
        <Radio size="small" checked={selectedValue === "All"} />
        <ListItemText primary="Nenhum" />
      </MenuItem>
      {values?.map((v, i) => (
        <MenuItem key={i} value={v}>
          <Radio size="small" checked={selectedValue === v} />
          <ListItemText primary={v} />
        </MenuItem>
      ))}
    </SelecaoFiltro>
  );
}
